import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import { CalendarBadge } from '@powdr/components/calendar-badge';
import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import { BlockTypes, Breakpoints, ThemeProps as Prop } from '@powdr/constants';
import { AppContext } from '@powdr/context/app-context';
import { ContentBlockType } from '@powdr/model';
import { themeUtil, rgbaTransparencyToHex, clamp } from '@powdr/utils';

const CPNT = BlockTypes.REVEAL_VERTICAL;

// styled components
const ContentBlockRevealVertical = styled(Link)`
  display: block;
  width: 100%;
  position: relative;
  z-index: 17;

  .cb-copy {
    ${() => clamp(2)}
  }

  .cb-title {
    ${() => clamp(2)}
    margin-bottom: 15px;
  }

  &:focus:not(.overlay) {
    .cta-reveal-wrapper {
      transition: transform 0.35s ease-in-out;
      transform: translate3d(0, -40px, 0);

      ${Breakpoints.smallest(css`
        transition: transform 0 ease-in-out;
        transform: translate3d(0, 0, 0);
      `)}

      .cta-reveal-inner {
        &:before {
          display: none;
        }
      }
    }

    .button {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
      outline-offset: 2px;
      z-index: 1;
    }
  }

  &.open {
    .cta-w-tool-reveal {
      .cta-reveal-wrapper {
        transition: transform 0.35s ease-in-out;
        transform: translate3d(0, 0, 0);

        .cta-reveal-inner {
          &:before {
            border-top-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
            display: block;
            bottom: -50px;

            ${Breakpoints.smaller(css`
              bottom: -96px;
            `)}
          }

          &:after {
            display: block;
            top: -32px;
          }
        }
      }

      &:hover .cta-reveal-wrapper, &:active .cta-reveal-wrapper {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .cta-w-tool-reveal {
    margin: 0;

    &:hover:not(.overlay) {
      .cta-reveal-wrapper {
        transition: transform 0.35s ease-in-out;
        transform: translate3d(0, -40px, 0);

        ${Breakpoints.smallest(css`
          transition: transform 0 ease-in-out;
          transform: translate3d(0, 0, 0);
        `)}

        .cta-reveal-inner {
          &:before {
            display: none;
          }
        }
      }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: -1;
      height: 40px;

      .cb-link-icon  {
        margin-left: 5px;

        svg {
          fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
          color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
        }
      }

      ${Breakpoints.smallest(css`
        position: relative;
      `)}
    }
  }

  .cta-reveal-wrapper {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
    transition: transform 0.35s ease-in-out;
    transform: translate3d(0, 0, 0);
    display: block;
    text-align: center;
    height: 135px;
    z-index: 10;

    ${Breakpoints.smallest(css`
      position: relative;
      height: auto;
    `)}

    .calendar-badge {
      float: left;
      margin-top: -120px;
    }

    .cta-reveal-inner {
      position: absolute;
      margin: 20px;
      top: 0; right: 0; bottom: 0; left:0;

      ${Breakpoints.smallest(css`
        position: relative;
      `)}

      &:before, &:after {
        content: '';
        transition: all .3s ease-in;
        display: block;
        position: absolute;
        pointer-events: none;
        border: solid transparent;
        border-width: 15px;
        width: 0; height: 0;
        margin-left: -15px;
        left: 50%;
      }

      &:before {
        bottom: -32px; left: 50%;
      }

      &:after {
        top: -48px; left: 50%;
      }
    }
  }
`;

const TriangleElement = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-left-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile) + rgbaTransparencyToHex(0.75)};
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-style: solid;
  border-width: 0 0 115px 115px;

  ${Breakpoints.mobile(css`
    border-width: 0 0 65px 65px;
  `)}
`;

const AccentIconWrapper = styled.div`
  fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  position: absolute;
  top: 13px;
  left: 8px;

  ${Breakpoints.mobile(css`
    top: 6px;
    left: 3px;
  `)}
`;

const Image = styled.img`
  width: 100%;
`;

export const RevealVertical = ({
  title, copy, isOverlayEnabled, image, colorProfile, blockType, ariaLabel,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonClick,
  accentIconName, date, endDate, isStaticImageLink,
}) => {
  const { isMobile } = useContext(AppContext);

  return (
    <ContentBlockRevealVertical
      href={buttonLink}
      target={buttonTarget}
      onClick={() => buttonClick()}
      aria-label={ariaLabel}
      role="button"
      className={`content-block-${blockType} `}
      colorProfile={colorProfile}
    >
      <div className={classNames('cta-w-tool-reveal', { overlay: isOverlayEnabled })}>
        {(accentIconName) && (
          <>
            <TriangleElement colorProfile={colorProfile} />
            <AccentIconWrapper colorProfile={colorProfile}>
              <Icon name={accentIconName} height={(isMobile) ? '25' : '45'} />
            </AccentIconWrapper>
          </>
        )}
        {(image && !isStaticImageLink) && <GatsbyImage className="rv-image" image={image.src} alt={image?.altText} />}
        {(image && isStaticImageLink) && <Image className="rv-image" src={image.src} alt={image?.altText} />}
        <div className="cta-reveal-wrapper">
          <div className="cta-reveal-inner">
            {(date) && (
              <CalendarBadge
                type={blockType}
                startDate={date}
                endDate={endDate}
                colorProfile={colorProfile}
              />
            )}
            <div className="cta-content">
              <h3 className="cb-title">{title}</h3>
              <p className="cb-copy">{copy}</p>
            </div>
          </div>
        </div>
        {(buttonEnabled && buttonText) && (
          <div type="button" className="button with-background" aria-label={ariaLabel}>
            {buttonText}
            <Icon className="cb-link-icon" name="ui-plus-thick" width="10" height="10" />
          </div>
        )}
      </div>
    </ContentBlockRevealVertical>
  );
};

RevealVertical.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
