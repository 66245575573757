import classNames from 'classnames';
import React, {
  useEffect, useRef, useContext,
} from 'react';
import AnimateHeight from 'react-animate-height';
import ReactHtmlParser from 'react-html-parser';
import useResizeObserver from 'use-resize-observer';

import { Icon } from '@powdr/components';
import { AppContext } from '@powdr/context';
import { colorProfileByIndex } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledAlertBar } from './styles';

export const AlertBar = () => {
  const { closedAlert, setClosedAlert, setAlertHeight } = useContext(AppContext);
  const { headerConfig } = useStaticData();
  const alertBarContentRef = useRef(null);
  const alertBarRef = useRef(null);
  const { height } = useResizeObserver({ ref: alertBarRef });

  useEffect(() => {
    setAlertHeight((height === undefined) ? 0 : height);
  }, [height, setAlertHeight]);

  return (
    <StyledAlertBar
      className="alert-bar"
      colorProfile={colorProfileByIndex(headerConfig.alertColor)}
      ref={alertBarRef}
    >
      <AnimateHeight duration={500} height={(!closedAlert) ? 'auto' : 0}>
        <div className="alert-content-wrapper" ref={alertBarContentRef}>
          <div className={classNames('alert-content')}>
            <div className="alert-html">
              {ReactHtmlParser(headerConfig.alertCopy.value)}
            </div>
            <button type="button" onClick={() => setClosedAlert(true)} className="close" title="Close Alert Bar" aria-label="Close Alert Bar">
              <Icon name="ui-close-circle" width="36" height="36" />
            </button>
          </div>
        </div>
      </AnimateHeight>
    </StyledAlertBar>
  );
};

AlertBar.propTypes = {};

AlertBar.defaultProps = {};
