/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DorApis } from '@powdr/constants';
import { getSensors } from '@powdr/stores/actions/sensors-action';
import { getWeather } from '@powdr/stores/actions/weather-action';

// eslint-disable-next-line import/no-dynamic-require
const { gatsbyConfig } = require(`@powdr/${process.env.GATSBY_PROPERTY_NAME}`);

export const useTemperature = () => {
  const { dor } = gatsbyConfig || {};
  const dispatch = useDispatch();
  const useWeatherData = useSelector((state) => state.weather);
  const useSensorsData = useSelector((state) => state.sensors);
  const { isLoading: weatherIsLoading, error: weatherError, data: weatherData } = useWeatherData;
  const { isLoading: sensorsIsLoading, error: sensorsError, data: sensorsData } = useSensorsData;
  const [temperatureData, setTemperatureData] = useState({
    icon: 'dor-clear',
    value: null,
  });
  useEffect(() => {
    dispatch(getWeather());
    dispatch(getSensors());
  }, [dispatch]);

  useEffect(() => {
    if (!weatherIsLoading && !sensorsIsLoading) {
      const { temp: weatherTemp, icon: weatherIcon } = weatherData?.widgetWeather || {};
      const { temp: sensorsTemp } = sensorsData?.widgetWeather || {};
      const temperatureValue = (dor?.temperature?.api === DorApis.SENSORS)
        ? sensorsTemp
        : (dor?.temperature?.api === DorApis.WEATHER)
          ? weatherTemp
          : null;

      setTemperatureData({
        icon: weatherIcon,
        value: (Number.isNaN(temperatureValue) || temperatureValue === undefined)
          ? null
          : temperatureValue,
        errors: {
          weather: weatherError,
          sensors: sensorsError,
        },
      });
    }
  }, [
    dor,
    sensorsData, sensorsIsLoading, sensorsError,
    weatherData, weatherError, weatherIsLoading,
  ]);

  return temperatureData;
};
