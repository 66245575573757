import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, Components, ColorProfiles,
} from '@powdr/constants';
import { rgbaTransparencyToHex, themeUtil } from '@powdr/utils';

const CPNT = Components.FORM_FORMBUCKET;

export const StyledFormFormbucket = styled.div`
  margin-bottom: 15px;

  .form-control {
    display: block;
    margin-bottom: 15px;
    width: 100%;
    max-width: 50%;
    height: auto;
    padding: 10px 15px;
    font-size: 18px;
    line-height: 1.25;
    background-color: white;
    background-image: none;
    background-clip: padding-box;
    border: 2px solid ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    color: black;
  }

  h1, h2, h3, h4, h5, h6 {
    ${({ theme }) => theme.fonts.SEC_HDR_BLK};
    margin-bottom: 5px;
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
  }

  h1 {
    font-size: ${({ theme }) => theme.FontSize.MED30};
  }

  h2 {
    font-size: ${({ theme }) => theme.FontSize.MED10};
  }

  h3 {
    font-size: ${({ theme }) => theme.FontSize.REG40};
  }

  h4 {
    font-size: ${({ theme }) => theme.FontSize.REG40};
  }

  h5 {
    font-size: ${({ theme }) => theme.FontSize.REG30};
  }

  h6 {
    font-size: ${({ theme }) => theme.FontSize.REG30};
  }


  p, label {
    font-size: ${({ theme }) => theme.FontSize.REG30};
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  }

  p {
    margin-bottom: 15px;
    line-height: 1.2;
  }

  .l-group {
    display: block;
    margin-bottom: 30px;
  }

  .form-group {
    display: block;
    margin-bottom: 30px;
  }

  button[type="submit"] {
    display: flex;
    align-items: center;
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    font-size: ${({ theme }) => theme.FontSize.REG30};
    text-transform: uppercase;
    padding: 15px 20px;
    border: none;
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};

    .form-loader {
      margin-left: 20px;
    }

    &:hover:not(:disabled) {
      cursor: pointer;
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    }

    &:disabled {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_INACTIVE, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_INACTIVE, CPNT, colorProfile)};
      cursor: not-allowed;
    }
  }

  .recaptcha {
    margin-bottom: 15px;
  }

  input:required:focus {
    outline: none;
  }

  form {
    margin-bottom: 30px;
  }

  textarea {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 15px;
    width: 100%;
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 20px;
    color: #55595c;
  }

  .formCheckbox {
    margin-bottom: 15px;
  }

  .flex-row {
    display: flex;
    width: 100%;
    gap: 25px;
  }

  .row-item {
    width: 100%;
  }

  .flex-checkboxes {
    display: flex;
    gap: 30%;
  }

  ${Breakpoints.smallest(css`
    max-width: 100%;
  `)}
`;

export const FormStatePopup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 25%;
  background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT, ColorProfiles.PRIMARY) + rgbaTransparencyToHex(0.5)};

  .close-button {
    position: fixed;
    margin: 25px;
    top: 0;
    right: 0;
    padding: 10px;
    background-color: ${() => themeUtil(Prop.BTN_BG, CPNT, ColorProfiles.PRIMARY)};
    color: ${() => themeUtil(Prop.BTN_TXT, CPNT, ColorProfiles.PRIMARY)};

    svg {
      fill: ${() => themeUtil(Prop.BTN_TXT, CPNT, ColorProfiles.PRIMARY)};
      color: ${() => themeUtil(Prop.BTN_TXT, CPNT, ColorProfiles.PRIMARY)};
    }

    &:hover {
      background-color: ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT, ColorProfiles.PRIMARY)};
      color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT, ColorProfiles.PRIMARY)};

      svg {
        fill: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT, ColorProfiles.PRIMARY)};
        color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT, ColorProfiles.PRIMARY)};
      }
    }
  }

  div {
    h2 {
      color: ${() => themeUtil(Prop.HEADER, CPNT, ColorProfiles.PRIMARY)};
      font-size: ${({ theme }) => theme.FontSize.LRG50};
      margin-bottom: 10px;
      line-height: 1;
    }

    p {
      color: ${() => themeUtil(Prop.CONTENT, CPNT, ColorProfiles.PRIMARY)};
      ${({ theme }) => theme.fonts.PRI_BDY_MED};
      font-size: ${({ theme }) => theme.FontSize.LRG10};
      line-height: 1;
    }
  }
`;
