import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

import { MegaContainer } from '@powdr/components';
import { useOnClickedOutside } from '@powdr/hooks';
import { componentFactory } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  StyledWidgetPromotions, MegaPromoLink, MegaPromoToggle, CloseIcon,
  PromotionToggleIcon, ToggleAlignmentWrapper, PromoText,
} from './styles';

export const WidgetPromotions = ({
  onClick,
  isOpen,
  width,
  height,
}) => {
  const { headerConfig } = useStaticData();
  const {
    promoButtonTitle, promoButtonUrl, promoButtonUrlTarget,
    promoButtonIcon, isRightAlignedNav, promotions,
  } = headerConfig;
  const toggleRef = useRef(null);
  useOnClickedOutside(toggleRef, (isOpen) ? onClick : undefined);

  return (
    <StyledWidgetPromotions
      isRightAlignedNav={isRightAlignedNav}
      ref={toggleRef}
      onMouseDown={(e) => e.preventDefault()}
    >
      {(promoButtonUrl)
        ? (
          <MegaPromoLink
            title={`${promoButtonTitle} Button`}
            href={promoButtonUrl}
            target={promoButtonUrlTarget || '_blank'}
            width={width}
            height={height}
            hasIcon={!!promoButtonIcon}
          >
            <ToggleAlignmentWrapper>
              {(promoButtonIcon) && <PromotionToggleIcon name={promoButtonIcon} width="20" />}
              <PromoText>{promoButtonTitle}</PromoText>
            </ToggleAlignmentWrapper>
          </MegaPromoLink>
        )
        : (
          <MegaPromoToggle
            onClick={onClick}
            hasIcon={!!promoButtonIcon}
            width={width}
            height={height}
          >
            {(!isOpen)
              ? (
                <ToggleAlignmentWrapper>
                  {(promoButtonIcon) && <PromotionToggleIcon name={promoButtonIcon} width="20" />}
                  <PromoText>{promoButtonTitle}</PromoText>
                </ToggleAlignmentWrapper>
              )
              : <CloseIcon isOpen={isOpen} name="ui-close-circle" />}

          </MegaPromoToggle>
        )}
      {(isOpen) && (
        <MegaContainer>
          {componentFactory([promotions])}
        </MegaContainer>
      )}
    </StyledWidgetPromotions>
  );
};

WidgetPromotions.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
};

WidgetPromotions.defaultProps = {
  onClick: () => {},
  isOpen: false,
  height: null,
};
