import { VerticalPosition } from './constants';

export const CarouselTransitionEffects = {
  FADE: 'fade',
  COVERFLOW: 'coverflow',
  CARDS: 'cards',
};

export const CarouselNavigationPlacement = {
  TOP: VerticalPosition.TOP,
  BOTTOM: VerticalPosition.BOTTOM,
  ON_SLIDE: 'on-slide',
};

export const CarouselControlPlacement = {
  ...CarouselNavigationPlacement,
  IN_WRAPPER: 'in-wrapper',
};

export const CarouselPaginationPlacement = {
  ...CarouselNavigationPlacement,
  IN_CONTROLS: 'in-controls',
};

export const CarouselPaginationType = {
  FRACTION: 'fraction',
  BULLETS: 'bullets',
  ICON_BULLETS: 'icon-bullets',
  TEXT_BULLETS: 'text-bullets',
  CUSTOM: 'custom',
};

export const CustomPaginationOptions = {
  NO_INDICATORS: 1,
  ICON_INDICATORS: 2,
  TEXT_INDICATORS: 3,
};
