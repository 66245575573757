import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components, Breakpoints, GlobalLayoutValues,
} from '@powdr/constants';
import { componentColor } from '@powdr/utils';

const CPNT = Components.HEADER;

export const StyledSeasonalityTab = styled.div`
  pointer-events: all;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  transition: width 300ms ease-out, top 0.6s 300ms;
  overflow: hidden;
  margin-top: ${`${GlobalLayoutValues.DESKTOP.SEASON_TAB_GAP}px`};
  width: 50px;
  z-index: 9;
  background: ${() => componentColor(Prop.NAV_BG, CPNT, Prop.BACKGROUND)};

  &:focus-within {
    outline: 2px solid ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT, Prop.LINK_TXT_HOVER)};
    outline-offset: 2px;
  }

  .season-toggle {
    height: ${`${GlobalLayoutValues.DESKTOP.SEASON_TAB_HEIGHT}px`};
    position: relative;
    left: unset;

    ul.season-buttons {
      li {
        transition: left 0s;
        top: 8px;
        left: 14px;
        padding: 0;
        position: absolute;
        width: 80px;
        height: auto;
      }

      // winter active
      &.winter {
        .winter-toggle {
          span {
            transition: opacity 300ms ease-in, visibility 0s 300ms;
            opacity: 0;
            visibility: hidden;
          }
        }

        .summer-toggle {
          transition:
            left 0s 300ms ease-out,
            opacity 300ms ease-in,
            visibility 0s 300ms;
          opacity: 0;
          visibility: hidden;
        }
      }

      // summer active
      &.summer {
        .summer-toggle {
          transition: left 300ms ease-out;
          left: 14px;

          span {
            transition: opacity 300ms ease-in, visibility 0s 300ms;
            opacity: 0;
            visibility: hidden;
          }
        }

        .winter-toggle {
          transition: opacity 150ms ease-in, visibility 0s 150ms;
          opacity:0 ;
          visibility: hidden;
        }
      }
    }
  }

  &.active {
    width: 200px;

    .season-toggle {
      .season-buttons {
        .summer-toggle {
          left: 100px;
        }

        // winter active
        &.winter {
          .winter-toggle {
            span {
              transition: opacity 300ms 300ms ease-out, visibility 0s 300ms;
              opacity: 1;
              visibility: visible;
            }
          }

          .summer-toggle {
            transition: opacity 300ms 300ms ease-out, visibility 0s 300ms;
            opacity: 1;
            visibility: visible;
          }
        }

        // summer active
        &.summer {
          .summer-toggle {
            left: 100px;
            transition: left 300ms ease-out;
            span {
              transition: opacity 300ms 300ms ease-out, visibility 0s 300ms;
              opacity: 1;
              visibility: visible;
            }
          }

          .winter-toggle {
            transition: opacity 300ms 300ms ease-out, visibility 0s 300ms;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  ${Breakpoints.mobile(css`
    display: none;
  `)};
`;
