import styled from 'styled-components';

import { ThemeProps as Prop, Components } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.BOOKING_WIDGET;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG50};
  padding: 16px 20px;
  background-color: ${({ $colorProfile, $colors }) => (($colors) ? $colors.BTN_BG : themeUtil(Prop.BTN_BG, CPNT, $colorProfile))};
  color: ${({ $colorProfile, $colors }) => (($colors) ? $colors.BTN_TXT : themeUtil(Prop.BTN_TXT, CPNT, $colorProfile))};
  transition: color 0.25s ease-out, background-color 0.25s ease-out;

  ${({ disabled, $colorProfile, $colors }) => (disabled ? `
    cursor: default;
    opacity: .5;
  ` : `
    &:hover, &:focus {
      transition: color 0.25s ease-out, background-color 0.25s ease-out;
      background-color: ${(($colors) ? $colors.BTN_BG_HOVER : themeUtil(Prop.BTN_BG_HOVER, CPNT, $colorProfile))};
      color: ${(($colors) ? $colors.BTN_TXT_HOVER : themeUtil(Prop.BTN_TXT_HOVER, CPNT, $colorProfile))};
    }
  `)};
`;
