import styled, { css } from 'styled-components';

import { Link } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Components, HeaderStyles,
} from '@powdr/constants';
import { componentColor, themeUtil } from '@powdr/utils';

const CPNT = Components.HEADER;

export const StyledNavigationCTA = styled(Link)`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG30};
  transition: 0.25s all ease-in-out;
  cursor: pointer;
  line-height: 1;

  ${({ $useButtonStyles, $background }) => (($useButtonStyles) ? `
    padding: 10px 50px;
    color: ${themeUtil(Prop.BTN_TXT, CPNT)};
    background-color: ${themeUtil(Prop.BTN_BG, CPNT)};

    &:hover {
      color: ${themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
      background-color: ${themeUtil(Prop.BTN_BG_HOVER, CPNT)};
    }
  ` : `
    display: flex;
    align-items: center;
    height: 100%;
    color: ${($background === HeaderStyles.TRANSLUCENT)
      ? componentColor(Prop.NAV_ITEM_TXT_TRANSLUCENT, CPNT, Prop.CONTENT)
      : componentColor(Prop.NAV_ITEM_TXT_SOLID, CPNT, Prop.CONTENT)};

    &:hover {
      color: ${componentColor(Prop.NAV_ITEM_TXT_ACTIVE, CPNT, Prop.CONTENT)};
    }
  `)};

  ${Breakpoints.mobile(css`
    ${({ $useButtonStyles }) => (($useButtonStyles) ? 'padding: 10px;' : '')}
  `)}
`;
