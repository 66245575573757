import styled, { css } from 'styled-components';

import { ThemeProps as Prop, Components, Breakpoints } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.CONTENT_BLOCK_HERO;

export const StyledContentBlockHero = styled.div`
  position: relative;
  z-index: 1;
  ${({ heroHeight, isImageDictatesHeight }) => {
    if (isImageDictatesHeight) return 'height: 100%;';
    if (heroHeight) {
      return `
        height: ${heroHeight}vh;
        max-height: ${heroHeight}vh;
        min-height: ${heroHeight}vh;
      `;
    }
    return 'height: 500px';
  }};

  // offset mobile styles overrides
  ${Breakpoints.mobile(css`
    padding-left: 0 !important;
    padding-right: 0 !important;
  `)}

  .hero-video-overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;

    button.btn-close {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 15;

      .icon-c {
        margin: 0 auto;

        svg {
          fill: #fff;
          color: #fff;
        }
      }
    }

    iframe {
      margin: 0 auto;
      height: 100%;
      width: 100%;
    }
  }
`;

export const ScrollButton = styled.button`
  position: absolute;
  left: calc(50% - 25px);
  bottom: 25px;
  z-index: 101;

  svg {
    fill: ${() => themeUtil(Prop.BTN_BG, CPNT)};
    color: ${() => themeUtil(Prop.BTN_BG, CPNT)};
  }
`;
