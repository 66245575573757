// eslint-disable-next-line import/no-unresolved
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import { Link } from '@powdr/components';
import { Icon } from '@powdr/components/icon';
import { AppContext } from '@powdr/context';

import {
  CloseButton,
  CloseButtonAlt,
  ModalOverlay,
  DialogAlert,
  DialogPage,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from './styles';

export const Modal = ({
  children,
  closeModalLink,
  header,
  footer,
  handleCloseModal,
  isDialogAlert,
  isFullScreen,
  colorProfile,
}) => {
  const {
    toggleBodyScroll,
  } = useContext(AppContext);

  useEffect(() => {
    toggleBodyScroll(true);
    return () => {
      toggleBodyScroll(false);
    };
  }, [toggleBodyScroll]);

  const onCloseModal = () => {
    if (closeModalLink) {
      navigate(closeModalLink);
    } else {
      handleCloseModal();
    }
  };

  return (
    <ModalOverlay $colorProfile={colorProfile}>
      { isDialogAlert
        ? (
          <DialogContent isDialogAlert={isDialogAlert} $colorProfile={colorProfile}>
            <DialogAlert $colorProfile={colorProfile}>
              {children}
            </DialogAlert>
          </DialogContent>
        )
        : (
          <DialogPage isFullScreen={isFullScreen} $colorProfile={colorProfile}>
            <Link href={closeModalLink}>
              { isFullScreen
                ? (
                  <CloseButtonAlt aria-label="close" onClick={onCloseModal} type="button" $colorProfile={colorProfile}>
                    <span className="close">Close</span>
                    <span className="button"><Icon name="ui-close-circle" width="36" height="36" /></span>
                  </CloseButtonAlt>
                )
                : (
                  <CloseButton aria-label="close" onClick={onCloseModal} type="button" $colorProfile={colorProfile}>
                    <Icon name="ui-close" width="15" height="15" />
                  </CloseButton>
                )}
            </Link>
            <DialogContent $colorProfile={colorProfile}>
              <SimpleBar style={{ height: '100%' }}>
                { header && <DialogHeader>{header}</DialogHeader>}
                <DialogBody>{children}</DialogBody>
                { footer && <DialogFooter>{footer}</DialogFooter>}
              </SimpleBar>
            </DialogContent>
          </DialogPage>
        )}
    </ModalOverlay>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  closeModalLink: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
  handleCloseModal: PropTypes.func,
  isDialogAlert: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  colorProfile: PropTypes.string,
};

Modal.defaultProps = {
  closeModalLink: null,
  header: null,
  footer: null,
  handleCloseModal: () => {},
  isDialogAlert: false,
  isFullScreen: false,
  colorProfile: null,
};
