import styled, { css } from 'styled-components';

import { Link, Icon } from '@powdr/components';
import { ThemeProps as Prop, Breakpoints, Components } from '@powdr/constants';
import { themeUtil, compo } from '@powdr/utils';

const CPNT = Components.LINK_LIST;

export const StyledLinkList = styled.div``;

export const LinkTable = styled.table`
  width: 80%;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  display: table-row;
  border-bottom: 1px solid ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};

  &:last-child {
    border-bottom: none;
  }
`;

export const TableCell = styled.td`
  display: table-cell;
  padding: 10px 0;
  width: auto;
`;

export const CellLinkWrapper = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    a {
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }
  }
`;

export const CellLink = styled(Link)`
  font-size: ${({ theme }) => theme.FontSize.REG30};
  line-height: ${({ theme }) => theme.FontSize.REG30};
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
  transition: all .25s;

  ${Breakpoints.large(css`
    font-size: ${({ theme }) => theme.FontSize.REG40};
    line-height: ${({ theme }) => theme.FontSize.REG40};
  `)}
`;

export const CellIcon = styled(Icon)`
  transform: rotate(-90deg);

  svg {
    transition: all .25s;
    fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
  }
`;
