import { combineReducers } from 'redux';

import conditionsReducer from './conditions-reducer';
import drupalReducer from './drupal-reducer';
import ecommerceReducer from './ecommerce-reducer';
import loggingReducer from './logging-reducer';
import mediaReducer from './media-reducer';
import nodeReducer from './node-reducer';
import sensorsReducer from './sensors-reducer';
import statusReducer from './status-reducer';
import weatherReducer from './weather-reducer';

// TODO: Add additional DOR feeds to the combined reducer
export default combineReducers({
  conditions: conditionsReducer,
  drupal: drupalReducer,
  ecommerce: ecommerceReducer,
  logging: loggingReducer,
  media: mediaReducer,
  node: nodeReducer,
  sensors: sensorsReducer,
  status: statusReducer,
  weather: weatherReducer,
});
