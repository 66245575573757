import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { StyledDorPercentWheel } from './styles';

export const DorPercentWheel = ({
  parentColorProfile,
  totalVal,
  openVal,
  strokeWidth,
  isParentNavFeed,
}) => {
  const [strokeDashoffset, setStrokeDashoffset] = useState(340);
  const [strokeDasharray, setStrokeDasharray] = useState(0);

  useEffect(() => {
    const percent = Math.round((openVal / totalVal) * 100);
    const progress = percent / 100;
    const rad = 54;
    const circ = 2 * Math.PI * rad;
    const dashoffset = circ * (1 - progress);

    setStrokeDasharray(circ);

    if (openVal === 0 || openVal === '0') {
      setStrokeDashoffset(340);
    } else {
      setStrokeDashoffset(dashoffset);
    }
  }, [strokeDasharray, strokeDashoffset]);

  return (
    <StyledDorPercentWheel colorProfile={parentColorProfile} isParentNavFeed={isParentNavFeed}>
      <svg className="progress" viewBox="0 0 120 120">
        <circle
          className="meter"
          cx="60"
          cy="60"
          r="54"
          strokeWidth={strokeWidth}
        />
        <circle
          className="value"
          cx="60"
          cy="60"
          r="54"
          strokeWidth={strokeWidth}
          strokeDashoffset={strokeDashoffset}
          strokeDasharray={strokeDasharray}
        />
        <text
          x="50%"
          y="54%"
          shapeRendering="geometricPrecision"
          textAnchor="middle"
          fontWeight="bolder"
          fontSize="28"
        >
          {openVal}
        </text>
        <text
          x="50%"
          y="68%"
          shapeRendering="geometricPrecision"
          textAnchor="middle"
          fontSize="12"
        >
          of
          {' '}
          {totalVal}
        </text>
      </svg>
    </StyledDorPercentWheel>
  );
};

DorPercentWheel.propTypes = {
  totalVal: PropTypes.number,
  openVal: PropTypes.number,
  strokeWidth: PropTypes.number,
  parentColorProfile: PropTypes.number,
  isParentNavFeed: PropTypes.bool,
};

DorPercentWheel.defaultProps = {
  totalVal: 0,
  openVal: 0,
  strokeWidth: 8,
  parentColorProfile: null,
  isParentNavFeed: false,
};
