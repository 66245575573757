import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useEffect, useState, useMemo,
} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '@powdr/components';
import { HideOptions, LiftTrailReportsStats, StatusStates } from '@powdr/constants';
import { getConditions } from '@powdr/stores/actions/conditions-action';
import { getStatus } from '@powdr/stores/actions/status-action';
import { slugify } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import { DorPercentWheel } from '../dor-percent-wheel/dor-percent-wheel';

import { StyledDorTrailReport } from './styles';

export const DorTrailReport = ({
  title,
  html,
  hide,
  isParentNavFeed,
  filterByTrailReport,
  filterByTrailType,
  ignoreLiftSubtypes,
  parentColorProfile,
}) => {
  const { property } = useStaticData();
  const dispatch = useDispatch();
  const useConditionsData = useSelector((state) => state.conditions);
  const useStatusData = useSelector((state) => state.status);
  const {
    isLoading: isConditionsLoading,
    error: conditionsError,
    data: conditionsData,
  } = useConditionsData;
  const {
    isLoading: isStatusLoading,
    error: statusError,
    data: statusData,
  } = useStatusData;
  const [trailReportData, setTrailReportData] = useState({});
  const [openLiftsToIgnore, setOpenLiftsToIgnore] = useState(0);
  const [totalLiftsToIgnore, setTotalLiftsToIgnore] = useState(0);

  useEffect(() => {
    dispatch(getConditions());
    dispatch(getStatus());
  }, [dispatch]);

  useEffect(() => {
    setTrailReportData(conditionsData?.dorTrailReport);
  }, [conditionsData]);

  useEffect(() => {
    if (statusData && ignoreLiftSubtypes?.length > 0) {
      Object.values(statusData).forEach((i) => {
        if (ignoreLiftSubtypes.includes(i?.properties?.subtype)) {
          setTotalLiftsToIgnore((count) => count + 1);
          if (StatusStates.OPENING.includes(i?.properties?.global_status)
            || StatusStates.EXPECTED.includes(i?.properties?.global_status)) {
            setOpenLiftsToIgnore((count) => count + 1);
          }
        }
      });
    }
  }, [statusData, ignoreLiftSubtypes]);

  const HeaderRender = useMemo(() => (
    <div className="dtr-header">
      {title && (
        <h3>{title}</h3>
      )}
      {html && ReactHtmlParser(html)}
    </div>
  ), [title, html]);

  const BodyRender = useMemo(() => (
    <ul className="dtr-grid">
      {trailReportData?.reports
        ?.map((item, i) => {
          const intersect = [
            ...(filterByTrailReport || []),
            ...(filterByTrailType || []),
          ].some((f) => item.alias.includes(f));

          return intersect && (
            <li
              className={
                classNames(
                  'dtr-col',
                  'animated',
                  'fadeInUp',
                  `u-an-de-${i}`,
                )
              }
              key={item.key}
            >
              <DorPercentWheel
                colorProfile={parentColorProfile}
                totalVal={(item.label === LiftTrailReportsStats[1].label)
                  ? item.total - totalLiftsToIgnore
                  : item.total}
                openVal={(item.label === LiftTrailReportsStats[1].label)
                  ? item.open - openLiftsToIgnore
                  : item.open}
                isParentNavFeed={isParentNavFeed}
              />
              <hr />
              <h3>{item.label}</h3>
            </li>
          );
        }) || []}
    </ul>
  ), [filterByTrailReport, filterByTrailType, isParentNavFeed, openLiftsToIgnore,
    parentColorProfile, totalLiftsToIgnore, trailReportData]);

  const DetailsRender = useMemo(() => (
    <div className="dtr-details">
      {trailReportData?.updates?.map((u) => (
        <>
          {u.title && !hide.includes(HideOptions.TITLE)
            && (<h3>{u.title}</h3>)}
          {u.publication_date && !hide.includes(HideOptions.DATE)
            && (<small>{u.publication_date}</small>)}
          {u.html && !hide.includes(HideOptions.HTML)
            && ReactHtmlParser(html)}
        </>
      ))}
    </div>
  ), [hide, html, trailReportData]);

  return (
    <StyledDorTrailReport
      colorProfile={parentColorProfile}
      className={classNames('dor-trail-report', {
        feed: isParentNavFeed, 'no-feed': !isParentNavFeed,
      })}
      property={property}
      isParentNavFeed={isParentNavFeed}
    >
      {isConditionsLoading || isStatusLoading ? (
        <Loader
          className="icon"
          classAnimationEffect="loader-animated spin infinite"
          iconName="dor-sunny"
          size="30"
        />
      ) : (
        <>
          {(!isParentNavFeed && (title || html)) && HeaderRender}
          {(trailReportData?.reports && statusData) && BodyRender}
          {(!isParentNavFeed && trailReportData?.updates) && DetailsRender}
        </>
      )}
    </StyledDorTrailReport>
  );
};

DorTrailReport.propTypes = {
  title: PropTypes.string,
  html: PropTypes.string,
  isParentNavFeed: PropTypes.bool,
  hide: PropTypes.arrayOf(PropTypes.string),
  filterByTrailReport: PropTypes.arrayOf(PropTypes.string),
  filterByTrailType: PropTypes.arrayOf(PropTypes.string),
  ignoreLiftSubtypes: PropTypes.arrayOf(PropTypes.string),
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

DorTrailReport.defaultProps = {
  hide: [],
  title: null,
  html: null,
  isParentNavFeed: false,
  filterByTrailReport: [],
  filterByTrailType: [],
  ignoreLiftSubtypes: [],
  parentColorProfile: null,
};
