import PropTypes from 'prop-types';
import React from 'react';

import { useHeaderBackground } from '@powdr/hooks';
import { componentFactory } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledMiniComponentSection } from './styles';

export const MiniComponentSection = ({
  className,
  components,
}) => {
  const { headerConfig } = useStaticData();
  const background = useHeaderBackground();

  return (
    <StyledMiniComponentSection
      className={className}
      background={background}
      opacity={headerConfig.navigationOpacityPercentage}
    >
      {componentFactory(components)}
    </StyledMiniComponentSection>
  );
};

MiniComponentSection.propTypes = {
  className: PropTypes.string,
  components: PropTypes.arrayOf({}).isRequired,
};

MiniComponentSection.defaultProps = {
  className: '',
};
