import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components, Breakpoints, ComponentGroups, Layouts,
} from '@powdr/constants';
import { themeUtil, componentFontSize } from '@powdr/utils';

const CPNT = Components.DOR_SNOW_TOTALS;

export const StyledDorSnowTotals = styled.div`
  margin-bottom: 30px;
  ${Breakpoints.desktopOnly(css`
    ${({ layout }) => ((layout === Layouts.STACKED) ? '' : 'display: flex; align-items: flex-start;')}
  `)}

  h1, h2, h3, h4, h5, h6 {
    a { font-size: inherit; }
  }

  h1 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h1')}
  }

  h2 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h2')}
  }

  h3 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h3')}
  }

  h4 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h4')}
  }

  h5 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h5')}
  }

  h6 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h6')}
  }

  a:not(.btn) {
    transition: color .25s ease-out, background-color .25s ease-out;
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};

    &:hover, &:focus {
      transition: color .25s ease-out, background-color .25s ease-out;
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }

    &:focus {
      outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};;
      outline-offset: 2px;
    }
  }

  .btn {
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'button')}
    line-height: 1;
    border: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    width: auto;
    height: auto;
    padding: 16px 20px;
    text-transform: none;
    margin: 0;
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    font-weight: bold;
    transition: color .25s ease-out, background-color .25s ease-out;

    &:hover, &:focus {
      transition: color .25s ease-out, background-color .25s ease-out;
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }

    &:focus {
      outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};;
      outline-offset: 2px;
    }
  }

  .col {
    ${Breakpoints.desktopOnly(css`
      ${({ layout }) => ((layout === Layouts.STACKED) ? '' : 'width: 50%;')}
    `)}
  }

  .dst-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 15px;

    .dst-item.col {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      ${Breakpoints.desktopOnly(css`
        ${({ layout }) => ((layout === Layouts.STACKED) ? 'flex: 1;' : 'width: 47%;')}
      `)}

      ${Breakpoints.mobile(css`
        flex: 1;
        min-width: 33.333333%;
      `)}
    }

    .icon {
      justify-content: center;
      display: flex;
      margin: 0 auto;
      margin-bottom: 10px;

      svg {
        fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
        color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
      }
    }

    hr {
      width: 100%;
      height: 1px;
      background-color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
      border: none;
    }

    h3.amount,
    p.duration  {
      color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
      margin-bottom: 5px;
      text-align: center;
      text-transform: capitalize;
    }

    h3.amount {
      font-size: ${({ theme }) => theme.FontSize.MED40};
    }

    p.duration {
      font-size: ${({ theme }) => theme.FontSize.REG30};
      margin-bottom: 10px;
    }

    p {
      ${({ theme }) => theme.fonts.PRI_BDY_MED};
    }
  }


  ${Breakpoints.desktopOnly(css`
    .dst-details {
      ${({ layout }) => ((layout === Layouts.STACKED) ? '' : 'width: 50%;')}
    }
  `)}

  .dst-header,
  .dst-details {
    p {
      line-height: 20px;
    }

    u {
      text-decoration: underline;
    }

    strong {
      font-weight: bold;
    }

    em {
      font-style: italic;
    }

    li, p {
      ${({ theme }) => theme.fonts.PRI_BDY_MED};
      font-size: ${({ theme }) => theme.FontSize.REG40};
    }

    h3,
    p {
      margin-bottom: 15px;
      color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    }

    a {
      transition: all .25s linear;
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};

      &:hover {
        color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
      }
    }
  }

`;

export const Timestamp = styled.small`
  ${({ theme }) => theme.fonts.PRI_BDY_MED};
  font-size: ${({ theme }) => theme.FontSize.REG20};
  line-height: 1;
  color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
  margin-bottom: 15px;
`;
