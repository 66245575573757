import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, Components, Breakpoints, GlobalLayoutValues,
} from '@powdr/constants';
import { themeUtil, componentColor } from '@powdr/utils';

const CPNT = Components.WEATHER_FEED;

export const StyledWidgetWeather = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  .row {
    display: flex;
    flex-direction: row;
  }

  .row.depth,
  .row.amount {
    align-items: center;
  }

  .col {
    display: flex;
    flex-direction: column;
  }
`;

export const MegaDorFeedToggle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(isOpen) => ((isOpen) ? '7px' : '10px')};
  height: 100%;
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT)};

  // prevent child elements from being clickable
  > * {
    pointer-events: none;
  }

  .integer,
  .status {
    align-items: center;
    display: flex;
    color: ${() => componentColor(Prop.CONTENT, CPNT)};
    ${({ theme }) => theme.fonts.PRI_HDR_BLK};
    font-size: ${({ theme }) => theme.FontSize.MED20};
    font-weight: 700;
    margin: 0px 4px;
  }

  svg {
    fill: ${() => componentColor(Prop.ICON, CPNT)};
    color: ${() => componentColor(Prop.ICON, CPNT)};
  }

  ${Breakpoints.desktopOnly(css`
    width: ${({ width }) => `${width}px`};
  `)}

  ${Breakpoints.mobile(css`
    width: ${`${GlobalLayoutValues.MOBILE.WEATHER_WIDGET_WIDTH}px`};
  `)}

  &:focus {
    outline: 2px solid ${() => componentColor(Prop.CONTENT, CPNT)};
    outline-offset: -2px;
    z-index: 1;
  }
`;

export const CloseIcon = styled(Icon)`
  margin: 0 auto;
  height: 100%;
  max-width: 50px;
`;
