import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, Components,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

export const TabsWrapper = styled.div`
  padding: 25px 50px;

  ${Breakpoints.mobile(css`
    padding: 30px 15px;
  `)}
`;

export const TabsHeader = styled.ul`
  box-shadow: 0 7px 6px -6px #cccccc;
  display: flex;
  justify-content: space-around;
`;

export const TabTitle = styled.li`
  display: flex;
  flex: 1;
  justify-content: center;

  &.active {
    button {
      border-bottom: 1px solid black;
      font-weight: bold;
    }
  }
`;

export const StyledButton = styled.button`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG40};
  padding: 0 30px 5px 30px;
  width: 100%;
`;
