import styled, { css } from 'styled-components';

import { Link } from '@powdr/components';
import { DatePicker } from '@powdr/components/widgets/datepicker';
import { Select } from '@powdr/components/widgets/select';
import { ThemeProps as Prop, Breakpoints, Components } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.BOOKING_WIDGET;

export const StyledTabField = styled.div`
  color: #55595c;
  position: relative;
  text-align: left;
  display: inline-block;
  margin-bottom: 15px;
  margin-right: 15px;
  width: 200px;
  min-height: 47.5px;

  ${Breakpoints.mobile(css`
    display: block;
    width: 100%;
  `)}

  &.w-300 {
    width: 300px;
  }
`;

export const StyledTabContent = styled.div`
  padding: 0 7.5%;
  text-align: center;
  ${({ show, is5050 }) => (show ? css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${() => ((is5050) ? 'column' : 'row')};
  ` : css`
    display: none;
  `)}

  ${Breakpoints.mobile(css`
    display: ${({ show }) => (show ? 'block' : 'none')};
    padding: 15px 0;
  `)}

`;

export const StyledTabIcon = styled.span`
  display: none;
  top: calc(50% - 8px);
  right: 15px;
  width: 16px;
  height: 16px;
  overflow: hidden;
  position: absolute;

  &:before,
  &:after {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
    position: absolute;
  }

  &:before {
    content: '';
    width: 100%;
    height: 4px;
    top: calc(50% - 2px);
    left: 0;
  }

  &:after {
    content: '';
    width: 4px;
    height: 100%;
    left: calc(50% - 2px);
  }

  ${Breakpoints.mobile(css`
    display: inline-block;
  `)}
`;

const baseButtonStyles = css`
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_INACTIVE, CPNT, colorProfile)};
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_INACTIVE, CPNT, colorProfile)};
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-align: center;
  width: auto;
  height: auto;
  padding: 16px 20px;
  font-size: ${({ theme }) => theme.FontSize.REG30};
  text-transform: uppercase;
  margin: 0 0 15px 0;
  max-height: 500px;

  &:hover:not(.disabled) {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
  }

  &:focus, &&.active {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
  }

  &.disabled {
    cursor: not-allowed;
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_INACTIVE, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_INACTIVE, CPNT, colorProfile)};
  }
`;

export const TabExecuteButton = styled(Link)`
  ${baseButtonStyles}
  margin: 0;
  line-height: normal;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
`;

export const StyledTab = styled.li`
  ${baseButtonStyles}
  width: 100%;
  margin-bottom: 15px;

  &.active {
    &::after {
      border-top-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
      content: '';
      position: absolute;
      bottom: -7px;
      left: 0;
      width: 0;
      right: 0;
      margin: auto;
      height: 0;
      border-style: solid;
      border-width: 15px 20px 0 20px;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }

    ${StyledTabIcon}:after {
      content: '';
      display: none;
    }

    ${Breakpoints.smallest(css`
      margin-bottom: 0;
    `)}
  }

  ${Breakpoints.large(css`
    font-size: ${({ theme }) => theme.FontSize.REG40};
  `)}

  ${Breakpoints.larger(css`
    font-size: ${({ theme }) => theme.FontSize.REG50};
  `)}

  ${Breakpoints.smallest(css`
    text-align: left;
  `)}
`;

export const StyledDesktopTab = styled(StyledTab)`
  margin: 0px 1px;
  width: 100%;

  ${Breakpoints.smallest(css`
    display: none;
  `)}
`;

export const StyledMobileTab = styled(StyledTab)`
  display: none;

  ${Breakpoints.smallest(css`
    ${({ isSingleTab }) => ((isSingleTab) ? 'display: none;' : 'display: inline-block;')}
    width: 100%;
  `)}
`;

export const StyledDesktopTabGroup = styled.ul`
  ${({ isSingleTab }) => ((isSingleTab) ? 'display: none;' : 'display: block;')}
  margin-bottom: 15px;

  ${({ isSingleTab, justified }) => justified && css`
    ${() => ((isSingleTab) ? 'display: none;' : 'display: flex;')}
    flex-wrap: nowrap;
    flex-flow: row;
    justify-content: space-between;

    ${Breakpoints.smallest(css`
      flex-flow: column;
    `)}
  `}

  ${Breakpoints.smallest(css`
    display: none !important;
  `)}
`;

export const StyledBookingWidget = styled.div`
  /* Increase specificity of StyledTab */
  ${StyledTab} {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_INACTIVE, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_INACTIVE, CPNT, colorProfile)};
  }
`;

export const StyledSelect = styled(Select)`
  ${({ is5050 }) => ((is5050) ? 'width: 100%; max-width: none; margin-bottom: 10px;' : '')}
`;

export const StyledDatePicker = styled(DatePicker)`
  ${({ is5050 }) => ((is5050) ? 'width: 100%; max-width: none; margin-bottom: 10px;' : '')}
`;
