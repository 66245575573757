import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import { BlockTypes, Breakpoints, ThemeProps as Prop } from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { themeUtil, clamp } from '@powdr/utils';

const CPNT = BlockTypes.BASIC_3;

// styled components
const ContentBlockBasic3 = styled(Link)`
  .basic-3-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 50px;

    p, .button-wrapper, .image-wrapper {
      text-align: center;
    }

    .image-wrapper {
      margin-bottom: 5px;
      width: 100%;
      max-width: 1000px;

      &.has-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        width: 80%;
        height: 80%;

        ${Breakpoints.mobile(css`
          width: 30%;
          margin: 15px auto 30px auto;
        `)}
      }

      .icon-bg {
        display: flex;
        justify-content: center;
        background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
        border-radius: 50%;
        padding: 17%;

        ${Breakpoints.mobile(css`
          padding: 30%;
        `)}

        .icon-squaring-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          height: 120px;
          width: 120px;
        }

        .icq-wrapper {
          margin: auto;
        }

        .icq-wrapper, .icon-squaring-container {
          ${Breakpoints.larger(css`
            height: 150px
            width: 150px;
          `)}

          ${Breakpoints.large(css`
            height: 120px
            width: 120px;
          `)}

          ${Breakpoints.small(css`
            height: 100px
            width: 100px;
          `)}

          ${Breakpoints.smaller(css`
            height: 90px
            width: 90px;
          `)}

          ${Breakpoints.smallest(css`
            height: 150px
            width: 150px;
          `)}

          ${Breakpoints.portraitSmall(css`
            height: 100px
            width: 100px;
          `)}
        }
      }
    }
  }

  p {
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    margin-bottom: 20px;
    line-height: normal;
  }

  .cb-copy {
    ${() => clamp(6)}
    ${Breakpoints.desktopOnly(css`
      margin-top: 15px;
    `)}
  }

  .cb-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
    ${() => clamp(3)}

    ${Breakpoints.desktopOnly(css`
      height: 75px;
    `)}
  }

  .cb-icon svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
  }

  &:hover .button, &:focus .button {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
  }

  &:focus .button {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    outline-offset: 2px;
  }
`;

const Image = styled.img``;

export const Basic3 = ({
  title, copy, blockType, colorProfile, ariaLabel,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonClick,
  iconName, image, isOverlayEnabled, isStaticImageLink,
}) => (
  <ContentBlockBasic3
    href={buttonLink}
    target={buttonTarget}
    colorProfile={colorProfile}
    onClick={() => buttonClick()}
    aria-label={ariaLabel}
    role="button"
    className={`content-block-${blockType} `}
  >
    <div className="basic-3-container">
      <h3 className="cb-title">{title}</h3>
      <div className={classNames('image-wrapper', { 'has-icon': iconName && !image })}>
        {(!iconName && image && !isStaticImageLink)
          && <GatsbyImage image={image.src} alt={image?.altText} />}
        {(!iconName && image && isStaticImageLink)
          && <Image src={image.src} alt={image?.altText} />}
        {(iconName && !image) && (
        <div className="icon-bg">
          <div className="icq-wrapper">
            <div className="icon-squaring-container">
              <Icon className="cb-icon" name={iconName} />
            </div>
          </div>
        </div>
        )}
      </div>
      {(copy) && (
      <p className="cb-copy">
        {copy}
      </p>
      )}
      {(buttonEnabled && buttonText && !isOverlayEnabled) && (
      <div className="button-wrapper">
        <div className="button with-background uppercase" aria-label={buttonText}>{buttonText}</div>
      </div>
      )}
    </div>
  </ContentBlockBasic3>
);

Basic3.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
