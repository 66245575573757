/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, {
  useContext,
  useRef,
} from 'react';

import {
  Icon, Loader, MegaContainer, DorFeed,
} from '@powdr/components';
import { Symbols } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useOnClickedOutside, useTemperature } from '@powdr/hooks';
import { useSnowfallMeasurement } from '@powdr/hooks/use-snowfall-measurement';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledWidgetWeather, MegaDorFeedToggle, CloseIcon } from './styles';

// TODO: Look into refactoring this so that it and the mini nav component
// weather indicator can be merged
export const WidgetWeather = ({
  onClick,
  isOpen,
  width,
  height,
}) => {
  const { isMobile } = useContext(AppContext);
  const temperatureData = useTemperature();
  const snowfallData = useSnowfallMeasurement();
  const toggleRef = useRef(null);
  const { headerConfig } = useStaticData();
  const { feedTempHide, feedSnowfallHide } = headerConfig;
  useOnClickedOutside(toggleRef, (isOpen) ? onClick : undefined);

  // TODO: Fix widget flicker / na / 0 ...

  return (
    <StyledWidgetWeather
      ref={toggleRef}
      height={height}
      onMouseDown={(e) => e.preventDefault()}
    >
      <MegaDorFeedToggle
        onClick={onClick}
        width={width}
        height={height}
        isOpen={isOpen}
      >
        {!isOpen || isMobile ? (
          (!temperatureData && !snowfallData) ? (
            <Loader
              className="icon"
              classAnimationEffect="loader-animated spin infinite"
              iconName="dor-sunny"
              size="30"
            />
          ) : (
            <>
              {(!feedTempHide) && (
                <span className="amount row">
                  <Icon name={temperatureData.icon} width="20" height="20" />
                  <span className="integer">
                    {`${(temperatureData.value === null) ? Symbols.DOUBLE_DASH : Math.round(temperatureData.value)}${Symbols.DEGREE}`}
                  </span>
                </span>
              )}
              {(!feedSnowfallHide) && (
                <span className="depth row">
                  <Icon name="dor-snow" width="20" height="20" />
                  <span className="integer">
                    {`${(snowfallData.value === null) ? Symbols.DOUBLE_DASH : snowfallData.value}${snowfallData.unit}`}
                  </span>
                </span>
              )}
            </>
          )
        ) : (
          <CloseIcon isOpen={isOpen} name="ui-close-circle" />
        )}
      </MegaDorFeedToggle>
      {(isOpen && !isMobile) && (
        <MegaContainer onClick={(e) => { e.stopPropagation(); }}>
          <DorFeed />
        </MegaContainer>
      )}
    </StyledWidgetWeather>
  );
};

WidgetWeather.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
};
WidgetWeather.defaultProps = {
  isOpen: false,
  height: null,
};
