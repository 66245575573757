import styled, { css } from 'styled-components';

import { PriceRange } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Components, ComponentGroups,
} from '@powdr/constants';
import { themeUtil, componentFontSize } from '@powdr/utils';

const CPNT = Components.MODAL;

export const StyledTemplatedContent = styled.section`
  display: flex;
  flex-direction: column;
  gap: 70px;
  ${({ theme }) => theme.fonts.PRI_BDY_MED};
  padding: 40px 7.5% 100px 7.5%;

  h1, h2, h3, h4, h5, h6 {
    color: ${() => themeUtil(Prop.HEADER, CPNT)};
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  li, p {
    color: ${() => themeUtil(Prop.CONTENT, CPNT)};
    line-height: 1.3;
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content')}

    ${Breakpoints.large(css`
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content-large-breakpoint')}
    `)}
  }

  li {
    list-style: disc outside none;
    margin-left: 20px !important; // !important needed to override WYSIWIG weirdness
  }

  hr {
    margin: 30px 0;
    border: 1px solid ${() => themeUtil(Prop.BORDER, CPNT)};
  }

  dl {
    border-top: 1px solid ${() => themeUtil(Prop.BORDER, CPNT)};
    border-bottom: 1px solid ${() => themeUtil(Prop.BORDER, CPNT)};
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  dt {
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    display: block;
    margin: 0;
    font-size: ${({ theme }) => theme.FontSize.REG30};
    text-transform: uppercase;
    padding: 10px 0;

    ${Breakpoints.large(css`
      font-size: ${({ theme }) => theme.FontSize.REG40};
    `)}

    ${Breakpoints.larger(css`
      font-size: ${({ theme }) => theme.FontSize.REG50};
    `)}
  }

  dd {
    width: 100%;
    font-size: ${({ theme }) => theme.FontSize.REG40};

    ${Breakpoints.large(css`
      font-size: ${({ theme }) => theme.FontSize.REG50};
    `)}

    ${Breakpoints.larger(css`
      font-size: ${({ theme }) => theme.FontSize.MED10};
    `)}

    ul {
      li {
        font-size: 14px;

        ${Breakpoints.large(css`
          font-size: ${({ theme }) => theme.FontSize.REG50};
        `)}

        ${Breakpoints.larger(css`
          font-size: ${({ theme }) => theme.FontSize.MED10};
        `)}
      }
    }
  }

  dd, dt {
    padding: 10px;
    display: block;
    text-align: left;
  }
`;

export const RowSection = styled.div`
  display: flex;
  width: 100%;
  gap: 6%;

  ${Breakpoints.mobile(css`
    flex-direction: column-reverse;
  `)}
`;

export const ContentContainer = styled.div`
  width: ${({ $splitContent }) => (($splitContent) ? '50%' : '100%')};

  ${Breakpoints.mobile(css`
    padding-right: 0;
    width: 100%;
  `)}
`;

export const SplitContentContainer = styled.div`
  width: 50%;

  ${Breakpoints.mobile(css`
    width: 100%;
  `)}
`;

export const DynamicTitle = styled.h1`
  margin-bottom: 20px;
  font-size: ${({ theme }) => theme.FontSize.LRG12};
`;

const wysiwigStyles = css`
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 15px !important;
    a { font-size: inherit; }
  }

  h1 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h1')}
  }

  h2 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h2')}
  }

  h3 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h3')}
  }

  h4 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h4')}
  }

  h5 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h5')}
  }

  h6 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h6')}
  }

  p, li {
    color: ${() => themeUtil(Prop.CONTENT, CPNT)};
    margin-bottom: 15px;
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content')}

    ${Breakpoints.large(css`
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content-large-breakpoint')}
    `)}
  }

  img {
    height: 100%;
    max-width: 100%;

    &.align-center {
      display: block;
      margin: 0 auto 15px auto;
    }

    &.align-left {
      float: left;
    }

    &.align-right {
      float: right;
    }
  }

  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: middle;
  }

  table {
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 40px;
    width: 100%;

    tbody {
      width: 100%;
    }

    tr {
      display: table-row;
      border-bottom: 1px solid ${() => themeUtil(Prop.BORDER, CPNT)};
    }

    th {
      ${({ theme }) => theme.fonts.PRI_BDY_HVY};
      font-size: ${({ theme }) => theme.FontSize.REG20};
      text-transform: uppercase;

      ${Breakpoints.mobile(css`
        font-size: ${({ theme }) => theme.FontSize.REG40};
      `)}

      ${Breakpoints.large(css`
        font-size: ${({ theme }) => theme.FontSize.REG30};
      `)}

      ${Breakpoints.larger(css`
        font-size: ${({ theme }) => theme.FontSize.REG40};
      `)}
    }

    td {
      font-size: ${({ theme }) => theme.FontSize.REG40};

      ${Breakpoints.large(css`
        font-size: ${({ theme }) => theme.FontSize.REG50};
      `)}

      ${Breakpoints.larger(css`
        font-size: ${({ theme }) => theme.FontSize.MED10};
      `)}

      .btn {
        @include bp-mobile {
          margin: 0 1px;
        }
      }

      .icon-c {
        margin: 0 auto;
      }
    }

    td,
    th {
      display: table-cell;
      color: ${() => themeUtil(Prop.CONTENT, CPNT)};
      padding: 10px 15px 10px 15px;
      width: auto;
      line-height:1.2em;
      svg {
        fill: ${() => themeUtil(Prop.ICON, CPNT)};
        color: ${() => themeUtil(Prop.ICON, CPNT)};
      }
    }

    th,
    td {
      text-align: center;
    }

    th:first-child,
    td:first-child {
      text-align: left;
    }

    th:last-child,
    td:last-child {
      text-align: right;
    }

    ${Breakpoints.mobileTabletPortrait(css`
      th, td {
        vertical-align: bottom;
      }
    `)}
  }

  .global-table {
    table {
      th:last-child,
      td:last-child {
        text-align: center;
      }
    }
  }

  .bold-cell {
    font-weight: bold;
  }

  a:not(.btn) {
    transition: color .25s ease-out, background-color .25s ease-out;
    color: ${() => themeUtil(Prop.LINK_TXT, CPNT)};

    &:hover, &:focus {
      transition: color .25s ease-out, background-color .25s ease-out;
      color: ${() => themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
    }

    &:focus {
      outline: 2px solid ${() => themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
      outline-offset: 2px;
    }
  }

  .btn {
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'button')}
    line-height: 1;
    border: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    width: auto;
    height: auto;
    padding: 16px 20px;
    text-transform: none;
    margin: 0;
    color: ${() => themeUtil(Prop.BTN_TXT, CPNT)};
    background-color: ${() => themeUtil(Prop.BTN_BG, CPNT)};
    font-weight: bold;
    transition: color .25s ease-out, background-color .25s ease-out;

    &:hover, &:focus {
      transition: color .25s ease-out, background-color .25s ease-out;
      color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
      background-color: ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT)};
    }

    &:focus {
      outline: 2px solid ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT)};;
      outline-offset: 2px;
    }
  }
`;

export const DynamicBody = styled.div`
  ${wysiwigStyles}
`;

export const LocationHoursContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 40px;

  a:not(.btn) {
    transition: color .25s ease-out, background-color .25s ease-out;
    color: ${() => themeUtil(Prop.LINK_TXT, CPNT)};

    &:hover, &:focus {
      transition: color .25s ease-out, background-color .25s ease-out;
      color: ${() => themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
    }

    &:focus {
      outline: 2px solid ${() => themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
      outline-offset: 2px;
    }
  }

  p, li, small {
    font-size: ${({ theme }) => theme.FontSize.REG30};

    &.bold {
      font-weight: bold;
    }
  }
`;

export const DynamicContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  line-height: 1.1;
`;

export const ContactInfoSection = styled.div``;

export const DynamicHours = styled.div`
  p {
    margin-bottom: 0;
  }

  li {
    list-style: none;
    margin: 0 !important;
  }
`;

export const DynamicPrice = styled(PriceRange)`
  font-weight: bold;
`;

export const DynamicMenu = styled.div`
  ${wysiwigStyles}
  margin-top: 20px;
`;

export const NewsStyleDate = styled.p``;

export const ShortDesc = styled.div`
  margin-bottom: 15px;
`;

export const NewsStyleImage = styled.img``;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  svg {
    fill: ${() => themeUtil(Prop.ICON, CPNT)};
    color: ${() => themeUtil(Prop.ICON, CPNT)};
  }
`;

export const StartEndDate = styled.div`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: 18px;
  margin-left: 15px;
`;

export const TextGallery = styled.div`
  display: flex;
  gap: 10%;
`;
