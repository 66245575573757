import styled, { css } from 'styled-components';

import { ThemeProps as Prop, Components, Breakpoints } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.REVERSIBLE_TABLE;

export const StyledReversibleTable = styled.div`
  table {
    width: auto;
    border-collapse: separate;
    border-spacing: 50px 0;
    margin-left: -50px;

    ${Breakpoints.smallInbetween(css`
      border-spacing: 30px 0;
      margin-left: -30px;
    `)}

    ${Breakpoints.smaller(css`
      border-spacing: 15px 0;
      margin-left: -15px;
    `)}

    ${Breakpoints.mobile(css`
      border-spacing: 5px 0;
      margin-left: -5px;
    `)}
  }

  th, td {
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    line-height: 1.2;
    text-transform: uppercase;
    min-width: 100px;

    ${Breakpoints.mobile(css`
      min-width: unset;
    `)}
  }

  thead th {
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    font-size: ${({ theme }) => theme.FontSize.REG20};
    padding-bottom: 3px;
    border-bottom: 1px solid ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    white-space: nowrap;
  }

  tfoot th {
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    padding-top: 3px;
    border-top: 1px solid ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  }

  tbody td {
    font-size: ${({ theme }) => theme.FontSize.MED40};

    ${Breakpoints.smallInbetween(css`
      font-size: ${({ theme }) => theme.FontSize.MED30};
    `)}

    ${Breakpoints.smaller(css`
      font-size: ${({ theme }) => theme.FontSize.REG50};
    `)}

    ${Breakpoints.mobile(css`
      font-size: ${({ theme }) => theme.FontSize.MED20};
    `)}

    ${({ isReversed, theme }) => ((isReversed) ? `
      ${theme.fonts.PRI_HDR_BLK};
      padding-bottom: 3px;
      white-space: normal;
    ` : `
      ${theme.fonts.PRI_HDR_BLK};
      text-transform: uppercase;
      padding-top: 3px;
      white-space: nowrap;
    `)}
  }
`;
