import styled, { css } from 'styled-components';

import { Components, Breakpoints, ThemeProps as Prop } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.CALENDAR_BADGE;

export const StyledCalendarBadge = styled.div`
  ${({ theme }) => theme.fonts.PRI_BDY_MED};
`;

export const DateDayMonthWrapper = styled.div`
  display: inline-block;
  text-align: center;
  margin-bottom: 10px;
  min-width: 75px;
  max-width: 100%;
  width: 100%;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  border: 1px solid ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
`;

export const DayOfWeekText = styled.div`
  display: block;
  padding: 5px;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.FontSize.REG10};
  font-weight: 600;
  color: ${({ colorProfile }) => themeUtil(Prop.ELEMENT_TXT, CPNT, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.ELEMENT, CPNT, colorProfile)};
`;

export const DateMonthWrapper = styled.div`
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  padding: 5px;
  display: block;
  line-height: 1;
`;

export const DayOfMonthText = styled.div`
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  font-size: ${({ theme }) => theme.FontSize.LRG20};
  font-weight: bold;
`;

export const Month = styled.div`
  color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
  font-size: ${({ theme }) => theme.FontSize.REG10};
  text-transform: uppercase;
`;
