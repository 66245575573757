import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled, { css } from 'styled-components';

import { Icon, Link } from '@powdr/components';
import { AlternatingBlockLayoutOptions, BlockTypes, Breakpoints } from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { formatDate } from '@powdr/utils';

// eslint-disable-next-line no-unused-vars
const CPNT = BlockTypes.ARTICLE_PREVIEW;

// styled components
const ContentBlockArticlePreview = styled.div`
  display: flex;
  gap: 6%;
  flex-direction: ${({ alternateBlockLayoutState }) => (
    (alternateBlockLayoutState === AlternatingBlockLayoutOptions.DEFAULT)
      ? 'row'
      : 'row-reverse'
  )};

  .cb-title {
    padding-bottom: 10px;
    margin-bottom: 5px;
  }

  .cb-copy {
    margin-bottom: 15px;
  }

  .cb-formatted-content {
    margin-bottom: 15px;
  }

  small {
    font-size: 14px;
    text-transform: none;
    margin-bottom: 10px;
  }

  ${Breakpoints.mobile(css`
    flex-direction: column;
  `)}
`;

const ImageContainer = styled.div`
  width: 50%;

  ${Breakpoints.mobile(css`
    width: 100%;
  `)}
`;

const ContentContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  text-align: left;

  ${Breakpoints.mobile(css`
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  `)}
`;

const TextDecoration = styled.span`
  font-size: ${({ theme }) => theme.FontSize.REG30};
`;

const StyledLink = styled(Link)`
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;

  .link-icon {
    margin-left: 5px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  ${({ $fullWidth }) => (($fullWidth) && 'width: 65%;')};
`;

const StyledImage = styled(GatsbyImage)`
  display: block;
  width: 100%;
  height: auto;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

export const ArticlePreview = ({
  title, copy, blockType, colorProfile, image, date, endDate, isStaticImageLink, ariaLabel,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonIconName, buttonClick,
  alternateBlockLayoutState, textDecoration, formattedContent,
  altButtonEnabled, altButtonText, altButtonLink, isOverlayEnabled,
}) => (
  <ContentBlockArticlePreview
    className={`content-block-${blockType}`}
    colorProfile={colorProfile}
    alternateBlockLayoutState={alternateBlockLayoutState}
  >
    <ImageContainer>
      {(image && !isStaticImageLink) && <StyledImage image={image.src} alt={image?.altText} />}
      {(image && isStaticImageLink) && <Image src={image.src} alt={image?.altText} />}
    </ImageContainer>
    <ContentContainer alternateBlockLayoutState={alternateBlockLayoutState}>
      {(title) && (
        <h3 className="cb-title">
          {(textDecoration) && <TextDecoration>{textDecoration}</TextDecoration>}
          {`${(textDecoration) ? ' / ' : ''}${title}`}
        </h3>
      )}
      {(date) && <small>{`${formatDate(date).month.num}/${formatDate(date).day.num}/${formatDate(date).day.num}${(endDate) ? ` - ${formatDate(endDate).month.num}/${formatDate(endDate).day.num}/${formatDate(endDate).day.num}` : ''}`}</small>}
      {(copy) && <p className="cb-copy">{copy}</p>}
      {(formattedContent) && <div className="cb-formatted-content">{ReactHtmlParser(formattedContent)}</div>}
      {(buttonEnabled && buttonText) && (
        <ButtonWrapper
          $fullWidth={altButtonEnabled}
          $alternateBlockLayoutState={alternateBlockLayoutState}
        >
          <StyledLink
            aria-label={ariaLabel}
            role="button"
            className="button uppercase with-background"
            onClick={() => buttonClick()}
            href={buttonLink}
            target={buttonTarget}
          >
            {buttonText}
            {(buttonIconName) && <Icon className="link-icon" name={buttonIconName} width="13" height="13" />}
          </StyledLink>
          {(altButtonEnabled && !isOverlayEnabled) && (
            <StyledLink className="button alt-button uppercase with-background" href={altButtonLink}>
              {altButtonText}
            </StyledLink>
          )}
        </ButtonWrapper>
      )}
    </ContentContainer>
  </ContentBlockArticlePreview>
);

ArticlePreview.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
