import styled, { css } from 'styled-components';

import {
  Calendar, Loader, Button, Icon,
} from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Components,
} from '@powdr/constants';
import { themeUtil, componentColor } from '@powdr/utils';

const CPNT = Components.ECOMMERCE_TAB;

export const StyledEcommerceTab = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;

  ${Breakpoints.mobile(css`
    align-items: center;
    flex-direction: column;
  `)};

  ${Breakpoints.small(css`
    align-items: center;
    flex-direction: column;
  `)}
`;

export const ButtonIcon = styled(Icon)`
  margin-right: 8px;
  svg {
    fill: ${() => themeUtil(Prop.CONTENT, CPNT)};
    color: ${() => themeUtil(Prop.CONTENT, CPNT)};
  }
`;

export const StyledCalendar = styled(Calendar)`
  position: relative;
  top: 0px;
  max-width: 700px;
`;

export const StyledLoader = styled(Loader)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -100px;

  ${Breakpoints.mobile(css`
    height: 100px;
    margin-top: auto;
  `)}
`;

export const SelectDates = styled.div`
  padding-left: 10px;
  width: 50%;

  ${Breakpoints.small(css`
    margin-bottom: 30px;
    padding-left: 0;
    width: 100%;
  `)}

  ${Breakpoints.mobile(css`
    padding: 0 10px;
    margin-bottom: 30px;
    width: 100%;
  `)}

`;

export const FindProducts = styled.div`
  width: 50%;
  margin-left: 20px;

  ${Breakpoints.small(css`
    margin: 0;
    width: 100%;
  `)}

  ${Breakpoints.mobile(css`
    padding: 0 10px;
  `)}
`;

export const ProductOptions = styled.div`
  display: flex;
  flex-wrap: wrap;

  label {
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    margin: 0px 10px 10px 0;
  }
`;

export const CalendarButton = styled(Button)`
  background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT)};
  border: 2px solid ${() => themeUtil(Prop.CONTENT, CPNT)};
  color: ${() => themeUtil(Prop.CONTENT, CPNT)};
  border-width: 2px;
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG50};
  font-weight: bold;
  line-height: initial;
  white-space: nowrap;
  width: 100%;

  &:hover {
    background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT)};
    color: ${() => themeUtil(Prop.CONTENT, CPNT)};
  }

  ${Breakpoints.small(css`
    justify-content: center;
  `)}
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT)};
`;

export const ScrollSpacer = styled.div`
  height: 50px;
`;

export const NextButton = styled(Button)`
  background-color: ${() => componentColor(Prop.BTN_BG_ALT, CPNT, Prop.BTN_BG)};
  color: ${() => componentColor(Prop.BTN_TXT_ALT, CPNT, Prop.BTN_TXT)};
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG50} !important;
  font-weight: bold;
  justify-content: center;
  text-transform: uppercase !important;
  align-items: center;

  &.continue {
    margin: 30px auto;
    padding-left: 125px;
    padding-right: 125px;
  }
  &.search {
    width: 100%;
  }

  &.add-to-cart {
    width: 100%;

    ${Breakpoints.mobile(css`
      width: 100%;
    `)}
  }

  .data-await-loader {
    display: flex;
    justify-content: center;
  }
`;

export const Disclaimer = styled.div`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  line-height: 15px;
  margin: 20px auto 0;
  max-width: 700px;
`;

export const ModalHeader = styled.h1`
  font-size: ${({ theme }) => theme.FontSize.LRG12};
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  margin-bottom: 35px;

  &.ticket-select-header {
    max-width: 1050px;
  }

  ${Breakpoints.mobile(css`
    margin-bottom: 25px;
  `)}
`;

export const ProductRowsWrapper = styled.div`
  margin: 0 auto;
  max-width: 1050px;
  > h2 {
    font-size: ${({ theme }) => theme.FontSize.MED50};
    margin-top: 20px;
  }
`;
export const ProductRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow-y: scroll;
  padding-bottom: 25px;

  ${Breakpoints.mobile(css`
    justify-content: unset;
    margin-bottom: 15px;
    padding-bottom: 15px;
  `)}
`;

export const Products = styled.ul`
  display: flex;

  ${Breakpoints.mobile(css`
    margin-left: 0px;
  `)}
`;

export const AlertError = styled.div`
  align-items: flex-start;
  border-left: 5px solid red;
  display: flex;
  margin-right: 50px;
  width: 350px;


  h2 {
    font-size: ${({ theme }) => theme.FontSize.REG50};
    margin-top: 15px;
  }
  .errors {
    font-size: ${({ theme }) => theme.FontSize.REG30};
    margin-top: 15px;
  }
  ul {
    list-style: disc;
    margin: 10px;
    font-size: 13px;
  }
  button {
    background-color: ${() => componentColor(Prop.BTN_BG_ALT, CPNT, Prop.BTN_BG)};
    margin: 15px 0;
    text-transform: uppercase;
    width: 100%;
  }
`;

export const StyledErrorIcon = styled(Icon)`
  margin: 10px 15px 0 10px;
  svg {
    fill: red;
    color: red;
  }
`;
