import styled, { css } from 'styled-components';

import { ThemeProps as Prop, Components, Breakpoints } from '@powdr/constants';
import { componentColor } from '@powdr/utils';

const CPNT = Components.ALERT_BAR;

export const StyledAlertBar = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT, Prop.BACKGROUND)};
  position: relative;
  overflow: hidden;
  pointer-events: all;
  ${({ theme }) => theme.fonts.PRI_BDY_MED};

  .alert-content-wrapper {
    height: fit-content;
  }

  .alert-content {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: fit-content;
    padding: 10px 30px 10px 30px;

    .alert-html {
      margin-bottom: 0;
      width: 100%;
      line-height: 1.2;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${() => componentColor(Prop.HEADER, CPNT, Prop.HEADER)};
        margin-bottom: 0;
        font-size: 16px;

        ${Breakpoints.mobile(css`
          font-size: 13px;
        `)}

        ${Breakpoints.small(css`
          font-size: 14px;
        `)}
      }

      p {
        color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
        margin-bottom: 0;
        font-size: 16px;

        ${Breakpoints.small(css`
          font-size: 14px;
        `)}

        ${Breakpoints.mobile(css`
          font-size: 13px;
        `)}
      }

      a {
        color: ${() => componentColor(Prop.LINK_TXT, CPNT, Prop.LINK_TXT)};
        transition: color 0.2s linear;

        &:hover {
          color: ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT, Prop.LINK_TXT_HOVER)};
        }

        &:focus {
          outline: 2px solid ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT, Prop.LINK_TXT_HOVER)};
          outline-offset: 0.2em;
        }
      }

      .btn {
        background-color: ${() => componentColor(Prop.BTN_BG, CPNT, Prop.BTN_BG)};
        color: ${() => componentColor(Prop.BTN_TXT, CPNT, Prop.BTN_TXT)};
        padding: 8px 15px;

        &:hover {
          background-color: ${() => componentColor(Prop.BTN_BG_HOVER, CPNT, Prop.BTN_BG_HOVER)};
          color: ${() => componentColor(Prop.BTN_TXT_HOVER, CPNT, Prop.BTN_TXT_HOVER)};
        }

        ${Breakpoints.mobile(css`
          padding: 4px 15px;
        `)}
      }
    }

    .close {
      display: block;
      margin-left: 10px;

      svg {
        transition: fill 0.2s linear;
        fill: ${() => componentColor(Prop.ICON, CPNT, Prop.ICON)};
        color: ${() => componentColor(Prop.ICON, CPNT, Prop.ICON)};
      }

      &:hover {
        svg {
          fill: ${() => componentColor(Prop.ICON_HOVER, CPNT, Prop.ICON_HOVER)};
          color: ${() => componentColor(Prop.ICON_HOVER, CPNT, Prop.ICON_HOVER)};
        }
      }

      &:focus {
        outline: 2px solid ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT, Prop.LINK_TXT_HOVER)};
        outline-offset: 0.2em;
      }
    }
  }

`;
