import PropTypes from 'prop-types';
import React from 'react';

import {
  PromoBlock, PromoButton, PromoHeader, PromoPrice,
  PromoDescription, PromoPriceDesc, StyledPromotionList,
} from './styles';

export const PromotionList = ({
  relationships,
}) => {
  const promotionArr = relationships?.promotion || [];

  return (
    <StyledPromotionList>
      {promotionArr.map((promo) => (
        <PromoBlock key={`promo-block-${promo.title}`}>
          <PromoHeader>{promo.title}</PromoHeader>
          <PromoPrice>
            {promo.price}
            {(promo.priceDescription) && (
              <PromoPriceDesc>
                {promo.priceDescription}
              </PromoPriceDesc>
            )}
          </PromoPrice>
          <PromoDescription>
            {promo.description}
          </PromoDescription>
          <PromoButton href={promo.url} target={promo.target}>
            {promo.buttonText}
          </PromoButton>
        </PromoBlock>
      ))}
    </StyledPromotionList>
  );
};

PromotionList.propTypes = {
  relationships: PropTypes.shape({
    promotion: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

PromotionList.defaultProps = {};
