import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components, Breakpoints,
} from '@powdr/constants';
import { componentColor } from '@powdr/utils';

const CPNT = Components.STICKY_NAV;

export const StyledStickyNav = styled.div`
  position: sticky;
  text-align: center;
  top: ${({ headerOffset }) => `${headerOffset - 1}px`};
  z-index: 70;

  nav.second-level {
    background-color: ${() => componentColor(Prop.BACKGROUND, CPNT, Prop.BACKGROUND)};
    width: 100%;

    li {
      display: inline-block;
      min-height: 51px;
      vertical-align: top;
    }

    a.btn.m-link {
      display: block;
      background-color: transparent;
      color: ${() => componentColor(Prop.BTN_TXT, CPNT, Prop.LINK_TXT)};
      font-size: ${({ theme }) => theme.FontSize.REG40};
      height: 100%;
      min-height: 51px;
      line-height: 1.1;
      transition: background-color .25s ease-out, color .25s ease-out;

      &:hover, &:focus {
        transition: background-color .25s ease-out, color .25s ease-out;
        background-color: ${() => componentColor(Prop.BTN_BG_HOVER, CPNT, Prop.BACKGROUND)};
        color: ${() => componentColor(Prop.BTN_TXT_HOVER, CPNT, Prop.LINK_TXT_HOVER)};
      }

      &:focus {
        outline: 2px solid ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT, Prop.BACKGROUND)};
        outline-offset: -10px;
      }

      &.m-active {
        background-color: ${() => componentColor(Prop.BTN_BG_ACTIVE, CPNT, Prop.BTN_BG_ACTIVE)};
        color: ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT, Prop.BTN_TXT_ACTIVE)};

        &:hover {
          background-color: ${() => componentColor(Prop.BTN_BG_ACTIVE, CPNT, Prop.BTN_BG_ACTIVE)};
        }
      }

      ${Breakpoints.large(css`
        font-size: ${({ theme }) => theme.FontSize.REG50};
      `)}

      ${Breakpoints.larger(css`
        font-size: ${({ theme }) => theme.FontSize.MED10};
      `)}

      ${Breakpoints.mobileTablet(css`
        font-size: ${({ theme }) => theme.FontSize.REG50};
        min-height: 0;
        padding: 10px 20px;
      `)}
    }
  }

  .sticky-nav-mobile {
    ul {
      margin-bottom: 0;
    }
    li {
      margin-bottom: 0;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
      }
      .m-link {
        display: block;
      }

      button, .m-link {
        ${({ theme }) => theme.fonts.PRI_BDY_MED};
        font-size: ${({ theme }) => theme.FontSize.MED20};
        padding: 20px;
        width: 100%;
      }


      ${Breakpoints.portraitSmall(css`
        span, .m-link {
          font-size: 4vw;
        }
        .icon-c {
          width: 15px !important;
          height: 15px !important;
          margin-left: 10px;
        }
      `)}
    }
    .sticky-mobile-active {
      li {
        border-bottom: 1px solid ${() => componentColor(Prop.BORDER, CPNT)};
      }
      button {
        background-color: ${() => componentColor(Prop.BACKGROUND, CPNT)};
        text-transform: uppercase;
        span {
          ${({ theme }) => theme.fonts.PRI_BDY_HVY};
          color: ${() => componentColor(Prop.BTN_TXT, CPNT, Prop.LINK_TXT)};
          font-weight: bold;
          padding-right: 20px;
        }
        svg {
          fill: ${() => componentColor(Prop.BTN_TXT, CPNT, Prop.LINK_TXT)};
          color: ${() => componentColor(Prop.BTN_TXT, CPNT, Prop.LINK_TXT)};
        }
      }
    }

    .sticky-mobile-dropdown {
      background-color: ${() => componentColor(Prop.BTN_BG_ACTIVE, CPNT, Prop.BTN_BG_ACTIVE)};
      width: 100%;
      padding-bottom: 20px;
      position: absolute;
      z-index: 10;
      overflow-y: auto;

      li {
        border-bottom: 1px solid ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT)};
        margin: 0 20px;

        &:first-child {
          border-top: 1px solid ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT)};
        }
      }

      .m-link {
        color: ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT)};
      }
    }

    &.mobile-sticky-nav-open {
      .sticky-mobile-active {
        li {
          border-bottom: 0;
        }
        button {
          background-color: ${() => componentColor(Prop.BTN_BG_ACTIVE, CPNT, Prop.BTN_BG_ACTIVE)};

          span {
            color: ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT, Prop.BTN_TXT_ACTIVE)};
          }

          svg {
            transform: rotate(180deg);
            fill:  ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT, Prop.BTN_TXT_ACTIVE)};
            color:  ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT, Prop.BTN_TXT_ACTIVE)};
          }
          }
        }
      }

    }
`;
