import styled, { css } from 'styled-components';

import { ThemeProps as Prop, Components, Breakpoints } from '@powdr/constants';
import { getDorComponentColor } from '@powdr/utils';

const CPNT = Components.DOR;

export const StyledDorTrailReport = styled.div`
  margin-bottom: 30px;

  &.no-feed {
    display: block;

    .dtr-header {
      margin-bottom: 30px;
    }
  }

  &.feed {
    gap: 15px;
    display: flex;
  }

  ${Breakpoints.mobile(css`
    flex-direction: column;
  `)}

  .dtr-col {
    width: auto;
    margin-bottom: 15px;

    ${Breakpoints.mobile(css`
        width: 100%;
        margin: 0 auto 15px;
    `)}
  }

  &.feed {
    .dtr-grid {
      .dtr-col {
        width: calc(50% - 15px);
        margin: 0 auto;

        ${Breakpoints.mobile(css`
            width: calc(50% - 15px);
            margin: 0 auto 15px auto;
        `)}
      }
    }
  }

  .dtr-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 15px;

    .dtr-col {
      max-width: 180px;
      margin: 0 auto;

      ${Breakpoints.mobile(css`
          max-width: calc(50% - 15px);
          margin: 0 auto 15px auto;
      `)}
    }

    .icon {
      justify-content: center;
      display: flex;
      margin-bottom: 16px;

      svg {
        fill: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.ICON, CPNT)};
        color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.ICON, CPNT)};
      }
    }

    hr {
      width: 100%;
    }

    h3 {
      font-size: ${({ theme }) => theme.FontSize.MED10};
      text-align: center;
      text-transform: capitalize;
    }

  }

  .dtr-header {
    h3 {
      font-size: ${({ theme }) => theme.FontSize.MED30};
    }
  }

  .dtr-details {
    p {
      line-height: 20px;
    }

    h3,
    small,
    p {
      margin-bottom: 16px;
      color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.CONTENT, CPNT)};
    }

    a {
      color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.LINK_TXT, CPNT)};

      &:hover {
        color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.LINK_TXT_HOVER, CPNT)};
      }
    }
  }
`;
