import styled, { css } from 'styled-components';

import { Link, SeasonToggle } from '@powdr/components';
import {
  ThemeProps as Prop, Components, Breakpoints, Alignment,
} from '@powdr/constants';
import { componentColor, themeUtil } from '@powdr/utils';

const CPNT = Components.ECOMMERCE_BANNER;
const containerSidePadding = '10px';

export const StyledEcommerceBanner = styled.div`
  width: 100%;
`;

export const StyledEcommerceBannerDesktop = styled.div`
  display: none;
  ${Breakpoints.desktopOnly(css`
    display: block;
  `)}
`;

export const StyledEcommerceBannerMobile = styled.div`
  display: none;
  ${Breakpoints.mobile(css`
    ${({ $showMobileBanner }) => (($showMobileBanner) ? 'display: block;' : '')}
  `)}
`;

export const Banner = styled.div`
  display: flex;
  pointer-events: all;
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT)};
  width: 100%;
  height: ${({ extendHeight }) => ((extendHeight) ? '60px' : '30px')};
  position: relative;
  align-items: center;
  justify-content: ${({ isUnityEnabled, showLinkGroups, isSeasonToggleEnabled }) => {
    if (isUnityEnabled && !showLinkGroups && !isSeasonToggleEnabled) return 'flex-end';
    if (isSeasonToggleEnabled && !showLinkGroups && !isUnityEnabled) return 'flex-start';
    return 'space-between';
  }};
  ${Breakpoints.mobile(css`
    flex-direction: column;
  `)}
`;

const SharedLinkTextStyles = css`
  text-transform: uppercase;
  color: ${() => componentColor(Prop.LINK_TXT, CPNT)};
  transition: color .25s ease-out;
  line-height: 1;
  width: max-content;

  &:hover {
    color: ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT)};
  }

  &:focus {
    outline: 2px solid ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT)};
    outline-offset: 0.2em;
  }

  ${({ theme, isFromFeedContainer }) => ((isFromFeedContainer) ? `
      ${theme.fonts.PRI_BDY_MED};
      font-size: ${theme.FontSize.REG20};
      height: ${theme.FontSize.REG20};
    ` : `
      ${theme.fonts.PRI_BDY_MED};
      font-size: ${theme.FontSize.REG30};
      height: ${theme.FontSize.REG30};
    `)}

  ${Breakpoints.mobile(css`
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    font-size: ${({ theme }) => theme.FontSize.REG20};
    height: ${({ theme }) => theme.FontSize.REG20};
  `)}
`;

export const LinkGroupSeparator = styled.div`
  ${Breakpoints.mobile(css`
    display: none;
  `)}
  position: relative;
  height: calc(100% - 10px);
  width: 1px;
  ${({ position }) => position}: 12.5px;
  border-left: 1px solid ${() => componentColor(Prop.LINK_SEPARATOR, CPNT, Prop.BORDER)};
`;

export const MobileBorderContainer = styled.div`
  position: absolute;
  height: 1px;
  width: calc(100% - 15px);
  top: 28px;
  border-bottom: 1px solid ${() => componentColor(Prop.LINK_SEPARATOR, CPNT, Prop.BORDER)};
`;

export const SeasonToggleContainer = styled.div`
  ${Breakpoints.desktopOnly(css`
    height: 100%;
    padding-left: ${containerSidePadding};
  `)}
`;

export const SeasonToggleSeparator = styled.div`

`;

export const StyledSeasonToggle = styled(SeasonToggle)`
  position: relative !important;
  left: 0 !important;
`;

export const LinkGroupsContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ numColumns }) => `repeat(${numColumns}, 1fr);`};
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100%;
  width: 100%;
  align-items: center;

  ${({ hasLeftSibling, hasRightSibling }) => {
    if (hasLeftSibling && hasRightSibling) return 'margin: 0 25px;';
    if (hasLeftSibling) return 'margin-left: 25px;';
    if (hasRightSibling) return `margin-right: 25px; padding-left: ${containerSidePadding};`;
    return `padding: 0 ${containerSidePadding}`;
  }};

  ${Breakpoints.mobile(css`
    width: 100%;
    padding: 0 15px;
    margin: 0;
  `)}
`;

export const LinkGroup = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;

  ${({ position }) => {
    if (position === Alignment.LEFT) {
      return 'grid-area: 1 / 1 / 2 / 2;';
    }

    if (position === Alignment.RIGHT) {
      return `
        grid-area: 1 / 3 / 2 / 4;
        justify-content: right;
      `;
    }

    return `
      grid-area: 1 / 2 / 2 / 3;
      justify-content: center;
    `;
  }}
`;

export const LinkSeparator = styled.div`
  ${SharedLinkTextStyles}
  color: ${() => componentColor(Prop.LINK_SEPARATOR, CPNT, Prop.BORDER)};

  &:hover {
    color: ${() => componentColor(Prop.LINK_SEPARATOR, CPNT, Prop.BORDER)};
  }
`;

export const BannerLink = styled(Link)`
  ${SharedLinkTextStyles}

  ${({ href }) => ((!href) ? `
    cursor: default;
    color: ${themeUtil(Prop.CONTENT, CPNT)};

    &:hover {
      color: ${themeUtil(Prop.CONTENT, CPNT)};
    }
  ` : '')}

  ${({ hasMultiple }) => ((hasMultiple) ? `
    padding: 0 5px;

    &:last-child {
      padding-left: 5px;
      padding-right: 0px;
    }

    &:first-child {
      padding-right: 5px;
      padding-left: 0px;
    }
  ` : '')}
`;

export const EcommerceCartLoginContainer = styled.div`
  display: flex;
  width: fit-content;
  height: 100%;
  padding-right: ${containerSidePadding};

  ${({ isFromFeedContainer }) => ((isFromFeedContainer) ? `
      width: 100%;
      height: 30px;
      padding: 0 5px;
      justify-content: space-between;
    ` : '')}

  ${Breakpoints.mobile(css`
    width: 100%;
    padding: 0 15px;
    justify-content: space-between;
  `)}
`;

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
`;

export const RegisterLink = styled(Link)`
  ${SharedLinkTextStyles}
`;

export const LoginLinkSeparator = styled.div`
  ${SharedLinkTextStyles}
  color: ${() => componentColor(Prop.ELEMENT, CPNT)};
  padding: 0 5px;

  &:hover {
    color: ${() => componentColor(Prop.ELEMENT, CPNT)};
  }
`;

export const LoginLink = styled(Link)`
  ${SharedLinkTextStyles}
`;

export const LoggedInLink = styled(Link)`
  ${SharedLinkTextStyles}
`;

export const CartLinkContainer = styled.div`
  display: flex;
  height: 100%;
  width: auto;
  align-items: center;
  justify-content: right;

  ${({ isFromFeedContainer }) => ((isFromFeedContainer) ? '' : 'padding-left: 50px;')};

`;

export const CartLink = styled(Link)`
  ${SharedLinkTextStyles}
`;
