import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Components, ComponentGroups, Alignment,
} from '@powdr/constants';
import { themeUtil, componentFontSize } from '@powdr/utils';

const CPNT = Components.UI_HTML;

export const StyledUiHtml = styled.div`
  text-align: ${({ $alignment }) => $alignment || Alignment.LEFT};
  // Might still need this but not sure, fix this differently if we see issues
  //background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  iframe {
    overflow: hidden;
  }

  p, li, ul {
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
    a { font-size: inherit; }
  }

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }

  a:not(.btn) {
    transition: color .25s ease-out, background-color .25s ease-out;
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};

    &:hover, &:focus {
      transition: color .25s ease-out, background-color .25s ease-out;
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }

    &:focus {
      outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
      outline-offset: 2px;
    }
  }

  .btn {
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'button')}
    line-height: 1;
    border: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    width: auto;
    height: auto;
    padding: 16px 20px;
    text-transform: none;
    margin: 0;
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    font-weight: bold;
    transition: color .25s ease-out, background-color .25s ease-out;

    &:hover, &:focus {
      transition: color .25s ease-out, background-color .25s ease-out;
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }

    &:focus {
      outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};;
      outline-offset: 2px;
    }
  }

  h1 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h1')}
    margin-bottom: 40px;
  }

  h2 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h2')}
    margin-bottom: 20px;
  }

  h3 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h3')}
    margin-bottom: 15px;
  }

  h4 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h4')}
    margin-bottom: 10px;
  }

  h5 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h5')}
    margin-bottom: 10px;
  }

  h6 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h6')}
    margin-bottom: 5px;
  }

  summary {
    ${({ theme }) => theme.fonts.PRI_HDR_BLK};
  }

  ul, ol {
    margin-bottom: 25px;
  }

  ul {
    li {
      list-style: disc outside none;
      margin-left: 15px;
      margin-bottom: 5px;
      line-height: 1.25;
    }
  }

  ol {
    li {
      list-style: decimal;
      margin-left: 15px;
      margin-bottom: 5px;
      line-height: 1.25;
    }
  }

  strong {
    font-weight: bold;
  }

  hr {
    margin: 30px 0;
    border: 1px solid ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
  }

  em {
    font-style: italic;
  }

  li, p, table {
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content')}

    ${Breakpoints.large(css`
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content-large-breakpoint')}
    `)}
  }

  p {
    line-height: 1.25;
    margin-bottom: 15px
  }

  table {
    width: 100%;
    line-height: 25px;
    margin-bottom: 15px;

    td {
      font-size: 16px;
    }
  }

  figure.caption-img figcaption {
    font-size: 14px;
    margin: -10px 0 25px 0;
  }

  .center-xy {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    embed, iframe, object, img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  img {
    &.align-left {
      float: left;
    }

    &.align-right {
      float: right;
    }
  }
`;

export const StyledIcon = styled(Icon)`
  margin-bottom: 30px;
`;
