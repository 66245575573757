import { useState, useEffect } from 'react';

import { isRuntime } from '@powdr/utils';

export const useWindowResize = () => {
  const [windowResize, setWindowResize] = useState({
    windowWidth: undefined,
    windowHeight: undefined,
  });

  if (isRuntime()) return windowResize;

  useEffect(() => {
    const handleResize = () => {
      setWindowResize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    };
    handleResize();
    const debounceResize = setTimeout(() => {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, 250);
    return () => clearTimeout(debounceResize);
  }, []);

  return windowResize;
};
