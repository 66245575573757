import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import {
  AlternatingBlockLayoutOptions, BlockTypes, Breakpoints, ThemeProps as Prop,
} from '@powdr/constants';
import { useWindowResize } from '@powdr/hooks';
import { ContentBlockType } from '@powdr/model';
import { themeUtil, getBackgroundImageSrc, clamp } from '@powdr/utils';

const CPNT = BlockTypes.SPLIT_ALT;

// styled components
const ContentBlockSplitAlt = styled(Link)`
  display: flex;
  flex-direction: ${({ alternateBlockLayoutState }) => (
    (alternateBlockLayoutState === AlternatingBlockLayoutOptions.DEFAULT)
      ? 'row'
      : 'row-reverse'
  )};
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 330px;
  overflow: hidden;

  .cb-title {
    ${() => clamp(2)}
    margin-bottom: 15px;
  }

  .cb-copy {
    ${() => clamp(3)}
  }

  &:hover, &:focus {
    .cb-content {
      width: 70%;
      text-align: center;

      ${Breakpoints.mobile(css`
        width: 100%;
        height: 70%;
      `)}
    }

    .cb-image {
      width: 30%;

      ${Breakpoints.mobile(css`
        width: 100%;
        height: 30%;
      `)}
    }

    .button {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }
  }

  &:focus .button {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    outline-offset: 2px;
  }

  ${Breakpoints.mobile(css`
    flex-direction: column;
    height: 600px;
    overflow: hidden;
  `)}

  .cb-col {
    width: 50%;

    ${Breakpoints.mobile(css`
      width: 100%;
    `)}
  }

  .cb-image {
    transition: .3s ease-in-out;
    display: block;
    width: 50%;

    ${Breakpoints.mobile(css`
      width: 100%;
      height: 50%;
    `)}
  }

  .cb-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    transition: .3s ease-in-out;
    background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

    .button {
      display: inline-block;
    }

    ${Breakpoints.mobile(css`
      width: 100%;
      height: 50%;
    `)}

    svg.wedge {
      fill: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
      position: absolute;
      top: 0;
      display: block;
      height: 100%;
      width: 60px;
      ${({ alternateBlockLayoutState }) => (
    (alternateBlockLayoutState === AlternatingBlockLayoutOptions.DEFAULT)
      ? 'left: -60px;'
      : 'right: -60px; transform: scale(-1, 1);'
  )};

      ${Breakpoints.mobile(css`
        transform: scaleX(-1);
        width: 100%;
        top: -60px;
        left: 0;
        height: 60px;
      `)}
    }

    .row {
      text-align: left;
      width: 100%;
      padding: 0 40px;
      background: transparent;
      position: relative;
      z-index: 1;

      ${Breakpoints.mobile(css`
        padding: 25px;
      `)}

      &.details, &.icon {
        padding-bottom: 20px;
      }

      &.details {
        height: 155px;
        overflow: visible;

        p {
          color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
        }
      }
    }
  }
`;

const BGImage = styled.div`
  display: block;
  width: 50%;
  background-image: ${({ image }) => `url('${image}')`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

// TODO: bg image utility for the BGImage once built
export const SplitAlt = ({
  title, copy, blockType, ariaLabel,
  buttonEnabled, buttonLink, buttonText, buttonTarget, buttonClick,
  colorProfile, image, columnOption, iconName, isStaticImageLink,
  alternateBlockLayoutState,
}) => {
  const [bgImage, setBgImage] = useState('');
  const resize = useWindowResize();

  useEffect(() => {
    if (resize.windowWidth) {
      setBgImage(
        (!isStaticImageLink)
          ? getBackgroundImageSrc(image?.src, resize.windowWidth, columnOption)
          : image.src,
      );
    }
  }, [resize.windowWidth]);

  return (
    <ContentBlockSplitAlt
      href={buttonLink}
      target={buttonTarget}
      colorProfile={colorProfile}
      onClick={() => buttonClick()}
      aria-label={ariaLabel}
      role="button"
      className={`content-block-${blockType} `}
      alternateBlockLayoutState={alternateBlockLayoutState}
    >
      <BGImage className="cb-image cb-col" image={bgImage} aria-label={image?.altText} />
      <div className="cb-content cb-col">
        <svg
          className="wedge"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon points="0,100 100,0 100,100" />
        </svg>
        {(iconName) && (
          <div className="row icon">
            <Icon className="cb-icon" name={iconName} width="50" height="50" />
          </div>
        )}
        <div className="row details">
          {(title) && <h3 className="cb-title">{title}</h3>}
          {(copy) && <p className="cb-copy">{copy}</p>}
        </div>
        <div className="row cta">
          {(buttonEnabled && buttonText) && (
            <div className="button with-background uppercase">
              {buttonText}
            </div>
          )}
        </div>
      </div>
    </ContentBlockSplitAlt>
  );
};

SplitAlt.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
