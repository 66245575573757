import axios from 'axios';

import { FetchStatus } from '@powdr/constants';
import { getNodeApiDomain } from '@powdr/utils';

import { WEATHER_PENDING, WEATHER_SUCCESS, WEATHER_ERROR } from '../types';

export const getWeather = () => async (dispatch, getState) => {
  const weatherStatus = getState().weather.status;
  dispatch({
    type: WEATHER_PENDING,
    payload: [],
  });
  // If more than one component calls the same feed make sure we only make one request
  if (weatherStatus === FetchStatus.IDLE) {
    try {
      const res = await axios.get(`${getNodeApiDomain()}/dor/weather`);
      dispatch({
        type: WEATHER_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: WEATHER_ERROR,
        payload: error,
      });
    }
  }
};
