import styled, { css } from 'styled-components';

import {
  Components, ThemeProps as Prop, Alignment, Breakpoints, ComponentGroups,
} from '@powdr/constants';
import { themeUtil, componentFontSize } from '@powdr/utils';

const CPNT = Components.UI_FOOTER;

export const StyledUiFooter = styled.div`
  text-align: ${({ alignment }) => Alignment[alignment?.toUpperCase()] || Alignment.CENTER};
  padding: ${({ $paddingTop, $paddingBottom }) => `${$paddingTop}px 0px ${$paddingBottom}px`};

  iframe {
    overflow: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    a { font-size: inherit; }
  }

  h1 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h1')}
    margin-bottom: 40px;
  }

  h2 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h2')}
    margin-bottom: 20px;
  }

  h3 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h3')}
    margin-bottom: 15px;
  }

  h4 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h4')}
    margin-bottom: 10px;
  }

  h5 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h5')}
    margin-bottom: 10px;
  }

  h6 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h6')}
    margin-bottom: 5px;
  }

  table {
    margin-bottom: 15px;
  }

  p {
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content')}
    margin-bottom: 15px;
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    line-height: 1.25;

    ${Breakpoints.large(css`
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content-large-breakpoint')}
    `)}
  }

  a:not(.btn) {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content')}
    transition: color .25s ease-out, background-color .25s ease-out;
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};

    &:hover, &:focus {
      transition: color .25s ease-out, background-color .25s ease-out;
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }

    &:focus {
      outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};;
      outline-offset: 2px;
    }

    ${Breakpoints.large(css`
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content-large-breakpoint')}
    `)}
  }

  .btn {
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'button')}
    border: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    width: auto;
    height: auto;
    line-height: 1;
    padding: 16px 20px;
    text-transform: none;
    margin: 0;
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    font-weight: bold;
    transition: color .25s ease-out, background-color .25s ease-out;

    &:hover, &:focus {
      transition: color .25s ease-out, background-color .25s ease-out;
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }

    &:focus {
      outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};;
      outline-offset: 2px;
    }
  }

  hr {
    margin: 30px 0;
    border: 1px solid ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
  }
`;
