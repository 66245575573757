import PropTypes from 'prop-types';
import React from 'react';

import { DorWeather } from '@powdr/components';
import { setWeatherSliderObj } from '@powdr/utils';

import { StyledStaticForecast } from './styles';

export const StaticForecast = ({
  className,
  parentColorProfile,
  relationships,
}) => {
  const { forecastItems } = relationships;
  const adjustedForecastData = forecastItems
    .filter((i) => new Date(i.forecastDate.replace(/-/g, '/')) >= new Date(new Date().setHours(0, 0, 0, 0)))
    .map((forecastItem) => setWeatherSliderObj({
      icon: forecastItem.weatherIcon,
      tempMin: forecastItem.tempLow,
      tempMax: forecastItem.tempHigh,
      shortLabel: forecastItem.weatherDescription,
      snowMin: forecastItem.minSnowfall,
      snowMax: forecastItem.maxSnowfall,
      date: new Date(new Date(forecastItem.forecastDate.replace(/-/g, '/')).setHours(0, 0, 0, 0)),
    }))
    .sort((a, b) => a.date - b.date)
    .slice(0, 5);

  return (
    <StyledStaticForecast className={className}>
      <DorWeather
        isStaticForecast
        staticItems={adjustedForecastData}
        parentColorProfile={parentColorProfile}
      />
    </StyledStaticForecast>
  );
};

StaticForecast.propTypes = {
  className: PropTypes.string,
  relationships: PropTypes.shape().isRequired,
  parentColorProfile: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

StaticForecast.defaultProps = {
  className: '',
  parentColorProfile: null,
};
