/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Alignment, LinkTargets } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useSeason, useIsInView } from '@powdr/hooks';

import {
  BlurIcon, MobileScrollBlur, StyledUiTable, Table, TableBody, TableWrapper,
  Row, HeaderCell, DataCell, CellLink, CellIcon, MobileScrollInViewHelper,
} from './styles';

const BUY_NOW = 'Buy Now!';

const SearchString = {
  HTTP: 'http://',
  HTTPS: 'https://',
  SRC_HTTP: 'src="http',
};

export const UiTable = ({
  id,
  isFirstColumnBold,
  isFirstColumnCenter,
  isLastColumnCenter,
  isHorizontalScroll,
  tables,
  parentColorProfile,
  componentSeason,
}) => {
  const { isMobile } = useContext(AppContext);
  const { checkSeasonality } = useSeason();
  const colorProfile = parentColorProfile;
  const tableRef = useRef();
  const firstColumnRef = useRef();
  const lastColumnRef = useRef();
  const isFirstColumnVisible = useIsInView(firstColumnRef);
  const isLastColumnVisible = useIsInView(lastColumnRef);

  // TODO: should be able to clean up some of this and reduce the amount of code
  // Function to check cell data for any special values from CMS that allow for admins
  // to have buttons, icons, other manipulations in the table cell
  const checkCellDataForSpecialValues = (cellValue) => {
    // check for url to change into button
    // && except for img src with fully qualified uri
    if (
      (cellValue.includes(SearchString.HTTP) || cellValue.includes(SearchString.HTTPS))
      && !cellValue.includes(SearchString.SRC_HTTP)
    ) {
      const arr = cellValue.split(',');
      let target = LinkTargets.SELF;
      let href = null;
      let buttonText = BUY_NOW;

      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i].includes(SearchString.HTTP) || arr[i].includes(SearchString.HTTPS)) {
          href = arr[i].replace(' ', '');
        } else if (
          arr[i].replace(' ', '') === LinkTargets.SELF
          || arr[i].replace(' ', '') === LinkTargets.PARENT
          || arr[i].replace(' ', '') === LinkTargets.BLANK
        ) {
          target = arr[i].replace(' ', '');
        } else {
          buttonText = arr[i];
        }
      }

      return (
        <CellLink
          className="cell-button"
          colorProfile={colorProfile}
          href={href}
          target={target}
        >
          {buttonText}
        </CellLink>
      );
    }

    // check for icon name to display an icon
    if (cellValue.indexOf('icon=') !== -1) {
      const arr = cellValue.split(',');
      const iconName = arr[0].replace('icon=', '');
      const iconColor = arr?.[1];

      return (
        <CellIcon
          name={iconName}
          height="20"
          width="20"
          color={iconColor || undefined}
          colorProfile={colorProfile}
        />
      );
    }

    if (cellValue.indexOf('bold-text') !== -1) {
      return cellValue.split(',')[0];
    }

    return ReactHtmlParser(cellValue);
  };

  return (
    checkSeasonality(componentSeason) && (
      <StyledUiTable key={id} className="ui-table" colorProfile={colorProfile}>
        {(isMobile && isHorizontalScroll) && (
        <>
          {/* TODO: remove need for SVG blur (via CSS or some other method) */}
          {(!isFirstColumnVisible) && <MobileScrollBlur side={Alignment.LEFT}><BlurIcon name="decoration-blur" width="20" maxHeight={tableRef?.current?.clientHeight} /></MobileScrollBlur>}
          {(!isLastColumnVisible) && <MobileScrollBlur side={Alignment.RIGHT}><BlurIcon name="decoration-blur" width="20" maxHeight={tableRef?.current?.clientHeight} /></MobileScrollBlur>}
        </>
        )}
        {tables.map((table, tableIndex) => {
          const rows = Object.values(table?.rows).filter((i) => i !== null);
          if ((table?.rows.length === 1 && table?.rows[0][0] === '') || Object.values(table?.rows)[0] === null) return null; // handle an empty table
          return (
            <TableWrapper key={`table-${tableIndex}`} ref={tableRef}>
              <MobileScrollInViewHelper ref={firstColumnRef} />
              <Table isHorizontalScroll={isHorizontalScroll} hasMultipleTables={tables.length > 1}>
                <TableBody colorProfile={colorProfile}>
                  {rows.map((row, rowIndex) => (
                    <Row key={`row-${tableIndex}-${rowIndex}`} colorProfile={colorProfile}>
                      {row.map((tableCell, cellIndex) => ((rowIndex === 0) ? (
                        <HeaderCell
                          key={`cell-${rowIndex}-${cellIndex}`}
                          isFirstColumnCenter={isFirstColumnCenter}
                          isLastColumnCenter={isLastColumnCenter}
                          isBoldCell={tableCell.indexOf('bold-text') !== -1}
                          colorProfile={colorProfile}
                        >
                          {checkCellDataForSpecialValues(tableCell)}
                        </HeaderCell>
                      ) : (
                        <DataCell
                          key={`cell-${rowIndex}-${cellIndex}`}
                          isFirstColumnBold={isFirstColumnBold}
                          isFirstColumnCenter={isFirstColumnCenter}
                          isLastColumnCenter={isLastColumnCenter}
                          isBoldCell={tableCell.indexOf('bold-text') !== -1}
                          colorProfile={colorProfile}
                        >
                          {checkCellDataForSpecialValues(tableCell)}
                        </DataCell>
                      )))}
                    </Row>
                  ))}
                </TableBody>
              </Table>
              <MobileScrollInViewHelper ref={lastColumnRef} />
            </TableWrapper>
          );
        })}
      </StyledUiTable>
    )
  );
};

UiTable.propTypes = {
  id: PropTypes.string,
  isFirstColumnBold: PropTypes.bool,
  isFirstColumnCenter: PropTypes.bool,
  isLastColumnCenter: PropTypes.bool,
  isHorizontalScroll: PropTypes.bool,
  tables: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  parentColorProfile: PropTypes.string,
  componentSeason: PropTypes.string,
};

UiTable.defaultProps = {
  id: '',
  isFirstColumnBold: false,
  isFirstColumnCenter: false,
  isLastColumnCenter: false,
  isHorizontalScroll: false,
  parentColorProfile: null,
  componentSeason: null,
};
