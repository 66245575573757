/* eslint-disable object-curly-newline */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components, Breakpoints, HeaderStyles,
} from '@powdr/constants';
import { componentColor, rgbaTransparencyToHex } from '@powdr/utils';

const CPNT = Components.HEADER;

export const StyledHeaderLogo = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  height: 100%;
  pointer-events: all;

  ${Breakpoints.desktopOnly(css`
    min-width: ${({ containerWidth }) => containerWidth};
    max-width: ${({ containerWidth }) => containerWidth};
  `)}

  ${Breakpoints.mobile(css`
    ${({ isFromMobileDrawer, isDorFeedEnabled, isHideNav }) => (
      (isFromMobileDrawer)
        ? 'width: auto;'
        : `width: ${`calc(100% - ${((isDorFeedEnabled) ? 140 : 0) + ((isHideNav) ? 0 : 60)}px)`};`)}
  `)}

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    padding: 10px;

    ${({ isUseLogoBGColor, scrollStateStyle, isMobile, opacity, isFromDemo }) => (
      (isUseLogoBGColor || isFromDemo)
        ? `background-color: ${componentColor(Prop.LOGO_BG, CPNT, Prop.BACKGROUND)};`
        : (scrollStateStyle === HeaderStyles.TRANSLUCENT)
          ? `background-color: ${`${componentColor(Prop.NAV_BG, CPNT, Prop.BACKGROUND)}${rgbaTransparencyToHex((opacity / 100))};`};`
          : `${(!isMobile)
            ? `background-color: ${componentColor(Prop.NAV_BG, CPNT, Prop.BACKGROUND)};`
            : ''}`
    )};

    &:focus {
      outline: 2px solid ${() => componentColor(Prop.LOGO_FOCUS_COLOR, CPNT, Prop.LINK_TXT_HOVER)};
      outline-offset: -8px;
    }

    ${Breakpoints.mobile(css`
      justify-content: left;

      svg {
        width: auto;
      }
    `)}

    svg {
      height: ${({ headerLogoHeight }) => headerLogoHeight};
    }
  }
`;
