/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { useRef, useContext, useEffect } from 'react';
import useResizeObserver from 'use-resize-observer';

import {
  MainNav, Icon,
} from '@powdr/components';
import { SeasonalityPlacement } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  MiniComponentSection,
  FeedContainer, HeaderLogo, SeasonalityTab,
} from './components';
import {
  MobileDrawerToggle, HeaderEcommerceBanner, StyledHeader,
  StyledHeaderDesktop, StyledHeaderMobile, MainNavigationSection,
} from './styles';

export const Header = ({
  levels,
  isLandingPage,
}) => {
  const {
    themeConfig,
    isMobile,
    scrollPosition,
    setInView,
    hasScrolledSeason,
    setHasScrolledSeason,
    setSeasonTabActive,
    setDrawerOpen,
    setFullHeaderHeight,
  } = useContext(AppContext);
  const headerRef = useRef(null);
  const { height } = useResizeObserver({ ref: headerRef });
  const {
    property, siteName, headerConfig, navigation,
  } = useStaticData();
  const isSeasonalityTab = (
    headerConfig.isSeasonalityEnabled
    && headerConfig.seasonalityPlacement === SeasonalityPlacement.IN_SIDE_TAB
  );
  const hideMobileDrawer = () => navigation.childPages.map(
    (page) => page?.isHideFromNav,
  ).every(Boolean) || isLandingPage;
  const navigationComponents = (headerConfig?.navigationComponents?.length > 0)
    ? headerConfig.navigationComponents
    : null;

  useEffect(
    () => {
      const headerHeight = height || 0;
      // class to check if the header is in view
      setInView(headerHeight > scrollPosition.y ? 'in-view' : '');

      // checking scroll for season tab
      if (scrollPosition.y > 500 && !hasScrolledSeason) {
      // should these latter fields be in context? what else from header config should be
        setHasScrolledSeason(true);
        setSeasonTabActive(false);
      }
    },
    [
      height,
      scrollPosition.y,
      hasScrolledSeason,
      setHasScrolledSeason,
      setInView,
      setSeasonTabActive,
    ],
  );

  useEffect(() => {
    if (height) setFullHeaderHeight(height - ((isSeasonalityTab && !isMobile) ? 75 : 0));
  }, [height, setFullHeaderHeight, isSeasonalityTab, isMobile]);

  return (
    <StyledHeader
      ref={headerRef}
      className={`${property}`}
    >
      {/* Unity Shop Bar (contains banner as well) */}
      {(headerConfig.isBannerEnabled && !isLandingPage) && (
        <HeaderEcommerceBanner
          bannerPlacement={headerConfig.bannerPlacement}
          isUnityEnabled={headerConfig.isUnityEnabled}
          bannerCopy={headerConfig.bannerCopy}
        />
      )}

      {/* Nav */}
      <StyledHeaderDesktop className="header-main" height={themeConfig.layout.headerHeight}>
        <HeaderLogo siteName={siteName} isHideNav={hideMobileDrawer()} />

        {/* Main Nav + Navigation Components */}
        <MainNavigationSection>
          {(navigationComponents) && (<MiniComponentSection components={navigationComponents} from="desktop-header" />)}
          <MainNav levels={levels} isLandingPage={isLandingPage} />
        </MainNavigationSection>

        {/* Feed Container */}
        {(!isLandingPage && (headerConfig.isDorFeedEnabled || headerConfig.isPromotionsEnabled)) && <FeedContainer />}
      </StyledHeaderDesktop>

      <StyledHeaderMobile className="header-main">
        {(navigationComponents) && (<MiniComponentSection components={navigationComponents} from="mobile-header" />)}

        <MainNavigationSection $height={themeConfig.layout.headerHeightMobile}>
          <HeaderLogo siteName={siteName} isHideNav={hideMobileDrawer()} isLandingPage={isLandingPage} />

          {/* Feed Container */}
          {(!isLandingPage && (headerConfig.isDorFeedEnabled || headerConfig.isPromotionsEnabled)) && <FeedContainer />}

          {/* Mobile button */}
          {!hideMobileDrawer() && (
          <MobileDrawerToggle>
            <button
              type="button"
              className="hamburger"
              onClick={() => setDrawerOpen(true)}
              style={{ width: `${themeConfig.layout.headerHeightMobile}px` }}
            >
              <Icon className="icon-c" name="ui-menu" width="25" height="25" />
            </button>
          </MobileDrawerToggle>
          )}
        </MainNavigationSection>

      </StyledHeaderMobile>

      {/* Seasonality tab */}
      {(isSeasonalityTab) && <SeasonalityTab />}

    </StyledHeader>
  );
};

Header.propTypes = {
  levels: PropTypes.instanceOf(Array).isRequired,
  isLandingPage: PropTypes.bool,
};

Header.defaultProps = {
  isLandingPage: false,
};
