// eslint-disable-next-line import/no-unresolved
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { Icon, MainNav, MiniComponentSection } from '@powdr/components';
import { Components } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { componentFactory, isEmpty } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  StyledMobileDrawer, PropertyLogo, MobileDrawerHeader, DrawerSeasonToggleWrapper,
  DrawerSeasonToggle, DrawerCloseButton, MobileDrawerBody, ScrollContainer,
  DorComponents, PromotionDrawer, PromotionDrawerOpenButton, PromotionDrawerCloseButton,
  PromotionDrawerLink, MobileDrawerButton, FooterButtons, MainHeaderSection,
} from './styles';

export const MobileDrawer = ({ levels }) => {
  const [isPromotionDrawerOpen, setPromotionDrawerOpen] = useState(false);
  const { headerConfig, dorHeader, mobileDrawer } = useStaticData();
  const {
    themeConfig,
    isMobileDrawerOpen,
    setDrawerOpen,
    isMobileThirdLevelOpen,
  } = useContext(AppContext);
  const {
    promoButtonTitle, promoButtonUrl, navigationComponents, promotions,
  } = headerConfig;
  const dorComponents = dorHeader?.relationships?.feedAreas
    ?.find((item) => item.typeName === Components.DOR_FEED_DRAWER)
    ?.relationships?.mobileDrawerComponents;
  const miniNavComponents = (navigationComponents?.length > 0)
    ? navigationComponents
    : null;

  return (
    <StyledMobileDrawer
      isMobileDrawerOpen={isMobileDrawerOpen}
      isMobileThirdLevelOpen={isMobileThirdLevelOpen}
    >
      <MobileDrawerHeader>
        {(miniNavComponents) && (<MiniComponentSection components={miniNavComponents} from="mobile-drawer" />)}
        <MainHeaderSection $height={themeConfig.layout.headerHeightMobile}>
          <PropertyLogo className="" isFromMobileDrawer />
          {headerConfig.isSeasonalityEnabled && (
          <DrawerSeasonToggleWrapper isFromMobileDrawer>
            <DrawerSeasonToggle />
          </DrawerSeasonToggleWrapper>
          )}
          <DrawerCloseButton
            size={themeConfig.layout.headerHeightMobile}
            onClick={() => setDrawerOpen(false)}
          >
            <Icon name="ui-close" width="20" height="20" />
          </DrawerCloseButton>
        </MainHeaderSection>
      </MobileDrawerHeader>
      <MobileDrawerBody height={themeConfig.layout.headerHeightMobile}>
        <ScrollContainer maxHeightOffset={themeConfig.layout.headerHeightMobile}>
          {(dorComponents) && (
            <DorComponents>
              {componentFactory(dorComponents)}
            </DorComponents>
          )}
          <MainNav levels={levels} />
        </ScrollContainer>
        <FooterButtons>
          {(!isEmpty(mobileDrawer?.drawerButton)) && (
            <MobileDrawerButton
              title={mobileDrawer.drawerButton.buttonText}
              href={mobileDrawer.drawerButton.buttonLink}
              target={mobileDrawer.drawerButton.linkTarget}
              height={themeConfig.layout.headerHeightMobile}
            >
              {mobileDrawer.drawerButton.buttonText}
              <Icon name={mobileDrawer.drawerButton.buttonIcon} width="25" height="25" />
            </MobileDrawerButton>
          )}
          {(!promoButtonUrl && promoButtonTitle) && (
            <PromotionDrawerOpenButton
              onClick={() => setPromotionDrawerOpen(true)}
              height={themeConfig.layout.headerHeightMobile}
            >
              {promoButtonTitle}
            </PromotionDrawerOpenButton>
          )}
          {(promoButtonUrl) && (
            <PromotionDrawerLink
              href={promoButtonUrl}
              onClick={() => setDrawerOpen(false)}
              height={themeConfig.layout.headerHeightMobile}
            >
              {promoButtonTitle}
            </PromotionDrawerLink>
          )}
        </FooterButtons>
        <PromotionDrawer className={classNames({ 'drawer-open': isPromotionDrawerOpen })}>
          <PromotionDrawerCloseButton onClick={() => { setPromotionDrawerOpen(false); }}>
            <Icon name="ui-close" width="20" height="20" />
          </PromotionDrawerCloseButton>
          <ScrollContainer maxHeightOffset={0}>
            {(promotions) && componentFactory([promotions])}
          </ScrollContainer>
        </PromotionDrawer>
      </MobileDrawerBody>
    </StyledMobileDrawer>
  );
};

MobileDrawer.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

MobileDrawer.defaultProps = {

};
