import styled, { css } from 'styled-components';

import { ThemeProps as Prop, Breakpoints } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

export const StyledDorSectorReport = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
`;

export const SectorGraph = styled.div`
  display: flex;
  flex-direction: column;

  ${({ isParentNavFeed }) => ((isParentNavFeed) ? 'padding: 0 5px; flex: 33.3% 0;' : 'padding: 0 5px; flex: 25% 0;')}

  hr {
    width: 100%;
  }

  h3 {
    font-size: ${({ theme }) => theme.FontSize.MED10};
    text-align: center;
    text-transform: capitalize;
    line-height: 1.2;
  }
`;
