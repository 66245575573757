import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { useQueryParam, StringParam } from 'use-query-params';

import { Icon } from '@powdr/components';
import { DemoHeaderBanner } from '@powdr/components/demos';
import {
  Season, QueryParamNames, Components, ThemeProps as Prop,
} from '@powdr/constants';
import { themeUtil, componentColor } from '@powdr/utils';

const CPNT = Components.HEADER;

const JumpButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG30};
  line-height: ${({ theme }) => theme.FontSize.REG30};
  color: ${() => componentColor(Prop.NAV_ITEM_TXT_SOLID, CPNT, Prop.CONTENT)};
  background-color: ${() => componentColor(Prop.NAV_BG, CPNT, Prop.BACKGROUND)};
  transition: all 0.25s ease-in-out;
  border-bottom: 1px solid ${() => themeUtil(Prop.BORDER, CPNT)};

  &:hover {
    color: ${() => componentColor(Prop.NAV_ITEM_TXT_ACTIVE, CPNT, Prop.CONTENT)};
    background-color: ${() => componentColor(Prop.NAV_ITEM_BG_ACTIVE, CPNT, Prop.BACKGROUND)};
  }
`;

export const DemoPageJump = () => (
  <DemoHeaderBanner>
    <JumpButton href="#">Jump to Top of Page</JumpButton>
  </DemoHeaderBanner>

);

DemoPageJump.propTypes = {};

DemoPageJump.defaultProps = {};
