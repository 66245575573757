import styled, { css } from 'styled-components';

import { Link } from '@powdr/components';
import {
  ThemeProps as Prop, Components, Breakpoints,
} from '@powdr/constants';
import { componentColor } from '@powdr/utils';

const CPNT = Components.DOR;

export const StyledDorFeed = styled.div`

  /* override extra title in feed */
  .dor-trail-report-header {
    display: none;
  }

  .dor-trail-report,
  .dor-alert {
    margin-bottom: 0;
  }

  .grid {
    gap: 16px;

    .col {
      flex: 1;
    }
  }

  .flex-2 {
    ${Breakpoints.desktopOnly(css`
      width: 50%;
    `)}
  }

  .area {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${() => componentColor(Prop.COMPONENT_BORDER, CPNT, Prop.BORDER)};

    &:last-child {
      border-bottom: 0px;
    }
  }

  h3 {
    font-size: ${({ theme }) => theme.FontSize.REG50};
    margin-bottom: 20px;
  }

  ${Breakpoints.mobile(css`
    padding: 70px 0 20px 0;

    .grid.row {
      margin-bottom: 50px;
    }
  `)}
`;

export const StyledLink = styled(Link)`
  font-size: 14px;
  ${({ theme }) => theme.fonts.PRI_BDY_MED};
  color: ${() => componentColor(Prop.LINK_TXT, CPNT)};
  transition: color 0.15s ease-in-out;

  &:hover {
    color: ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT)};
  }

  &:focus {
    outline: 2px solid ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT)};
    outline-offset: 2px;
  }
`;
