/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useMemo, useRef, useState, useCallback, useEffect,
} from 'react';
import {
  Navigation, Autoplay,
  EffectFade, EffectCoverflow, EffectCards,
  Scrollbar, FreeMode, A11y, Thumbs,
} from 'swiper/modules';

import { Icon } from '@powdr/components';
import {
  ColorProfiles, KeydownEventCodes,
  CarouselTransitionEffects, NavigationDirection,
  CarouselPaginationPlacement, CarouselPaginationType,
  CarouselControlPlacement, CarouselNavigationPlacement,
} from '@powdr/constants';
import { getRandomInt } from '@powdr/utils';

import {
  StyledPrimaryCarousel, CarouselWrapper, StyledSwiper, StyledSwiperSlide,
  CarouselNavigationWrapper, ControlArrowWrapper, ControlArrow,
  FractionPaginationWrapper, FractionPagination,
  ScrollbarContainer, ScrollbarElement,
  BulletPaginationWrapper, BulletPagination,
  SinglePaginationBullet,
} from './styles';

const CarouselModuleMap = {
  [CarouselTransitionEffects.CARDS]: EffectCards,
  [CarouselTransitionEffects.COVERFLOW]: EffectCoverflow,
  [CarouselTransitionEffects.FADE]: EffectFade,
};

export const PrimaryCarousel = ({
  className: swiperClassName,
  isAutoRotate,
  isInfiniteScroll,
  controlSettings,
  paginationSettings,
  transitionEffect,
  isAutoHeight,
  slidesPerView,
  slidesPerGroup,
  isCenteredSlides,
  spaceBetweenSlides,
  fitSlideWidthToItem,
  scrollable,
  freeMode,
  isShowThumbnails,
  colorProfile,
  children,
}) => {
  const [carouselItems, setCarouselItems] = useState(children.filter((e) => e));
  const [activeIndex, setActiveIndex] = useState();
  const [thumbnailCarousel, setThumbnailCarousel] = useState(null);
  const [keyboardControlEnabled, setKeyboardControlEnabled] = useState(false);
  const navigationOnTop = !!((
    controlSettings?.placement === CarouselControlPlacement.TOP
    || paginationSettings?.placement === CarouselPaginationPlacement.TOP
  ));
  const navigationOnBottom = !!((
    controlSettings?.placement === CarouselControlPlacement.BOTTOM
    || paginationSettings?.placement === CarouselPaginationPlacement.BOTTOM
  ));
  const navigationOnSlide = !!((
    controlSettings?.placement === CarouselControlPlacement.ON_SLIDE
    || paginationSettings?.placement === CarouselPaginationPlacement.ON_SLIDE
  ));
  const effectModule = CarouselModuleMap?.[transitionEffect] || null;
  const mainCarouselRef = useRef();
  const rotationSpeed = 7000; // ms
  const animationSpeed = 600; // ms

  useEffect(() => { setCarouselItems(children.filter((e) => e)); }, [children]);

  const navigate = useCallback(
    (direction) => (
      (direction === NavigationDirection.PREVIOUS)
        ? mainCarouselRef.current?.slidePrev()
        : mainCarouselRef.current?.slideNext()),
    [mainCarouselRef],
  );

  const keyboardControl = useCallback((e) => {
    if (!keyboardControlEnabled || !mainCarouselRef?.current) return;

    if (e.keyCode === KeydownEventCodes.LEFT) {
      navigate(NavigationDirection.PREVIOUS);
    }

    if (e.keyCode === KeydownEventCodes.RIGHT) {
      navigate(NavigationDirection.NEXT);
    }
  }, [keyboardControlEnabled, navigate]);

  const ControlArrowHandler = ({
    placement,
    direction,
    iconName,
    iconSize,
  }) => useMemo(() => (
    <ControlArrowWrapper
      className={`control-${direction}`}
      $colorProfile={colorProfile || ColorProfiles.BASE}
      $placement={placement}
      direction={direction}
      onClick={(e) => { e.stopPropagation(); navigate(direction); }}
      onMouseDown={(e) => { e.preventDefault(); }}
    >
      <ControlArrow
        className="control-arrow"
        $placement={placement}
        direction={direction}
        colorProfile={colorProfile || ColorProfiles.BASE}
      >
        <Icon
          className="control-arrow-icon"
          name={iconName}
          height={iconSize}
          width={iconSize}
        />
      </ControlArrow>
    </ControlArrowWrapper>
  ), [placement, direction, iconName, iconSize]);

  const CarouselNavigationHandler = ({
    placement,
    showArrows,
    showPagination,
  }) => useMemo(() => (
    <CarouselNavigationWrapper $placement={placement}>
      {/* Previous Arrow */}
      {(showArrows) && (
        <ControlArrowHandler
          direction={NavigationDirection.PREVIOUS}
          placement={controlSettings.placement}
          iconName={controlSettings.iconName}
          iconSize={controlSettings.size}
        />
      )}

      {/* Pagination */}
      {(showPagination) && (
        (paginationSettings.type === CarouselPaginationType.FRACTION) ? (
          <FractionPaginationWrapper
            $colorProfile={colorProfile}
            $paginationPlacement={paginationSettings.placement}
          >
            <FractionPagination
              $colorProfile={colorProfile}
              $paginationPlacement={paginationSettings.placement}
            >
              {`${activeIndex} / ${carouselItems.length}`}
            </FractionPagination>
          </FractionPaginationWrapper>
        ) : (
          <BulletPaginationWrapper>
            <BulletPagination
              className={`${CarouselPaginationType.BULLETS}-pagination`}
              $colorProfile={colorProfile}
              $smallGap={paginationSettings.type === CarouselPaginationType.BULLETS}
            >
              {((paginationSettings.type === CarouselPaginationType.BULLETS)
                ? carouselItems
                : paginationSettings?.customBullets)
                ?.map((bullet, idx) => (
                  <SinglePaginationBullet
                    key={`${bullet?.name || 'standard-bullet'}-${getRandomInt(0, 10000)}`}
                    className={classNames({
                      active: (idx) === mainCarouselRef?.current?.realIndex,
                    })}
                    role="button"
                    aria-label={`Navigate to Slide ${idx + 1}`}
                    onClick={() => mainCarouselRef.current?.slideToLoop(idx)}
                    $colorProfile={colorProfile}
                  >
                    {(paginationSettings.type === CarouselPaginationType.BULLETS) && (
                      <div className="standard-bullet" />
                    )}
                    {(paginationSettings.type === CarouselPaginationType.ICON_BULLETS) && (
                      <Icon className="icon-bullet" name={bullet.name} height={bullet.height} />
                    )}
                    {(paginationSettings.type === CarouselPaginationType.TEXT_BULLETS) && (
                      <div className="text-bullet">{bullet.name}</div>
                    )}
                  </SinglePaginationBullet>
                ))}
            </BulletPagination>
          </BulletPaginationWrapper>
        )
      )}

      {/* Next Arrow */}
      {(showArrows) && (
        <ControlArrowHandler
          direction={NavigationDirection.NEXT}
          placement={controlSettings.placement}
          iconName={controlSettings.iconName}
          iconSize={controlSettings.size}
        />
      )}

      {/* Carousel Scrollbar */}
      {(scrollable
        && paginationSettings.placement !== CarouselPaginationPlacement.BOTTOM
        && controlSettings.placement !== CarouselPaginationPlacement.BOTTOM) && (
          <ScrollbarContainer>
            <ScrollbarElement className="scrollbar-element" $colorProfile={colorProfile} />
          </ScrollbarContainer>
      )}
    </CarouselNavigationWrapper>
  ), [placement, showArrows, showPagination]);

  return (
    (carouselItems.length > 1) ? (
      <StyledPrimaryCarousel
        className={swiperClassName}
        $colorProfile={colorProfile}
        tabIndex={0}
        onFocus={() => setKeyboardControlEnabled(true)}
        onBlur={() => setKeyboardControlEnabled(false)}
        onKeyDown={(e) => keyboardControl(e)}
        aria-label="Use left and right arrow keys to navigate the carousel"
      >
        <>
          {/* Top-positioned Navigation */}
          {(navigationOnTop) && (
            <CarouselNavigationHandler
              placement={CarouselNavigationPlacement.TOP}
              showArrows={controlSettings.placement === CarouselControlPlacement.TOP}
              showPagination={paginationSettings.placement === CarouselControlPlacement.TOP}
            />
          )}

          {/* Main Carousel */}
          <CarouselWrapper
            $isWrapperControls={controlSettings.placement === CarouselControlPlacement.IN_WRAPPER}
          >
            {/* Outside Carousel Controls -- Previous Control */}
            {(controlSettings.placement === CarouselControlPlacement.IN_WRAPPER) && (
              <ControlArrowHandler
                direction={NavigationDirection.PREVIOUS}
                iconSize={controlSettings.size}
                iconName={controlSettings.iconName}
              />
            )}

            <StyledSwiper
              modules={[Autoplay, Navigation, Scrollbar, FreeMode, A11y, Thumbs]
                .concat((effectModule) ? [effectModule] : [])}
              onBeforeInit={(swiper) => { mainCarouselRef.current = swiper; }}
              onAfterInit={(swiper) => ((isInfiniteScroll) ? swiper.slideToLoop(0, 0) : null)}
              onSlideChange={(swiper) => { setActiveIndex(swiper.realIndex + 1); }}
              autoplay={(isAutoRotate) ? {
                delay: rotationSpeed,
                disableOnInteraction: false,
              } : false}
              thumbs={(isShowThumbnails) && {
                autoScrollOffset: 1,
                swiper: thumbnailCarousel,
              }}
              freeMode={freeMode}
              autoHeight={isAutoHeight}
              loop={isInfiniteScroll}
              speed={animationSpeed}
              slidesPerView={slidesPerView}
              slidesPerGroup={slidesPerGroup}
              scrollbar={(scrollable) && { draggable: true, el: '.scrollbar-element' }}
              navigation={{
                nextEl: `.control-${NavigationDirection.NEXT}`,
                prevEl: `.control-${NavigationDirection.PREVIOUS}`,
              }}
              effect={transitionEffect || null}
              centeredSlides={isCenteredSlides
              || transitionEffect === CarouselTransitionEffects.COVERFLOW}
              coverflowEffect={(transitionEffect === CarouselTransitionEffects.COVERFLOW)
                ? {
                  rotate: 50,
                  depth: 100,
                  modifier: 1,
                  slideShadows: false,
                } : null}
              spaceBetween={spaceBetweenSlides}
            >
              {carouselItems
                .map((item, idx) => React.cloneElement(
                  item,
                  { disabled: ((idx) !== mainCarouselRef?.current?.realIndex) },
                ))
                .map((item, idx) => (
                  <StyledSwiperSlide
                    key={item.key}
                    $fitSlideWidthToItem={fitSlideWidthToItem}
                    $slidesPerView={slidesPerView}
                    inert={((idx) !== mainCarouselRef?.current?.realIndex) ? '' : null}
                  >
                    {item}
                  </StyledSwiperSlide>
                ))}

              {/* On-Slide Navigation */}
              {(navigationOnSlide) && (
                <CarouselNavigationHandler
                  placement={CarouselNavigationPlacement.ON_SLIDE}
                  showArrows={controlSettings.placement === CarouselControlPlacement.ON_SLIDE}
                  showPagination={paginationSettings.placement === CarouselControlPlacement.ON_SLIDE}
                />
              )}
            </StyledSwiper>

            {/* Outside Carousel Controls -- Next Control */}
            {(controlSettings.placement === CarouselControlPlacement.IN_WRAPPER) && (
              <ControlArrowHandler
                direction={NavigationDirection.NEXT}
                iconSize={controlSettings.size}
                iconName={controlSettings.iconName}
              />
            )}
          </CarouselWrapper>

          {/* Thumbnail Carousel */}
          {(isShowThumbnails) && (
            <StyledSwiper
              onSwiper={setThumbnailCarousel}
              modules={[Navigation, A11y, Thumbs]}
              speed={animationSpeed}
              slidesPerView={3}
              spaceBetween={10}
              $isThumbnailCarousel
            >
              {carouselItems
                .map((item) => React.cloneElement(item, { disabled: true }))
                .map((item, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                  <StyledSwiperSlide key={`thumbnail-${idx}`} $isThumbnail>
                    {item}
                  </StyledSwiperSlide>
                ))}
            </StyledSwiper>
          )}

          {/* Bottom-positioned Navigation */}
          {(navigationOnBottom) && (
            <CarouselNavigationHandler
              placement={CarouselNavigationPlacement.BOTTOM}
              showArrows={controlSettings.placement === CarouselControlPlacement.BOTTOM}
              showPagination={paginationSettings.placement === CarouselControlPlacement.BOTTOM}
            />
          )}
        </>
      </StyledPrimaryCarousel>
    ) : carouselItems?.[0] || null
  );
};

PrimaryCarousel.propTypes = {
  className: PropTypes.string,
  controlSettings: PropTypes.shape({
    iconName: PropTypes.string,
    size: PropTypes.number,
    placement: PropTypes.oneOf(Object.values(CarouselControlPlacement)),
  }),
  paginationSettings: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(CarouselPaginationType)),
    placement: PropTypes.oneOf(Object.values(CarouselPaginationPlacement)),
    customBullets: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
  }),
  isAutoRotate: PropTypes.bool,
  isInfiniteScroll: PropTypes.bool,
  transitionEffect: PropTypes.oneOf(Object.values(CarouselTransitionEffects)),
  slidesPerView: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  slidesPerGroup: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAutoHeight: PropTypes.bool,
  isCenteredSlides: PropTypes.bool,
  scrollable: PropTypes.bool,
  freeMode: PropTypes.bool,
  spaceBetweenSlides: PropTypes.number,
  fitSlideWidthToItem: PropTypes.bool,
  isShowThumbnails: PropTypes.bool,
  colorProfile: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

PrimaryCarousel.defaultProps = {
  className: '',
  paginationSettings: {
    type: null,
    placement: null,
    customBullets: null,
  },
  controlSettings: {
    iconName: null,
    size: null,
    placement: null,
  },
  isAutoRotate: false,
  isInfiniteScroll: true,
  transitionEffect: null,
  isAutoHeight: false,
  isCenteredSlides: false,
  slidesPerView: 1,
  slidesPerGroup: 1,
  scrollable: false,
  freeMode: false,
  spaceBetweenSlides: 0,
  fitSlideWidthToItem: false,
  isShowThumbnails: false,
  colorProfile: null,
};
