import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { NavigationStyles } from '@powdr/constants';
import { AppContext } from '@powdr/context';

import { StyledMegaContainer } from './styles';

export const MegaContainer = ({
  className,
  children,
  isMegaNav,
  navigationStyle,
  isRightAlignedNav,
  splitHeight,
}) => {
  const { themeConfig } = useContext(AppContext);

  return (
    <StyledMegaContainer
      className={className}
      top={(!splitHeight) ? themeConfig.layout.headerHeight : themeConfig.layout.headerHeight / 2}
      navigationStyle={navigationStyle || NavigationStyles.FULL_WIDTH}
      isRightAlignedNav={isRightAlignedNav}
      isMegaNav={isMegaNav}
    >
      {children}
    </StyledMegaContainer>
  );
};

MegaContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isMegaNav: PropTypes.bool,
  navigationStyle: PropTypes.string,
  isRightAlignedNav: PropTypes.bool,
  splitHeight: PropTypes.bool.isRequired,
};

MegaContainer.defaultProps = {
  className: '',
  isMegaNav: false,
  navigationStyle: NavigationStyles.FULL_WIDTH,
  isRightAlignedNav: false,
};
