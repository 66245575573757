import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import {
  ThemeProps as Prop, Components, Breakpoints, StaticProfiles,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.QR_QUERY_CARDS;

export const StyledQrQueryCards = styled.div`
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 100%;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
`;

export const OverlayHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  height: 60px;
`;

export const OverlayLogo = styled.div`
  width: auto;
  height: 100%;
`;

export const OverlayLogoLink = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const OverlayCloseButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 10;
  cursor: pointer;

  &:hover {
    div {
      color: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
    }

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
    }
  }
`;

export const CloseButtonText = styled.div`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  margin-right: 10px;
  color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
`;

export const CloseButtonIcon = styled(Icon)`
  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }
`;

export const AlignmentWrapper = styled.div`
  ${Breakpoints.desktopOnly(css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 100%;
  `)}
`;

export const OverlayBody = styled.div``;

export const MainHeader = styled.div`
  ${({ theme }) => theme.fonts.SEC_HDR_BLK};
  line-height: 1em;
  text-transform: uppercase;
  font-size: 26px;
  margin: 25px;
  width: fit-content;
  text-align: center;

  ${Breakpoints.desktopOnly(css`
    margin: 0 auto 30px auto;
    width: 100%;
  `)}
`;

export const DatePickerSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${Breakpoints.mobile(css`
    width: 93%;
    margin: 0 auto 25px auto;
  `)}

  ${Breakpoints.desktopOnly(css`
    margin: 0 auto 30px auto;
    width: 50%;
  `)}
`;

export const DatePickerSectionHeader = styled.div`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: 16px;
  line-height: 1em;
  text-align: center;
  margin-bottom: 3px;
`;

export const DatePickerWrapper = styled.div`
  width: 242px;

  .react-datepicker {
    ${({ theme }) => `${theme.fonts.PRI_BDY_HVY}`};
  }

  // Fix for UX issue on datepicker via https://github.com/Hacker0x01/react-datepicker/issues/2930#issuecomment-1104174745
  // TODO: can possibly remove after next package update, check periodically for package update
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: inherit;
    background-color: inherit;
    color: inherit;
  }
  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
  }
`;

export const DatePickerCustomInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  font-size: 20px;
  cursor: pointer;
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  line-height: 1em;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};

  &:hover {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    }
  }

  &.open {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    }
  }
`;

export const DatePickerIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
  }
`;

// .cc-header
export const CardHeader = styled.div`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: 16px;
  line-height: 1em;
  text-align: center;
  margin-bottom: 3px;
`;

export const CardGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const CardCarousel = styled.div`
  margin-bottom: 50px;
`;

export const ColorWrapper = styled.div`
  height: 100%;
`;

export const SingleCard = styled.div`
  border-radius: 10px;
  border: 1px solid ${() => themeUtil(Prop.LIGHT, CPNT, StaticProfiles.SHADES)};
  width: 250px;

  ${Breakpoints.desktopOnly`
    margin: 0 15px;
  `}

  ${Breakpoints.mobile`
    margin: 0 auto;
  `}
`;

export const CardTitle = styled.div`
  ${({ theme }) => theme.fonts.SEC_HDR_BLK};
  color: ${({ colorProfile }) => themeUtil(Prop.ELEMENT_TXT, CPNT, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.ELEMENT, CPNT, colorProfile)};
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  height: auto;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  line-height: 1em;
  font-size: 26px;
  font-weight: bold;
  padding: 15px;
`;

export const CardBodyContainer = styled.div`
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 410px;
`;

export const HeadlineText = styled.div`
  ${({ theme }) => theme.fonts.SEC_HDR_BLK};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  text-align: center;
  font-size: 18px;
  line-height: 1.1em;
  font-weight: bold;
  padding: 10px 20px;
`;

export const FeatureItems = styled.div`
  height: 200px;
  padding: 0 20px;
`;

export const SingleFeatureItem = styled.div``;

export const ItemAlignmentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 12px;
`;

export const ItemIcon = styled(Icon)`
  margin: 2px 15px 0 0;

  svg {
    fill: ${({ iconColor }) => iconColor};
    color: ${({ iconColor }) => iconColor};
  }
`;

export const ItemText = styled.div`
  ${({ theme }) => theme.fonts.PRI_BDY_MED};
  line-height: 1.1;
  font-size: 14px;
  max-width: 200px;
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
`;

export const PriceSection = styled.div`
  ${({ theme }) => theme.fonts.SEC_HDR_BLK};
  line-height: 1em;
  height: 125px;
`;

export const PriceTextWrapper = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

// .price-desc
export const PriceDescription = styled.div`
  font-size: 14px;
  line-height: 1em;
  margin-bottom: 2px;
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
`;

export const Price = styled.div`
  font-weight: bold;
  font-size: 26px;
  line-height: 1em;
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
`;

export const PurchaseButton = styled(Link)`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  width: 160px;
  font-size: 16px;
  line-height: 1em;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 12px 16px;
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};

  &:hover {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
  }
`;
