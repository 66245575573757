import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import {
  AlternatingBlockLayoutOptions,
  BlockTypes, Breakpoints, ThemeProps as Prop,
} from '@powdr/constants';
import { useWindowResize } from '@powdr/hooks';
import { ContentBlockType } from '@powdr/model';
import { themeUtil, getBackgroundImageSrc, clamp } from '@powdr/utils';

const CPNT = BlockTypes.SPLIT;

// styled components
const ContentBlockSplit = styled(Link)`
  display: flex;
  flex-direction: ${({ alternateBlockLayoutState }) => (
    (alternateBlockLayoutState === AlternatingBlockLayoutOptions.DEFAULT)
      ? 'row'
      : 'row-reverse'
  )};
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 330px;
  overflow: hidden;

  .cb-title {
    ${() => clamp(2)}
    margin-bottom: 15px;
  }

  .cb-copy {
    ${() => clamp(4)}
  }

  ${Breakpoints.mobile(css`
    flex-direction: column;
    height: 600px;
    overflow: hidden;
  `)}

  &:hover:not(.overlay), &:focus:not(.overlay) {
    .cb-content {
      width: 70%;
      text-align: center;

      ${Breakpoints.mobile(css`
        width: 100%;
        height: 70%;
        padding: 25px;
      `)}
    }

    .cb-image {
      width: 30%;

      ${Breakpoints.mobile(css`
        width: 100%;
        height: 30%;
      `)}
    }

    .button {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }
  }

  &:focus .button {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    outline-offset: 2px;
  }

  .cb-image {
    transition: .3s ease-in-out;
    display: block;
    width: 50%;

    ${Breakpoints.mobile(css`
      width: 100%;
      height: 50%;
    `)}
  }

  .cb-content {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
    transition: .3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 50%;
    padding: 40px;

    ${Breakpoints.mobile(css`
      width: 100%;
      height: 50%;
    `)}

    .row {
      text-align: left;
      width: 100%;
      background: transparent;
      position: relative;
      z-index: 1;

      ${Breakpoints.mobile(css`
        padding: 25px;
      `)}

      &.details {
        padding-bottom: 0;
        height: 155px;
        overflow: visible;
      }
    }

    &.icon-on {
      justify-content: space-between;

      .row.icon {
        padding-bottom: 0;
        padding-top: 40px;

        svg {
          fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
          color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
        }

        ${Breakpoints.mobile(css`
          padding-top: 25px;
        `)}
      }

      .row.details {
        padding-top: 20px;
      }
    }

    // arrow left and top
    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 40px);
      ${({ alternateBlockLayoutState }) => ((alternateBlockLayoutState === AlternatingBlockLayoutOptions.DEFAULT) ? 'left: -20px;' : 'right: -20px; transform: scale(-1, 1);')};
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-top-width: 40px;
      border-bottom-width: 40px;
      border-right-width: 40px;
      border-right-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

      ${Breakpoints.mobile(css`
        border: 0 solid transparent;
        border-right-width: 40px;
        border-left-width: 40px;
        border-bottom-width: 40px;
        border-bottom-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
        top: -20px;
        left: 0;
        right: 0;
        margin: auto;
      `)}
    }
  }
`;

const BGImage = styled.div`
  display: block;
  width: 50%;
  background-image: ${({ image }) => `url('${image}')`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Split = ({
  title, copy, blockType, colorProfile, ariaLabel,
  buttonEnabled, buttonLink, buttonText, buttonTarget, buttonClick,
  image, columnOption, isOverlayEnabled, iconName, isStaticImageLink,
  alternateBlockLayoutState,
}) => {
  const [bgImage, setBgImage] = useState('');
  const resize = useWindowResize();

  useEffect(() => {
    if (resize.windowWidth) {
      setBgImage(
        (!isStaticImageLink)
          ? getBackgroundImageSrc(image?.src, resize.windowWidth, columnOption)
          : image.src,
      );
    }
  }, [resize.windowWidth]);

  return (
    <ContentBlockSplit
      href={buttonLink}
      target={buttonTarget}
      onClick={() => buttonClick()}
      aria-label={ariaLabel}
      role="button"
      colorProfile={colorProfile}
      className={classNames(`content-block-${blockType} `, { overlay: isOverlayEnabled })}
      alternateBlockLayoutState={alternateBlockLayoutState}
    >
      {(image) && <BGImage className="cb-image cb-col" image={bgImage} aria-label={image?.altText || ''} />}
      <div className={classNames('cb-content', { 'icon-on': iconName })}>
        {(iconName) && (
          <div className="row icon">
            <Icon name={iconName} width="50" height="50" />
          </div>
        )}
        <div className="row details">
          {(title) && <h3 className="cb-title">{title}</h3>}
          {(copy) && <p className="cb-copy">{copy}</p>}
        </div>
        <div className="row">
          {(buttonEnabled && buttonText) && (
            <button type="button" className="button with-background uppercase" aria-label={ariaLabel}>
              {buttonText}
            </button>
          )}
        </div>
      </div>
    </ContentBlockSplit>
  );
};

Split.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
