import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Loader } from '@powdr/components';
import { Symbols } from '@powdr/constants';
import { useHeaderBackground } from '@powdr/hooks';
import { useTemperature } from '@powdr/hooks/use-temperature';

import { StyledWeatherIndicator, TempContainer, TempValueText } from './styles';

export const WeatherIndicator = ({
  className,
}) => {
  const headerBackground = useHeaderBackground();
  const temperatureData = useTemperature();

  return (
    <StyledWeatherIndicator className={className}>
      {(!temperatureData) ? (
        <Loader
          className="icon"
          classAnimationEffect="loader-animated spin infinite"
          iconName="dor-sunny"
          size="30"
        />
      ) : (
        <TempContainer>
          <Icon name={temperatureData.icon || 'dor-sunny'} width="30" height="30" />
          <TempValueText $background={headerBackground}>
            {`${(temperatureData.value === null) ? Symbols.DOUBLE_DASH : Math.round(temperatureData.value)}${Symbols.DEGREE}`}
          </TempValueText>
        </TempContainer>
      )}
    </StyledWeatherIndicator>
  );
};

WeatherIndicator.propTypes = {
  className: PropTypes.string,
};

WeatherIndicator.defaultProps = {
  className: '',
};
