import styled, { css } from 'styled-components';

import { Icon, Link } from '@powdr/components';
import { ThemeProps as Prop, Components, Breakpoints } from '@powdr/constants';
import { themeUtil, componentColor } from '@powdr/utils';

const CPNT = Components.PROMOTIONS_FEED;

export const StyledWidgetPromotions = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  ${({ isRightAlignedNav }) => ((isRightAlignedNav) ? `
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 2px;
      top: 30%;
      margin-left: 2px;
      height: 40%;
      background-color: ${() => componentColor(Prop.LINK_TXT, CPNT)};

      &:hover {
        background-color: ${() => componentColor(Prop.LINK_TXT, CPNT)};
      }
    }
  ` : '')}

  ${Breakpoints.mobile(css`
    display: none;
  `)}

`;

const MegaPromoStyles = css`
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT)};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  text-transform: ${({ theme }) => ((theme?.HeaderTransform) ? theme.HeaderTransform : 'uppercase')};
  padding: 7px;
  text-align: ${({ hasIcon }) => ((hasIcon) ? 'left' : 'center')};
  overflow: hidden;

  &:focus {
    outline: 2px solid ${() => componentColor(Prop.LINK_TXT, CPNT)};
    outline-offset: -2px;
    z-index: 1;
  }

  svg {
    fill: ${() => componentColor(Prop.LINK_TXT, CPNT)};
    color: ${() => componentColor(Prop.LINK_TXT, CPNT)};
  }
`;

export const MegaPromoLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${MegaPromoStyles}
`;

export const MegaPromoToggle = styled.button`
  ${MegaPromoStyles}

  // prevent child elements from being clickable
  > * {
    pointer-events: none;
  }
`;

export const ToggleAlignmentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PromoText = styled.div`
  color: ${() => componentColor(Prop.LINK_TXT, CPNT)};
  ${({ theme }) => theme.fonts.SEC_HDR_BLK};
  font-weight: bold;
  font-size: ${({ theme }) => theme.FontSize.REG40};
  line-height: ${({ theme }) => theme.FontSize.REG40};
  width: fit-content;
`;

export const PromotionToggleIcon = styled(Icon)`
  margin-right: 5px;
`;

export const CloseIcon = styled(Icon)`
  margin: 0 auto;
  height: 100%;
  max-width: 50px;
`;
