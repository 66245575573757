import styled, { css } from 'styled-components';

import { MiniComponentSection, EcommerceBanner } from '@powdr/components';
import {
  ThemeProps as Prop, Components, Breakpoints, BannerPlacement,
} from '@powdr/constants';
import { themeUtil, componentColor } from '@powdr/utils';

const CPNT = Components.HEADER;

// TODO: cleanup all header styles for different header types
// TODO: Replace custom colors with component color pattern where hardcoded property names are
export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  position: sticky;
  width: 100%;
  z-index: 75;
  top: 0;
  pointer-events: none;

  // shared header styles
  .header-main {
    transition: 0.25s ease-in-out;
    width: 100%;
    white-space: nowrap;
    z-index: 99;
  }

  // landing page specific code
  .landingPage & {
    position: absolute !important;
    width: 100% !important;

    .main-nav,
    .nav-feeds,
    .feed-container {
      display: none !important;
    }

    h1.btn-logo a {
      background-color: transparent;
    }
  }

  unity-shop-bar {
    width: 100%;
  }

`;

export const StyledHeaderDesktop = styled.nav`
  display: none;
  ${({ height }) => height && `
    height: ${height}px;
  `}
  ${Breakpoints.desktopOnly(css`
    display: flex;
  `)}
`;

export const StyledHeaderMobile = styled.nav`
  display: none;

  ${Breakpoints.mobile(css`
    display: flex;
    flex-direction: column;
  `)}
`;

export const MainNavigationSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ $height }) => $height && `
    height: ${$height}px;
  `}

  ${Breakpoints.mobile(css`
    flex-direction: row;
  `)}
`;

export const HeaderEcommerceBanner = styled(EcommerceBanner)`
  ${Breakpoints.desktopOnly(css`
    ${({ bannerPlacement }) => ((bannerPlacement === BannerPlacement.IN_BANNER) ? 'display: block' : 'display: none')}
  `)}
  ${Breakpoints.mobile(css`
    ${({ isUnityEnabled, bannerCopy }) => ((isUnityEnabled || bannerCopy !== '') ? 'display: block' : 'display: none')}
  `)}
`;

export const MobileDrawerToggle = styled.div`
  height: 100%;
  float: right;

  button.hamburger {
    pointer-events: all;
    display: inline-block;
    background-color: ${() => componentColor(Prop.MENU_BTN_BG, Components.MOBILE_NAV, Prop.BTN_BG)};;
    line-height: 0;
    margin: 0;
    height: 100%;

    .icon-c {
      margin: 0 auto;

      svg {
        fill: ${() => componentColor(Prop.MENU_BTN_ICON, Components.MOBILE_NAV, Prop.BTN_TXT)};
        color: ${() => componentColor(Prop.MENU_BTN_ICON, Components.MOBILE_NAV, Prop.BTN_TXT)};
      }
    }
  }
`;
