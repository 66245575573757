import dayjs from 'dayjs';
import utcOffset from 'dayjs/plugin/utc';

dayjs.extend(utcOffset);

// TODO: convert this to use date-fns so we don't have 2 day
/**
 * Get an object with date information in several possible formats
 * @param {String} date
 * @returns {Object} Date data in several variations (long and short string)
 */
export const formatDate = (date) => {
  // TODO: Add date formats to our constants
  const d = dayjs(date, [
    'MM-DD-YYYY',
    'YYYY-MM-DD',
    'YYYY-MM-DD hh:mm a',
    'MM/DD/YYYY',
  ]);
  return {
    day: {
      num: d.format('D'),
      long: d.format('dddd'),
      short: d.format('ddd'),
    },
    year: d.year(),
    month: {
      long: d.format('MMMM'),
      short: d.format('MMM'),
      num: d.format('MM'),
    },
    time: {
      hours: d.format('hh'),
      minutes: d.format('mm'),
      ampm: d.format('a'),
    },
  };
};

// TODO: combine with formatDate
export const formatDateUtc = (date) => {
  // TODO: Add date formats to our constants
  const d = dayjs(date, [
    'MM-DD-YYYY',
    'YYYY-MM-DD',
    'YYYY-MM-DD hh:mm a',
  ]).utc();
  return {
    milliseconds: d.valueOf(),
    day: {
      num: d.format('D'),
      long: d.format('dddd'),
      short: d.format('ddd'),
    },
    year: d.year(),
    month: {
      long: d.format('MMMM'),
      short: d.format('MMM'),
      num: d.format('MM'),
    },
    time: {
      hours: d.format('hh'),
      minutes: d.format('mm'),
      ampm: d.format('a'),
    },
  };
};

// TODO: We may be able to clean this up at some point with ternaries rather than doing a bunch
// of if-else chaining
/**
 * Utility to help get all possible information from a start date string (and end date string
 * if it exists) into an object with formatted information
 * @param {String} startDate
 * @param {String} endDate
 * @param {String} type component type for handling variances in data formats
 * @returns {Object} Formatted date information
 */
export const getDateInfo = (startDate, endDate, type) => {
  const start = formatDate(startDate);
  const end = formatDate(endDate);
  let monthSe = '';
  let dayOfWeekSe = '';
  let daySe = '';
  let full = '';

  if (startDate && endDate && startDate !== endDate) {
    if (start.month.num === end.month.num) {
      monthSe = start.month.long;
    } else if (type === 'price') {
      monthSe = `${start.month.short} ${start.day.num} - ${end.month.short} ${end.day.num}`;
    } else {
      monthSe = `${start.month.short} - ${end.month.short}`;
    }

    if (start.day.num === end.day.num) {
      dayOfWeekSe = start.day.long;
    } else {
      dayOfWeekSe = `${start.day.short} - ${end.day.short}`;
    }

    if (start.day.num === end.day.num) {
      daySe = start.day.num;
    } else if (type === 'price') {
      if (start.month.num !== end.month.num) {
        daySe = '';
      } else {
        daySe = `${start.day.num}<span class="range-dash">-</span>${end.day.num}`;
      }
    } else {
      daySe = `${start.day.num}<span class="range-dash">-</span>${end.day.num}`;
    }

    if (start.month.num === end.month.num) {
      full = `${monthSe} ${daySe}, ${start.year}`;
    } else {
      full = `${monthSe}${daySe}, ${start.year}`;
    }

    return {
      isDateRange: true,
      dayOfWeek: dayOfWeekSe,
      year: `${start.year}-${end.year}`,
      month: monthSe,
      day: daySe,
      full,
    };
  }

  return {
    isDateRange: false,
    dayOfWeek: start.day.long,
    year: start.year,
    month: start.month.long,
    day: start.day.num,
    full: `${start.month.long} ${start.day.num}, ${start.year}`,
  };
};
