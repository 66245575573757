/* eslint-disable no-nested-ternary */
import {
  FetchStatus, Property, MtnAliases, MtBachelorMntProps, MtBachelorMntLoc, Symbols,
} from '@powdr/constants';
import {
  formatDate, formatDateUtc, flattenArray, groupByObjKey, camalizeToTitle, temperatureStringToInt,
} from '@powdr/utils';

import { SENSORS_PENDING, SENSORS_SUCCESS, SENSORS_ERROR } from '../types';

// eslint-disable-next-line import/no-dynamic-require
const { gatsbyConfig } = require(`@powdr/${process.env.GATSBY_PROPERTY_NAME}`);

const initialState = {
  data: null,
  status: FetchStatus.IDLE,
  error: null,
};

const getMtBatchlorObj = (key, val) => {
  const location = Object.values(MtBachelorMntLoc)
    .find((f) => (Array.isArray(f)
      ? f.find((ff) => ff === key)
      : key === f));

  const locAlias = Array.isArray(location)
    ? location.find((f) => f === key)
    : location;

  const obj = MtnAliases[locAlias];

  return {
    id: key,
    timestamp: val.timestamp,
    location: obj.location ? obj.location : null,
    elevation: obj.elevation ? obj.elevation : null,
    isoParts: formatDateUtc(new Date(val.timestamp)),
    temperature: val.temperature ? `${Math.round(val.temperature)}${Symbols.DEGREE}` : null,
    rh: val.rh ? Math.round(val.rh) : null,
    windDirAvg: val.windDirAvg ? Math.round(val.windDirAvg) : null,
    windSpeedAvg: val.windSpeedAvg ? Math.round(val.windSpeedAvg) : null,
    windSpeedMax: val.windSpeedMax ? Math.round(val.windSpeedMax) : null,
    depth: val.depth ? Math.round(val.depth) : null,
  };
};

const flattenMtnDataBody = (arr) => flattenArray(Object.values(arr)
  ?.map((td) => Object.entries(td)
    ?.map(([tdk, tdv]) => Object
      .values(MtBachelorMntProps)
      .includes(tdk)
      && (tdv !== null)
      && ({
        id: td.id,
        type: tdk,
        value: (tdv === null)
          ? Symbols.NA
          : `${tdv}`,
      }))
    .filter(Boolean)));

const getLiveWeatherCardsSensorsData = (sensors) => Object
  .entries(sensors)
  .filter(([k]) => MtBachelorMntLoc.CURRENT !== k)
  .map(([k, arr]) => getMtBatchlorObj(k, arr[0]));

const getLiveWeatherTableSensorsData = (sensors) => {
  const getRowData = () => Object.entries(
    groupByObjKey(flattenArray(Object
      .entries(sensors)
      .filter(([k]) => MtBachelorMntLoc.CURRENT !== k)
      .map(([k, arr]) => arr
        .map((item) => (getMtBatchlorObj(k, item))))), MtBachelorMntProps.TIMESTAMP),
  );
  const colsBody = (getRowData().map(([ts, arr]) => {
    const columns = [...flattenMtnDataBody(arr)];
    const dateParts = formatDateUtc(new Date(ts));

    return {
      count: columns.length,
      order: dateParts.milliseconds,
      timestamp: {
        full: ts,
        parts: dateParts,
      },
      columns,
    };
  })) || [];

  // CREATE TH HEAD ITEMS
  // Because table head needs to have all possible
  // values/attrbures i pull that from longest row
  const row = colsBody.sort((a, b) => b.order - a.order);
  const [headerRow] = [...row].sort((a, b) => b.count - a.count);

  const colsHead = Object.values(headerRow.columns)
    ?.map((td) => ({
      ...td,
      value: td.type === MtBachelorMntProps.TEMPERATURE
        ? `${MtnAliases[td.id]?.elevation} ${MtnAliases[td.id]?.location}: ${camalizeToTitle(td.type)}`
        : `${MtnAliases[td.id]?.location}: ${camalizeToTitle(td.type)}`,
    }));

  const colsFilters = Object
    .values(MtBachelorMntProps)
    .map((prop) => ({
      label: camalizeToTitle(prop),
      value: prop,
    }));

  const locFilters = Object
    .values(MtBachelorMntLoc)
    .map((prop) => {
      const keys = Object.keys(sensors);
      const key = Array.isArray(prop)
        ? keys
          .filter((f) => prop
            .includes(f))
        : prop;

      return {
        label: MtnAliases[key]?.location,
        value: Array.isArray(key) ? key[0] : key,
      };
    });

  // find the 24 hour high temp, 24 hour low temp, 6 hour avg wind speed for all locations
  const additionalData = Array.from(Object.entries(groupByObjKey(getRowData().map(([, data]) => data).flat(), 'id'))
    .map(([id, data]) => (
      {
        id,
        '24-Hr Low Temp': [{
          type: 'temperature',
          value: `${Math.round(Math.min(...data.map((o) => temperatureStringToInt(o.temperature))))}${Symbols.DEGREE}`,
        }],
        '24-Hr High Temp': [{
          type: 'temperature',
          value: `${Math.round(Math.max(...data.map((o) => temperatureStringToInt(o.temperature))))}${Symbols.DEGREE}`,
        }],
        '6-Hr Wind Speed & Dir Avg': [
          {
            type: 'windSpeedAvg',
            value: Math.round(data
              .slice(0, 6)
              .reduce((a, b) => a + (b.windSpeedAvg >= 0 ? b.windSpeedAvg : a), 0)
                / data.slice(0, 6).length),
          },
          {
            type: 'windDirAvg',
            value: Math.round(data
              .slice(0, 6)
              .reduce((a, b) => a + (b.windDirAvg >= 0 ? b.windDirAvg : a), 0)
                / data.slice(0, 6).length),
          },
          {
            type: 'windSpeedMax',
            value: Math.round(data
              .slice(0, 6)
              .reduce((a, b) => a + (b.windSpeedMax >= 0 ? b.windSpeedMax : a), 0)
                / data.slice(0, 6).length),
          },
        ],
      }
    ))
    .reduce((acc, obj) => {
      Object.entries(obj)
        .forEach(([key, val]) => ((!acc.has(key)) ? acc.set(key, [{
          id: obj.id,
          measurements: val,
        }]) : acc.get(key).push({
          id: obj.id,
          measurements: val,
        })));
      return acc;
    }, new Map()), ([key, val]) => ({ [key]: val }));

  return {
    colsHead,
    colsBody,
    colsFilters,
    locFilters,
    additionalData: (() => {
      const temp = Object.assign({}, ...additionalData);
      delete temp.id;
      return temp;
    })(),
  };
};

const getWidgetSensorsData = (sensors) => {
  const { dor } = gatsbyConfig || {};

  const [data] = Array.isArray(sensors)
    ? sensors.filter((sensor) => sensor.name === dor.temperature.name)
    : sensors[dor.temperature.name]
      ? sensors[dor.temperature.name]
      : [sensors];

  return {
    temp: Number(data.temp || data.temperature),
    icon: null,
  };
};

const reducer = (sensors) => {
  const { property, dor: { liveWeather } } = gatsbyConfig;
  const widgetWeather = getWidgetSensorsData(sensors) || {};

  const liveWeatherCards = liveWeather
    ? getLiveWeatherCardsSensorsData(sensors)
    : [];
  const liveWeatherTable = liveWeather
    ? getLiveWeatherTableSensorsData(sensors)
    : [];

  return {
    data: sensors,
    widgetWeather,
    liveWeatherCards,
    liveWeatherTable,
    modified: true,
    property,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SENSORS_PENDING:
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    case SENSORS_SUCCESS:
      return {
        ...state,
        data: reducer(action.payload),
        status: FetchStatus.SUCCESS,
      };
    case SENSORS_ERROR:
      return {
        status: FetchStatus.ERROR,
        error: action.payload,
      };
    default: return state;
  }
};
