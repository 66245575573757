/* eslint-disable no-nested-ternary */
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Season, HeaderStyles } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledHeaderLogo } from './styles';

export const HeaderLogo = ({
  className,
  isFromMobileDrawer,
  isHideNav,
  isFromDemo,
  isLandingPage,
}) => {
  const { defaultSeason, headerConfig, siteName } = useStaticData();
  const {
    isMobile,
    themeConfig,
    svgs,
    inView,
  } = useContext(AppContext);
  const isUseLogoBGColor = (isMobile && !isFromMobileDrawer)
    || (!isMobile && !headerConfig?.isMatchScrollState);
  const scrollStateStyle = (headerConfig?.navigationOpacityPercentage === 100 || !inView)
    ? HeaderStyles.SOLID
    : HeaderStyles.TRANSLUCENT;
  const hasAltLogo = !!(svgs?.SiteLogoDesktopAlt);

  return (
    <StyledHeaderLogo
      className={`btn-logo ${className}`}
      isFromMobileDrawer={isFromMobileDrawer}
      isHideNav={isHideNav}
      containerWidth={`${themeConfig.layout.headerLogoContainerWidth}px`}
      isDorFeedEnabled={(headerConfig.isDorFeedEnabled && !isLandingPage)}
      headerLogoHeight={(themeConfig.layout?.headerLogoHeight && !isMobile) ? `${themeConfig.layout.headerLogoHeight}px` : '100%'}
      isUseLogoBGColor={isUseLogoBGColor}
      scrollStateStyle={scrollStateStyle}
      isFromDemo={isFromDemo}
      isMobile={isMobile}
      opacity={headerConfig?.navigationOpacityPercentage}
    >
      <Link
        to={defaultSeason === Season.SUMMER ? `/?season=${Season.SUMMER}` : '/'}
        aria-label="Return to Homepage"
        title={siteName}
      >
        {(isFromMobileDrawer)
          ? <svgs.SiteLogoMobileDrawer />
          : (isMobile)
            ? <svgs.SiteLogoMobile />
            : (headerConfig?.isMatchScrollState && inView && hasAltLogo)
              ? <svgs.SiteLogoDesktopAlt />
              : <svgs.SiteLogoDesktop />}
      </Link>
    </StyledHeaderLogo>
  );
};

HeaderLogo.propTypes = {
  className: PropTypes.string,
  isFromMobileDrawer: PropTypes.bool,
  isHideNav: PropTypes.bool,
  isFromDemo: PropTypes.bool,
  isLandingPage: PropTypes.bool,
};

HeaderLogo.defaultProps = {
  className: '',
  isFromMobileDrawer: false,
  isHideNav: false,
  isFromDemo: false,
  isLandingPage: false,
};
