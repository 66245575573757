import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, Components, HeaderStyles,
} from '@powdr/constants';
import { themeUtil, componentColor } from '@powdr/utils';

const CPNT = Components.HEADER;

export const StyledWeatherIndicator = styled.div`
`;

export const TempContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const TempValueText = styled.p`
  ${({ theme }) => theme.fonts.PRI_HDR_BLK};
  font-size: ${({ theme }) => theme.FontSize.REG50};
  color: ${({ $background }) => (($background === HeaderStyles.TRANSLUCENT)
    ? componentColor(Prop.NAV_ITEM_TXT_TRANSLUCENT, CPNT, Prop.CONTENT)
    : componentColor(Prop.NAV_ITEM_TXT_SOLID, CPNT, Prop.CONTENT))};
`;
