/* eslint-disable no-lonely-if */
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import {
  Components, ThemeProps as Prop, StaticProfiles, CompareCardSides,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.COMPARE_CARDS;

export const StyledCompareCardInner = styled.div`
  border-radius: 10px;
  border: 1px solid ${() => themeUtil(Prop.LIGHT, CPNT, StaticProfiles.SHADES)};
  ${({ isMobile }) => ((isMobile) ? 'backface-visibility: hidden; position: absolute; width: 100%;' : '')}
  ${({ sideOfCard }) => ((sideOfCard === CompareCardSides.BACK) ? 'transform: rotateY(-180deg);' : '')}
`;

export const CompareCardTitle = styled.div`
  color: ${({ colorProfile }) => themeUtil(Prop.ELEMENT_TXT, CPNT, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.ELEMENT, CPNT, colorProfile)};
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: ${({ theme }) => theme.FontSize.MED10};
  line-height: 1.1;
  font-weight: bold;
  padding: 15px;
`;

const flippedHidden = css`
  div {
    transition: opacity .2s linear;
    opacity: 0;
  }
`;

const flippedShowing = css`
  div {
    transition: opacity .6s linear;
    opacity: 1;
  }
`;

export const CompareCardBodyContainer = styled.div`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

  ${({ sideOfCard, isCardFlipped }) => {
    if (isCardFlipped) {
      if (sideOfCard === CompareCardSides.FRONT) return flippedHidden;
      if (sideOfCard === CompareCardSides.BACK) return flippedShowing;
      return '';
    }

    // if card is not in flipped state
    if (sideOfCard === CompareCardSides.FRONT) return flippedShowing;
    if (sideOfCard === CompareCardSides.BACK) return flippedHidden;
    return '';
  }}
`;

export const HeadlineText = styled.div`
  ${({ theme }) => theme.fonts.PRI_BDY_MED};
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  text-align: center;
  line-height: 1.2;
  font-size: ${({ theme }) => theme.FontSize.REG30};
  font-weight: bold;
  padding: 10px 20px;
`;

export const FeatureItems = styled.div`
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  height: 350px;
  padding: 0 20px;

  ${({ isMobile }) => ((isMobile) ? 'height: 275px;' : '')}
  ${({ sideOfCard }) => ((sideOfCard === CompareCardSides.BACK) ? 'height: calc(50px + 275px + 175px); padding: 10px 20px;' : '')}
`;

export const PriceSection = styled.div`
  height: 175px;
  ${({ sideOfCard, isCardFlipped }) => ((sideOfCard === CompareCardSides.FRONT && isCardFlipped) ? 'pointer-events: none;' : '')}
`;

export const PriceTextWrapper = styled.div`
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  text-align: center;
  margin-bottom: 10px;
`;

export const PriceDescription = styled.div`
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  font-size: ${({ theme }) => theme.FontSize.REG30};
  line-height: ${({ theme }) => theme.FontSize.REG30};
  margin-bottom: 2px;
`;

export const Price = styled.div`
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  font-weight: bold;
  font-size: ${({ theme }) => theme.FontSize.MED30};
  line-height: ${({ theme }) => theme.FontSize.MED30};
`;

// checkbox-switch-wrapper
export const ToggleSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
`;

export const ToggleLabel = styled.div`
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  display: inline-block;
  font-size: ${({ theme }) => theme.FontSize.REG30};
  padding: 0 5px;
`;

// checkbox-switch
export const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 52px;
  min-width: 52px;
  height: 26px;
`;

// input
export const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked+span {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  }

  &:focus+span {
    box-shadow: 0 0 1px ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  }

  &:checked+span:before {
    transition: transform .4s ease;
    transform: translateX(24px);
  }
`;

// span.slider.round
export const SliderElement = styled.span`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_INACTIVE, CPNT, colorProfile)};
  border-radius: 25px;

  &:before {
    content: "";
    background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
    transition: transform .4s ease;
    transform: translateX(0);
    position: absolute;
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    top: 4px;
    border-radius: 50%;
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  ${({ sideOfCard, isCardFlipped }) => ((sideOfCard === CompareCardSides.FRONT && isCardFlipped) ? 'pointer-events: none;' : '')}
`;

export const PurchaseButton = styled(Link)`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  width: 160px;
  line-height: 1.1;
  font-size: ${({ theme }) => theme.FontSize.REG40};
  font-weight: bold;
  margin-bottom: 10px;
  padding: 12px 16px;
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};

  &:hover {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
  }
`;

export const MoreInfoButton = styled(Link)`
  display: block;
  font-weight: bold;
  line-height: 1.1;
  font-size: ${({ theme }) => theme.FontSize.REG40};
  color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};

  &:hover {
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
  }
`;

export const FlipCardButton = styled.div`
  display: flex;
  align-items: center;
  color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
  ${({ isCardFlipped }) => ((isCardFlipped) ? `
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 25px;
  ` : '')}

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
  }

  &:hover {
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }
  }
`;

// .back AND .view-more, adjusting functionality
export const FlipButtonText = styled.div`
  ${({ isCardFlipped, theme }) => ((isCardFlipped) ? `font-weight: bold; font-size: ${theme.FontSize.MED10}; padding-left: 5px;` : `font-size: ${theme.FontSize.REG30}; padding-right: 3px;`)}
  color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};

  &:hover {
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
  }
  `;

export const FlipButtonIcon = styled(Icon)`
  ${({ isCardFlipped }) => ((isCardFlipped) ? 'width: 40px; height: 40px;' : 'width: 20px; height: 20px; transform: rotate(180deg);')}

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
  }

  &:hover {
    fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
  }
`;
