/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { UiHtml } from '@powdr/components';
import { LinkTargets } from '@powdr/constants';

import * as TabHooks from './hooks/tabHooks';
import {
  TabExecuteButton,
  StyledTabContent,
  StyledSelect,
  StyledDatePicker,
} from './styles';

export const TabContent = ({
  active,
  tab,
  colorProfile,
  is5050,
}) => {
  const {
    tabLabel,
    tabType,
    submitButtonText,
    commerceEngineUrl,
    selectableItemType,
    lodgingDepartureOffest,
    copy,
    relationships: {
      dropdownOptions,
    },
  } = tab;

  const useTabHook = () => {
    try {
      return TabHooks[`use${tabType}`]({
        commerceEngineUrl, dropdownOptions, selectableItemType, lodgingDepartureOffest,
      });
    } catch {
      console.error('Tab Type in Booking Widget does not exist, reverting to fallback!');
      return TabHooks.useSingleDate({
        commerceEngineUrl, dropdownOptions, selectableItemType, lodgingDepartureOffest,
      });
    }
  };

  const {
    setTabValues, tabValues = {}, buttonLink = '', components,
  } = useTabHook();

  const handleDateChange = (name, minDate, selectedDate) => {
    setTabValues({
      [name]: {
        minDate,
        selectedDate,
      },
    });
  };

  const handleSelectChange = (selected, name) => {
    setTabValues({ [name]: { ...selected } });
  };

  const componentFields = components.map((component, componentIndex) => {
    const componentKey = `tab-${tabLabel}-${componentIndex}`;
    const commonFieldProps = { setTabValues, tabValues };
    // TODO: Destructure component vals

    switch (component.type) {
      case 'datepicker':
        return (
          <StyledDatePicker
            key={componentKey}
            placeholder={component.placeholder}
            prettyDate={component.prettyDate}
            dateFormat={component.dateFormat}
            isDefaultDate={component.isDefaultDate}
            isAutoSelected={tabValues[component.name]?.isAutoSelected}
            maxNumDays={component.maxDaysToBookAhead}
            colorProfile={colorProfile}
            isCurrentDaySelectable
            handleDateChange={
              (date) => handleDateChange(component.name, tabValues[component.name]?.minDate, date)
            }
            minDate={tabValues[component.name]?.minDate}
            is5050={is5050}
          />
        );

      case 'select':
        return (
          <StyledSelect
            key={componentKey}
            component={component}
            {...commonFieldProps}
            handleSelectChange={handleSelectChange}
            is5050={is5050}
          />
        );

      case 'radio': {
        return (
          <StyledSelect
            key={componentKey}
            radio
            component={component}
            choices={component.choices}
            {...commonFieldProps}
            handleSelectChange={handleSelectChange}
            is5050={is5050}
          />
        );
      }

      default:
        return null;
    }
  });

  return (
    <StyledTabContent show={active} is5050={is5050}>
      {(copy?.value) && <UiHtml html={copy} parentColorProfile={colorProfile} />}
      {componentFields}
      <TabExecuteButton
        className={classNames({ disabled: !buttonLink })}
        href={buttonLink}
        target={LinkTargets.BLANK}
        onClick={(e) => { if (!buttonLink) e.preventDefault(); }}
      >
        {submitButtonText}
      </TabExecuteButton>
    </StyledTabContent>
  );
};

TabContent.propTypes = {
  active: PropTypes.bool,
  tab: PropTypes.shape({
    tabLabel: PropTypes.string.isRequired,
    tabType: PropTypes.string.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    commerceEngineUrl: PropTypes.string,
    selectableItemType: PropTypes.string,
    copy: PropTypes.string,
    lodgingDepartureOffest: PropTypes.number,
    relationships: PropTypes.shape().isRequired,
  }).isRequired,
  colorProfile: PropTypes.string,
  is5050: PropTypes.bool,
};

TabContent.defaultProps = {
  active: false,
  colorProfile: '',
  is5050: false,
};
