import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { DemoHeaderBanner } from '@powdr/components/demos';
import { Components, ThemeProps as Prop, ColorProfiles } from '@powdr/constants';
import { deslugify, themeUtil, componentColor } from '@powdr/utils';

const CPNT = Components.HEADER;

const StyledDemoColorProfileToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 45px;
  background-color: ${() => componentColor(Prop.NAV_BG, CPNT, Prop.CONTENT)};
  color: ${() => componentColor(Prop.NAV_ITEM_TXT_SOLID, CPNT, Prop.CONTENT)};
  cursor: pointer;
`;

const ProfileToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG30};
  line-height: ${({ theme }) => theme.FontSize.REG30};
  color: ${() => themeUtil(Prop.NAV_BG, CPNT)};
  background-color: ${() => themeUtil(Prop.NAV_ITEM_TXT_SOLID, CPNT)};
  transition: all 0.25s ease-in-out;
  border-left: 1px solid ${() => themeUtil(Prop.BORDER, CPNT)};

  &:first-child {
    border-left: none;
  }

  ${({ active, colorProfile }) => ((active) ? `
    color: ${themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
    background-color: ${themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
  ` : `
    color: ${themeUtil(Prop.NAV_BG, CPNT)};
    background-color: ${themeUtil(Prop.NAV_ITEM_TXT_SOLID, CPNT)};

    &:hover {
      color: ${themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }
  `)}
`;

export const DemoColorProfileToggle = ({
  onSelectColorProfile,
}) => {
  const [currentProfile, setCurrentProfile] = useState(ColorProfiles.BASE);

  return (
    <DemoHeaderBanner>
      <StyledDemoColorProfileToggle>
        {Object.values(ColorProfiles).map(
          (profile) => (
            <ProfileToggle
              key={profile}
              active={profile === currentProfile}
              onClick={() => { onSelectColorProfile(profile); setCurrentProfile(profile); }}
              colorProfile={profile}
            >
              {deslugify(profile)}
            </ProfileToggle>
          ),
        )}
      </StyledDemoColorProfileToggle>
    </DemoHeaderBanner>

  );
};

DemoColorProfileToggle.propTypes = {
  onSelectColorProfile: PropTypes.func.isRequired,
};

DemoColorProfileToggle.defaultProps = {

};
