import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, Components, HeaderStyles,
} from '@powdr/constants';
import { themeUtil, componentColor, rgbaTransparencyToHex } from '@powdr/utils';

const CPNT = Components.HEADER;

export const StyledMiniComponentSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  height: 50%;
  gap: 25px;
  pointer-events: all;

  ${({ background, opacity }) => (
    (background === HeaderStyles.TRANSLUCENT)
      ? `background-color: ${`${componentColor(Prop.NAV_BG, CPNT, Prop.BACKGROUND)}${rgbaTransparencyToHex((opacity / 100))};`};`
      : `background-color: ${componentColor(Prop.NAV_BG, CPNT, Prop.BACKGROUND)}`
  )};

  ${Breakpoints.mobile(css`
    padding: 10px 15px;
    height: 100%;
    gap: 15px;
  `)}
`;
