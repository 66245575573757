import styled, { css } from 'styled-components';

import { Button, Icon, Link } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Components, StaticProfiles,
} from '@powdr/constants';
import { componentColor, themeUtil } from '@powdr/utils';

const CPNT = Components.ECOMMERCE_TAB;

export const OrderSummaryWrapper = styled.div`
  margin: 115px auto 0;
  max-width: 850px;

  ${Breakpoints.mobile(css`
    margin: 0 auto;
  `)}
`;

export const OSHeader = styled.div`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.MED50};
  line-height: 1;
  margin-bottom: 50px;
  font-weight: bold;
`;

export const OSProductDetailBlockWrapper = styled.div``;

export const OSProductDetailBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 175px;
  background-color: ${() => themeUtil(Prop.LIGHTER, CPNT, StaticProfiles.SHADES)};
  margin-bottom: 20px;
  padding: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  ${Breakpoints.mobile(css`
    padding: 10px;
    min-height: 135px;
  `)}
`;

export const ItemInfoWrapper = styled.div`
  display: flex;
  align-items: center;

  ${Breakpoints.mobile(css`
    width: 75%;
  `)}
`;

export const InfoTicketWrapper = styled.div`
  height: 95px;
  width: 75px;

  ${Breakpoints.mobile(css`
    width: 75px;
  `)}
`;

export const InfoTicket = styled.div`
  background-color: ${() => componentColor(Prop.BORDER, CPNT)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;

  &:before, &:after {
    background-color: ${() => themeUtil(Prop.LIGHTER, CPNT, StaticProfiles.SHADES)};
    border: 5px solid ${() => componentColor(Prop.BORDER, CPNT)};
    border-radius: 54px;
    content: ' ';
    display: block;
    height: 35px;
    position: absolute;
    width: 35px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
  }

  &:before {
    top: -28px;
  }

  &:after {
    bottom: -28px;
  }

  ${Breakpoints.mobile(css`
    width: 75px;
  `)}
`;

export const InfoTicketMainText = styled.h4`
  color: ${() => componentColor(Prop.CONTENT, CPNT)};
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-weight: bold;
  font-size: ${({ theme }) => theme.FontSize.REG50};
  line-height: 1;
  margin-bottom: 6px;

  ${Breakpoints.mobile(css`
    font-size: ${({ theme }) => theme.FontSize.REG40};
  `)}
`;

export const InfoTicketSubText = styled.p`
  color: ${() => componentColor(Prop.CONTENT, CPNT)};
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG50};
  line-height: 1;

  ${Breakpoints.mobile(css`
    font-size: ${({ theme }) => theme.FontSize.REG40};
  `)}
`;

export const ItemQuantityBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 65px;
  margin: 0 12px;
  padding: 7px;
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT)};

  ${Breakpoints.mobile(css`
    height: 40px;
    margin: 0 6px;
    padding: 4px;
  `)}
`;

export const ItemQuantityBlockNumber = styled.h4`
  font-size: ${({ theme }) => theme.FontSize.LRG30};
  font-weight: bold;
  line-height: 1;

  ${Breakpoints.mobile(css`
    font-size: ${({ theme }) => theme.FontSize.MED20};
  `)}
`;

export const ItemQuantityBlockSubText = styled.p`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG20};
  line-height: 1;
`;

export const ItemInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ItemInfoBlockTitle = styled.p`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.MED20};
  font-weight: bold;
  line-height: 1;
  margin-bottom: 8px;

  ${Breakpoints.mobile(css`
    font-size: ${({ theme }) => theme.FontSize.REG30};
    margin-bottom: 4px;
  `)}
`;

export const ItemInfoBlockDate = styled.p`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.MED20};
  line-height: 1;

  ${Breakpoints.mobile(css`
    font-size: ${({ theme }) => theme.FontSize.REG30};
  `)}
`;

export const ItemPricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;

  ${Breakpoints.mobile(css`
    width: 25%;
  `)}
`;

export const ItemPricingBlockPriceDivision = styled.p`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.MED10};
  line-height: 1;
  margin-bottom: 8px;

  ${Breakpoints.mobile(css`
    font-size: ${({ theme }) => theme.FontSize.REG30};
    font-weight: bold;
    margin-bottom: 4px;
  `)}
`;

export const ItemPricingBlockPrice = styled.p`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.LRG20};
  font-weight: bold;
  line-height: 1;

  ${Breakpoints.mobile(css`
    font-size: ${({ theme }) => theme.FontSize.MED30};
  `)}
`;

export const OrderCompletionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const OrderSubtotalWrapper = styled.div`
  display: flex;
  margin: 45px 0;

  ${Breakpoints.mobile(css`
    margin: 25px 0;
  `)}
`;

export const SubtotalLabel = styled.div`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.LRG20};
  line-height: 1;
  margin-right: 40px;

  ${Breakpoints.mobile(css`
    font-size: ${({ theme }) => theme.FontSize.MED30};
  `)}
`;

export const SubtotalNumber = styled.div`
  ${({ theme }) => theme.fonts.PRI_HDR_BLK};
  font-size: ${({ theme }) => theme.FontSize.LRG20};
  line-height: 1;

  ${Breakpoints.mobile(css`
    font-size: ${({ theme }) => theme.FontSize.MED30};
  `)}
`;

export const OrderButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 45px;

  ${Breakpoints.mobile(css`
    flex-direction: column-reverse;
    margin-bottom: 25px;
  `)}
`;

export const ContinueShoppingButton = styled(Button)`
  text-transform: uppercase;
  background-color: unset;
  border: 1px solid ${() => themeUtil(Prop.BTN_BG, CPNT)};
  color: ${() => themeUtil(Prop.BTN_BG, CPNT)};

  &:hover {
    border: 1px solid ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT)};
    color: ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT)};
  }
`;

export const CheckoutButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG50};
  padding: 16px 20px;
  width: 50%;
  justify-content: flex-end;
  text-transform: uppercase;
  background-color: ${() => componentColor(Prop.BTN_BG_ALT, CPNT, Prop.BTN_BG)};
  color: ${() => componentColor(Prop.BTN_TXT_ALT, CPNT, Prop.BTN_TXT)};
  font-weight: bold;

  ${({ disabled }) => (disabled ? `
    cursor: default;
    opacity: .5;
  ` : `
    &:hover {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    }
  `)}

  ${Breakpoints.mobile(css`
    width: 100%;
    margin-bottom: 25px;
  `)}
`;

export const CheckoutButtonInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const CheckoutText = styled.div`
`;

export const CheckoutIcon = styled(Icon)`
  position: absolute;
  right: 0;
`;

export const PromotionalSection = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const PromotionalSectionHeader = styled.h3`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG40};
`;

export const ScrollSpacer = styled.div`
  height: 50px;
`;
