import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { HeaderStyles } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledNavigationCTA } from './styles';

export const NavigationCTA = ({
  className,
  label,
  link,
  linkTarget,
  useButtonStyles,
}) => {
  const { inView } = useContext(AppContext);
  const { headerConfig } = useStaticData();
  const navBackground = (
    (headerConfig.navigationOpacityPercentage === 100 || !inView)
      ? HeaderStyles.SOLID
      : HeaderStyles.TRANSLUCENT
  );
  return (
    <StyledNavigationCTA
      className={className}
      href={link}
      target={linkTarget}
      $useButtonStyles={useButtonStyles}
      $background={navBackground}
    >
      {label}
    </StyledNavigationCTA>
  );
};

NavigationCTA.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkTarget: PropTypes.string,
  useButtonStyles: PropTypes.bool,
};

NavigationCTA.defaultProps = {
  className: '',
  linkTarget: null,
  useButtonStyles: false,
};
