import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components, StaticProfiles, ComponentGroups, Breakpoints,
} from '@powdr/constants';
import {
  themeUtil, componentFontSize, getDorComponentColor,
} from '@powdr/utils';

const CPNT = Components.DOR;

export const StyledDorAlert = styled.div`
  margin-bottom: 30px;

  &.dor-alert {
    position: relative;

    h1, h2, h3, h4, h5, h6 {
      a { font-size: inherit; }
    }

    h1 {
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h1')}
      margin-bottom: 40px;
    }

    h2 {
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h2')}
      margin-bottom: 20px;
    }

    h3 {
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h3')}
      color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.HEADER_TXT, CPNT)};
      margin-bottom: 10px;
    }

    h4 {
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h4')}
      margin-bottom: 10px;
    }

    h5 {
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h5')}
      margin-bottom: 10px;
    }

    h6 {
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h6')}
      margin-bottom: 5px;
    }

    p,
    small,
    strong,
    i,
    em {
      display: block;
      color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.HEADER_TXT, CPNT)};
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content')}
      margin-bottom: 5px;
      line-height: 1;

      ${Breakpoints.large(css`
        ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content-large-breakpoint')}
      `)}
    }

    a {
      color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.BTN_BG, CPNT)};
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content')}

      ${Breakpoints.large(css`
        ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content-large-breakpoint')}
      `)}
    }

    .bolder {
      font-weight: bolder;
    }

    .icon {
      display: flex;
      justify-content: center;

      svg {
        fill: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.ICON, CPNT)};
        color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.ICON, CPNT)};
      }
    }

    &.alert {

      .success {
        padding: 15px;
        background-color: ${() => themeUtil(Prop.SUCCESS, CPNT, StaticProfiles.ALERTS)};
      }

      .info {
        padding: 15px;
        background-color: ${() => themeUtil(Prop.INFO, CPNT, StaticProfiles.ALERTS)};
      }

      .warning {
        padding: 15px;
        background-color: ${() => themeUtil(Prop.WARNING, CPNT, StaticProfiles.ALERTS)};
      }

      .danger {
        padding: 15px;
        background-color: ${() => themeUtil(Prop.DANGER, CPNT, StaticProfiles.ALERTS)};
      }
    }
  }

`;
