import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Icon, PrimaryCarousel } from '@powdr/components';
import { CarouselControlPlacement, ScreenType } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useSeason, useWindowResize } from '@powdr/hooks';

import { HeroSlide } from './components';
import { StyledContentBlockHero, ScrollButton } from './styles';

export const ContentBlockHero = ({
  isAutoRotate,
  isEnableMobileVideoAutoplay,
  isImageDictatesHeight,
  isL1HeroOverride,
  slideOffsetDirection,
  slideOffsetAdjustment,
  isAltCopyDisplay,
  screenHeightOverride,
  scrollToName,
  hideOnPlatform,
  componentSeason,
  relationships,
}) => {
  const { isMobile, fullHeaderHeight } = useContext(AppContext);
  const { checkSeasonality } = useSeason();
  const resize = useWindowResize();
  const { heroSlides } = relationships || [];

  const heroScroll = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: resize.windowHeight - fullHeaderHeight,
    });
  };

  const handleHideHero = () => {
    if (hideOnPlatform) {
      if ((isMobile && hideOnPlatform === ScreenType.MOBILE)
        || (!isMobile && hideOnPlatform === ScreenType.DESKTOP)
      ) return true;
    }

    if (!checkSeasonality(componentSeason)) return true;

    if (isL1HeroOverride) return true;

    return false;
  };

  return (
    (!handleHideHero()) && (
      <StyledContentBlockHero
        id={scrollToName}
        heroHeight={screenHeightOverride}
        isImageDictatesHeight={isImageDictatesHeight}
      >
        <PrimaryCarousel
          isAutoRotate={isAutoRotate}
          controlSettings={{
            iconName: 'ui-arrow-left',
            size: 15,
            placement: CarouselControlPlacement.ON_SLIDE,
          }}
        >
          {heroSlides.filter((s) => !s.isHideSlide).map((slide) => (
            <HeroSlide
              key={`${slide.id}`}
              slide={slide}
              slideOffsetDirection={slideOffsetDirection}
              slideOffsetAdjustment={slideOffsetAdjustment}
              screenHeightOverride={screenHeightOverride}
              isEnableMobileVideoAutoplay={isEnableMobileVideoAutoplay}
              isAltCopyDisplay={isAltCopyDisplay}
            />
          ))}
        </PrimaryCarousel>
        {(screenHeightOverride >= 100 && !isImageDictatesHeight) && (
          <ScrollButton className="bounce" onClick={() => heroScroll()} aria-label="Scroll to Content">
            <Icon name="ui-arrow-scroll" width="50" height="50" />
          </ScrollButton>
        )}
      </StyledContentBlockHero>
    )
  );
};

ContentBlockHero.propTypes = {
  isAutoRotate: PropTypes.bool,
  componentSeason: PropTypes.string,
  slideOffsetDirection: PropTypes.string,
  slideOffsetAdjustment: PropTypes.number,
  isEnableMobileVideoAutoplay: PropTypes.bool,
  isImageDictatesHeight: PropTypes.bool,
  isL1HeroOverride: PropTypes.bool,
  screenHeightOverride: PropTypes.number,
  scrollToName: PropTypes.string,
  isAltCopyDisplay: PropTypes.bool,
  hideOnPlatform: PropTypes.string,
  relationships: PropTypes.shape().isRequired,
};

ContentBlockHero.defaultProps = {
  isAutoRotate: true,
  componentSeason: null,
  slideOffsetDirection: null,
  slideOffsetAdjustment: null,
  isEnableMobileVideoAutoplay: false,
  isImageDictatesHeight: false,
  isL1HeroOverride: false,
  isAltCopyDisplay: false,
  screenHeightOverride: null,
  hideOnPlatform: null,
  scrollToName: null,
};
