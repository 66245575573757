// eslint-disable-next-line import/no-unresolved
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { Icon } from '@powdr/components';
import { ColorProfiles } from '@powdr/constants';
import { componentFactory } from '@powdr/utils';

import {
  StyledCollapsePanel, ExpandButton, PanelBodyWrapper,
  TitleText, CopyText, HeaderLeft, HeaderRight, PanelHeader,
} from './styles';

export const CollapsePanel = ({
  id,
  relationships,
  parentColorProfile,
}) => {
  const [isPanelOpen, setPanelOpen] = useState(false);
  const { panelHeader, panelBody } = relationships;
  const bodyComponents = panelBody?.relationships?.bodyContent || [];
  const colorProfile = parentColorProfile || ColorProfiles.BASE;
  const animationSpeed = 500; // ms

  if (!panelHeader) return null;

  return (
    <StyledCollapsePanel key={id}>
      <PanelHeader
        className="panel-header"
        isContainer={panelHeader?.isContainer}
        onClick={() => setPanelOpen(!isPanelOpen)}
      >
        <HeaderLeft>
          <TitleText colorProfile={colorProfile}>{panelHeader?.title}</TitleText>
          <CopyText colorProfile={colorProfile}>{panelHeader?.headerCopy}</CopyText>
        </HeaderLeft>
        <HeaderRight>
          <ExpandButton
            colorProfile={colorProfile}
            hasText={(panelHeader?.buttonText)}
            $animationSpeed={animationSpeed}
          >
            {panelHeader?.buttonText}
            <Icon className={classNames('expand-icon', { open: isPanelOpen })} name="ui-chevron-down" width="15" height="15" />
          </ExpandButton>
        </HeaderRight>
      </PanelHeader>
      <PanelBodyWrapper isContainer={panelBody?.isContainer}>
        <AnimateHeight duration={animationSpeed} height={(isPanelOpen) ? 'auto' : 0}>
          {componentFactory(bodyComponents, parentColorProfile)}
        </AnimateHeight>
      </PanelBodyWrapper>
    </StyledCollapsePanel>
  );
};

CollapsePanel.propTypes = {
  id: PropTypes.string.isRequired,
  relationships: PropTypes.shape().isRequired,
  parentColorProfile: PropTypes.string,
};

CollapsePanel.defaultProps = {
  parentColorProfile: null,
};
