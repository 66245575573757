/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';

import { StyledDesktopTab, StyledMobileTab, StyledTabIcon } from './styles';

export const BookingWidgetTab = ({
  mobile,
  label,
  colorProfile,
  children,
  isSingleTab,
  ...props
}) => {
  const key = mobile ? `mobile-tab-${label}` : `desktop-tab-${label}`;
  const TabComponent = mobile ? StyledMobileTab : StyledDesktopTab;

  return mobile ? (
    <div key={`tabbed-content-${label}`}>
      <TabComponent
        tabIndex="0"
        key={key}
        {...props}
        isSingleTab={isSingleTab}
        colorProfile={colorProfile}
      >
        {label}
        <StyledTabIcon tabIndex="0" colorProfile={colorProfile} />
      </TabComponent>
      {children}
    </div>
  ) : (
    <TabComponent
      tabIndex="0"
      key={key}
      {...props}
      colorProfile={colorProfile}
    >
      {label}
      <StyledTabIcon tabIndex="0" colorProfile={colorProfile} />
    </TabComponent>
  );
};

BookingWidgetTab.propTypes = {
  mobile: PropTypes.bool,
  label: PropTypes.string.isRequired,
  colorProfile: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  isSingleTab: PropTypes.bool,
};

BookingWidgetTab.defaultProps = {
  mobile: false,
  colorProfile: '',
  children: null,
  isSingleTab: false,
};
