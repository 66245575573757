// eslint-disable-next-line import/no-unresolved

import classNames from 'classnames';
import React, { useMemo, useContext } from 'react';

import { Components } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useSeason } from '@powdr/hooks';
import { componentFactory } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledDorFeed, StyledLink } from './styles';

export const DorFeed = () => {
  const { checkSeasonality } = useSeason();
  const { dorHeader } = useStaticData();
  const { themeConfig } = useContext(AppContext);

  const feedAreasData = useMemo(() => dorHeader?.relationships?.feedAreas?.filter(
    (component) => component.typeName === Components.DOR_FEED_AREA,
  ), [dorHeader]);

  const FeedAreaRender = feedAreasData?.map((area, i) => (
    <li key={area.id} className={classNames('col', { 'flex-2': i === 0 })}>
      {area?.relationships?.dorComponents?.map((component, ii) => {
        const dynamicComponent = component;
        const areaDetails = dynamicComponent.relationships?.areaDetails;
        const details = areaDetails?.filter(
          (comp) => comp.typeName === Components.DOR_FEED_DETAILS,
        )[0];
        const season = areaDetails?.filter(
          (comp) => comp.typeName === Components.DOR_SEASON_DETAILS,
        )[0]?.season;

        const areaTitle = dynamicComponent?.title;
        const btnHref = details?.btnHref;
        const btnTarget = details?.btnTarget;
        const btnLabel = details?.btnLabel;

        return (
          (checkSeasonality(season)) && (
          <div
            key={dynamicComponent.id}
            className={classNames('area', `animated fadeInUp u-an-de-${ii}`)}
          >
            {areaTitle && <h3>{areaTitle}</h3>}
            {dynamicComponent && componentFactory(
              [component],
              themeConfig.megaNav,
              undefined,
              { isParentNavFeed: true, componentSeason: season },
            )}
            {btnHref && <StyledLink title={btnLabel} href={btnHref} target={btnTarget}>{btnLabel}</StyledLink>}
          </div>
          )
        );
      })}
    </li>
  ));

  return (
    <StyledDorFeed>
      <div>
        <ul className={classNames('grid row')}>
          {FeedAreaRender}
        </ul>
      </div>
    </StyledDorFeed>
  );
};

DorFeed.propTypes = {
};

DorFeed.defaultProps = {
};
