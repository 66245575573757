import styled, { css } from 'styled-components';

import { Link, Icon } from '@powdr/components';
import {
  Components, ThemeProps as Prop, Breakpoints, Alignment,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.UI_TABLE;

export const StyledUiTable = styled.div`
  ${Breakpoints.smaller(css`
    overflow-x: auto;
  `)}

  h1, h2, h3, h4, h5, h6 {
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
  }
`;

export const TableWrapper = styled.div`
  display: flex;
`;

export const Table = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  /* ${({ hasMultipleTables }) => ((hasMultipleTables)
    ? `
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0px;
        }
      `
    : 'margin-bottom: 0px;'
  )} */
  margin-bottom: 40px;

  ${({ isHorizontalScroll }) => ((isHorizontalScroll) ? 'min-width: 700px;' : '')}
`;

export const TableBody = styled.tbody`
  width: 100%;

  a:not(.cell-button) {
    transition: color .25s ease-out, background-color .25s ease-out;
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};

    &:hover, &:focus {
      transition: color .25s ease-out, background-color .25s ease-out;
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }

    &:focus {
      outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
      outline-offset: 2px;
    }
  }
`;

export const Row = styled.tr`
  display: table-row;
  border-bottom: 1px solid ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
`;

export const HeaderCell = styled.th`
  display: table-cell;
  padding: 10px 15px 10px 15px;
  width: auto;
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: 13px;
  line-height:1.2em;
  text-transform: uppercase;
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  text-align: center;

  &:first-child {
    text-align: ${({ isFirstColumnCenter }) => ((isFirstColumnCenter) ? 'center' : 'left')};
  }

  &:last-child {
    text-align: ${({ isLastColumnCenter }) => ((isLastColumnCenter) ? 'center' : 'right')};
  }

  ${({ isBoldCell }) => ((isBoldCell) ? 'font-weight: bold;' : '')}

  ${Breakpoints.mobile(css`
    font-size: 16px;
  `)}

  ${Breakpoints.large(css`
    font-size: 14px;
  `)}

  ${Breakpoints.larger(css`
    font-size: 16px;
  `)}
`;

export const DataCell = styled.td`
  display: table-cell;
  padding: 10px 15px 10px 15px;
  width: auto;
  font-size: 16px;
  line-height:1.2em;
  ${({ theme }) => theme.fonts.PRI_BDY_MED};
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  text-align: center;

  ${({ isBoldCell }) => ((isBoldCell) ? 'font-weight: bold;' : '')}

  &:first-child {
    text-align: ${({ isFirstColumnCenter }) => ((isFirstColumnCenter) ? 'center' : 'left')};
  }

  &:last-child {
    text-align: ${({ isLastColumnCenter }) => ((isLastColumnCenter) ? 'center' : 'right')};
  }

  ${Breakpoints.large(css`
    font-size: 18px;
  `)}

  ${({ isFirstColumnBold }) => ((isFirstColumnBold)
    ? `
      &:first-child {
        ${({ theme }) => theme.fonts.PRI_BDY_HVY};
        font-size: 14px;
        text-transform: uppercase;
      }
    ` : ''
  )}
`;

export const CellLink = styled(Link)`
  display: inline-block;
  padding: 10px 15px;
  text-transform: uppercase;
  font-size: 14px;
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  transition: color .25s ease-out, background-color .25s ease-out;

  &:hover, &:focus {
    transition: color .25s ease-out, background-color .25s ease-out;
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
  }

  &:focus {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};;
    outline-offset: 2px;
  }

  ${Breakpoints.mobile(css`
    margin: 0 1px;
  `)}
`;

export const CellIcon = styled(Icon)`
  margin: 0 auto;

  svg {
    fill: ${({ color, colorProfile }) => ((!color) ? themeUtil(Prop.ICON, CPNT, colorProfile) : '')};
    color: ${({ color, colorProfile }) => ((!color) ? themeUtil(Prop.ICON, CPNT, colorProfile) : '')};
  }
`;

export const MobileScrollBlur = styled.div`
  position: absolute;
  overflow-y: hidden;
  ${({ side }) => ((side === Alignment.LEFT) ? 'left: 0;' : 'right: 0; transform: rotate(180deg);')}
`;

export const MobileScrollInViewHelper = styled.div`
  height: 100px;
`;

export const BlurIcon = styled(Icon)`
  max-height: ${({ maxHeight }) => `${maxHeight}px`};
`;
