import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getConditions } from '@powdr/stores/actions/conditions-action';
import { getSnowfallMeasurementUnit } from '@powdr/utils';

export const useSnowfallMeasurement = () => {
  const dispatch = useDispatch();
  const useConditionsData = useSelector((state) => state.conditions);
  const {
    isLoading: conditionsIsLoading,
    error: conditionsError,
    data: conditionsData,
  } = useConditionsData;
  const [snowfallMeasurement, setSnowfallMeasurement] = useState({
    value: null,
    unit: getSnowfallMeasurementUnit(),
  });

  useEffect(() => {
    dispatch(getConditions());
  }, [dispatch]);

  useEffect(() => {
    if (!conditionsIsLoading) {
      setSnowfallMeasurement({
        value: conditionsData?.widgetWeather?.amount || 0,
        unit: getSnowfallMeasurementUnit(),
        errors: {
          conditions: conditionsError,
        },
      });
    }
  }, [
    conditionsData, conditionsIsLoading, conditionsError,
  ]);

  return snowfallMeasurement;
};
