import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, {
  useEffect, useState,
} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, Icon } from '@powdr/components';
import { HideOptions, DateFormats, Layouts } from '@powdr/constants';
import { getConditions } from '@powdr/stores/actions/conditions-action';
import { slugify, getSnowfallMeasurementUnit } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledDorSnowTotals, Timestamp } from './styles';

export const DorSnowTotals = ({
  hide,
  layout,
  title,
  html,
  isParentNavFeed,
  filterBy,
  locationId,
  parentColorProfile,
}) => {
  const { property } = useStaticData();
  const dispatch = useDispatch();
  const useConditionsData = useSelector((state) => state.conditions);
  const { isLoading, error, data } = useConditionsData;
  const [conditionsData, setConditionsData] = useState({});
  const measurementUnit = getSnowfallMeasurementUnit();

  useEffect(() => {
    dispatch(getConditions());
  }, [dispatch]);

  useEffect(() => {
    if (data?.dorSnowTotals) {
      // TODO: Move to own constant
      const PRIMARY = 'Primary';
      const defaultCond = data.dorSnowTotals
        ?.filter((condition) => condition.measurement_location_name === PRIMARY)
        || [];

      const cond = data.dorSnowTotals
        ?.filter((condition) => locationId
          ?.includes(String(condition.location_id)))
        || [];

      const [condReturn] = cond.length > 0
        ? cond
        : defaultCond;

      setConditionsData({ ...condReturn });
    }
  }, [data, locationId]);

  if (error) {
    console.error('DOR Conditions API Failed:', error.message);
    return null;
  }

  const HeaderRender = (
    <div className="dst-header">
      {title && (
        <h3>{title}</h3>
      )}
      {html && ReactHtmlParser(html)}
    </div>
  );

  const GridRender = (
    <ul className="dst-grid">
      {conditionsData?.items
        ?.filter((item) => filterBy
          .map((filterItem) => slugify(filterItem))
          .includes(slugify(item.duration)))
        .map((item, i) => (
          <li
            key={slugify(item.duration)}
            className={
              classNames(
                'dst-item',
                'col',
                'animated',
                'fadeInUp',
                `u-an-de-${i}`,
              )
            }
          >
            <Icon
              className="dst-icon icon"
              name="content-snowflake"
              width="20"
              height="20"
            />
            <h3 className="amount">
              {item.amount}
              {measurementUnit}
            </h3>
            <hr />
            <p className="duration">
              {item.duration}
            </p>
          </li>
        ))}
    </ul>
  );

  const DetailsRender = (
    <div className="col dst-details">
      {conditionsData?.title && !hide.includes(HideOptions.TITLE) && (<h3>{conditionsData?.title}</h3>)}
      {conditionsData?.date && !hide.includes(HideOptions.DATE) && (
        <Timestamp colorProfile={parentColorProfile}>
          {format(parseISO(conditionsData.date), DateFormats.PRETTY_TIMESTAMP)}
        </Timestamp>
      )}
      {conditionsData?.html
        && !hide.includes(HideOptions.HTML)
        && ReactHtmlParser(conditionsData.html)}
    </div>
  );

  return (
    <StyledDorSnowTotals
      colorProfile={parentColorProfile}
      className={classNames('dor-snow-totals', { feed: isParentNavFeed })}
      property={property}
      layout={layout || Layouts.STACKED}
    >
      {isLoading ? (
        <Loader
          className="dst-icon icon"
          classAnimationEffect="loader-animated spin infinite"
          iconName="dor-sunny"
          size="30"
        />
      ) : (
        <>
          <div className="col">
            {(!isParentNavFeed && (title || html)) && HeaderRender}
            {conditionsData?.items && GridRender}
          </div>
          {(!isParentNavFeed && (conditionsData?.title || conditionsData?.date || conditionsData?.html)) && DetailsRender}
        </>
      )}
    </StyledDorSnowTotals>
  );
};

DorSnowTotals.propTypes = {
  layout: PropTypes.string,
  title: PropTypes.string,
  html: PropTypes.string,
  locationId: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  isParentNavFeed: PropTypes.bool,
  filterBy: PropTypes.arrayOf(PropTypes.string),
  hide: PropTypes.arrayOf(PropTypes.string),
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

DorSnowTotals.defaultProps = {
  layout: Layouts.STACKED,
  title: null,
  html: null,
  locationId: null,
  isParentNavFeed: false,
  filterBy: ['overnight', '7 days'],
  hide: [],
  parentColorProfile: null,
};
