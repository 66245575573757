import {
  useEffect, useState, useContext, useCallback,
} from 'react';

import { HeaderStyles } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useStaticData } from '@powdr/web/src/hooks';

export const useHeaderBackground = () => {
  const { headerConfig } = useStaticData();
  const { inView, isMobile } = useContext(AppContext);
  const getNavStyle = useCallback(
    () => (
      (headerConfig.navigationOpacityPercentage === 100 || !inView || isMobile)
        ? HeaderStyles.SOLID
        : HeaderStyles.TRANSLUCENT),
    [inView, headerConfig, isMobile],
  );
  const [navBackground, setNavBackground] = useState(getNavStyle());

  useEffect(() => {
    // only update state if it changes
    if (getNavStyle() !== navBackground) setNavBackground(getNavStyle());
  }, [getNavStyle, navBackground]);

  return navBackground;
};
