import { useEffect } from 'react';

/**
 * Hook to run a given callback function when a click event is fired outside
 * a ref passed into the hook
 *
 * @param {React Ref} ref React ref to check against mouse click event
 * @param {Function} callback function to be run when a click event is fired not
 *                     on the target ref
 */
export const useOnClickedOutside = (
  ref,
  callback = () => {},
) => {
  useEffect(() => {
    const handleClickOutsideRef = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    // Bind the event listener
    document.addEventListener('click', handleClickOutsideRef);
    document.addEventListener('touchstart', handleClickOutsideRef);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutsideRef);
      document.removeEventListener('touchstart', handleClickOutsideRef);
    };
  }, [ref, callback]);
};
