/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { CheckedState } from '@powdr/constants';

import { StyledCheckbox } from './styles';

export const Checkbox = ({
  className,
  name,
  label,
  ariaLabel,
  checked,
  value,
  onChange,
  isChips,
  roundedToggle,
  isIgnoreLabelFor,
  additionalData,
  colorProfile,
}) => {
  const [checkboxState, setCheckboxState] = useState(checked !== CheckedState.UNCHECKED);

  const handleCheckedState = useCallback((e) => {
    if (onChange) onChange(e);
    setCheckboxState(!checkboxState);

    e.stopPropagation();
  }, [onChange, checkboxState]);

  return (
    <StyledCheckbox
      className={className}
      isChips={isChips}
      isChecked={checkboxState}
      colorProfile={colorProfile}
      $roundedToggle={roundedToggle}
    >
      <label
        htmlFor={(!isIgnoreLabelFor) && name}
        className="input-checkbox"
      >
        <input
          id={name}
          name={name}
          onChange={(e) => handleCheckedState(e)}
          checked={checkboxState}
          type="checkbox"
          value={value || name}
          aria-label={ariaLabel}
          {...additionalData}
        />
        {label}
      </label>
    </StyledCheckbox>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  colorProfile: PropTypes.string,
  checked: PropTypes.oneOf(Object.values(CheckedState)),
  isChips: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isIgnoreLabelFor: PropTypes.bool,
  value: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
  roundedToggle: PropTypes.bool,
  onChange: PropTypes.func,
  additionalData: PropTypes.shape(),
};

Checkbox.defaultProps = {
  className: '',
  colorProfile: null,
  checked: CheckedState.UNCHECKED,
  isChips: false,
  roundedToggle: false,
  value: null,
  isIgnoreLabelFor: false,
  onChange: () => {},
  additionalData: {},
};
