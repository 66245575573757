import {
  FetchStatus, DorWeatherApi, Icons, ImageTypes, TimesOfDay,
} from '@powdr/constants';
import {
  getIcon, formatDate, getIconNameFromUrl, setWeatherSliderObj,
} from '@powdr/utils';

import { WEATHER_PENDING, WEATHER_SUCCESS, WEATHER_ERROR } from '../types';

// eslint-disable-next-line import/no-dynamic-require
const { gatsbyConfig } = require(`@powdr/${process.env.GATSBY_PROPERTY_NAME}`);

const initialState = {
  data: null,
  status: FetchStatus.IDLE,
  error: null,
};

const processOpenSnowObjV1 = (data) => {
  // eslint-disable-next-line no-param-reassign
  delete data.results.location7.forecast.updated_at;
  const [period] = Object.values(data.results.location7.forecast);
  const p = period.day.temp ? period?.day : period?.night;

  return {
    temp: Number(p.temp || 0),
    icon: getIcon(p.icon.replace('.png', '')) || Icons.UNKNOWN,
  };
};

const processOpenSnowObjV2 = (data) => {
  const { temp, conditions_id: conditionsId } = data.forecast_current;
  const icon = getIcon(String(conditionsId));

  return {
    temp: Number(temp || 0),
    icon: icon || Icons.UNKNOWN,
  };
};

const getOpenSnowObj = (data) => (
  // eslint-disable-next-line no-nested-ternary
  data.results?.location7?.forecast
    ? processOpenSnowObjV1(data)
    : data.forecast_semi_daily
      ? processOpenSnowObjV2(data)
      : []
);

const processOpenSnowSlidesApiV2 = (data) => data.forecast_semi_daily
  .filter((slide) => slide.day_period === TimesOfDay.DAY)
  .map((slide) => {
    const {
      temp,
      temp_min: tempMin,
      temp_max: tempMax,
      conditions_label: shortLabel,
      precip_snow: snow,
      precip_snow_min: snowMin,
      precip_snow_max: snowMax,
      conditions_icon_url: iconUrl,
      display_at: date,
    } = slide;

    const icon = getIcon(iconUrl
      ?.split('/')
      ?.pop()
      ?.slice(0, -4)) || Icons.UNKNOWN;

    return setWeatherSliderObj({
      name: null,
      order: null,
      temp,
      tempMin,
      tempMax,
      shortLabel,
      longLabel: null,
      snow,
      snowMin,
      snowMax,
      icon,
      date,
    });
  });

const processOpenSnowSlidesApiV1 = (data) => Object.values(data.results.location7.forecast)
  .map((slide) => {
    const { day, night, date } = slide;
    const icon = getIcon(day.icon.replace(`.${ImageTypes.PNG}`, '')) || Icons.UNKNOWN;

    return setWeatherSliderObj({
      name: null,
      order: null,
      temp: day.temp,
      tempMin: day.temp,
      tempMax: night.temp,
      shortLabel: day.weather,
      snow: day.snow,
      snowMin: day.snow,
      snowMax: night.snow,
      icon,
      date,
    });
  });

const getOpenSnowSlides = (weather) => (
  // eslint-disable-next-line no-nested-ternary
  weather.results?.location7?.forecast
    ? processOpenSnowSlidesApiV1(weather)
    : weather?.forecast_semi_daily
      ? processOpenSnowSlidesApiV2(weather)
      : []
);

const getOpenMapObj = (data) => {
  const [daily] = data?.daily || [];
  const { temp, weather: [icons] } = daily || {};

  return {
    temp: Number(temp?.day || 0),
    icon: icons?.icon ? getIcon(icons.icon) : Icons.UNKNOWN,
  };
};

const getOpenMapSlides = (data) => data?.daily.map((s) => {
  const [w] = s.weather;
  const { day, min, max } = s.temp || { day: null, min: null, max: null };

  return setWeatherSliderObj({
    name: null,
    order: null,
    temp: day,
    tempMin: min,
    tempMax: max,
    shortLabel: w.main,
    longLabel: w.description,
    snow: null,
    snowMin: null,
    snowMax: null,
    icon: getIcon(w.icon) || Icons.UNKNOWN,
    date: s.dt * 1000,
  });
});

const getNoaaSlides = (data) => data?.properties?.periods
  ?.reduce((accumulator, currentValue, currentIndex, array) => {
    if (currentIndex % 2 === 0) {
      accumulator.push(array.slice(currentIndex, currentIndex + 2));
    }
    return accumulator;
  }, [])
  ?.map((s) => setWeatherSliderObj({
    name: null,
    order: null,
    temp: null,
    tempMin: s[1].temperature,
    tempMax: s[0].temperature,
    shortLabel: s[0].shortForecast,
    longLabel: s[0].detailedForecast,
    snow: null,
    snowMin: null,
    snowMax: null,
    icon: getIcon(s[0].icon.replace(/.*\/([^/]+)\?.*/g, '$1')) || Icons.UNKNOWN,
    date: s[0].startTime,
  }));

const getDefaultObj = (data) => {
  const obj = data?.properties?.periods
    ?.map((m) => ({
      ...m,
      ...formatDate(new Date(m.startTime)),
    })).reduce((prevValue, currValue) => {
      // eslint-disable-next-line no-param-reassign
      (prevValue[currValue.day.short] = prevValue[currValue.day.short] || []).push(currValue);
      return prevValue;
    }, {}); // return as object so I can get values from it in below

  const [temp] = Object.values(obj)
    ?.filter((value) => value.length > 1)
    ?.map((value) => {
      const [s] = value;
      const iconName = getIconNameFromUrl(s.icon);

      return ({
        order: s.number,
        temp: Math.round(s.temperature) || 0,
        icon: getIcon(iconName) || Icons.UNKNOWN,
      });
    }).sort((a, b) => ((a.order > b.order) ? 1 : -1)) || [{ icon: Icons.UNKNOWN, temp: 0 }];

  return temp;
};

const getDefaultSlides = (data) => {
  const obj = data?.properties?.periods?.map((m) => ({
    ...m,
    ...formatDate(new Date(m.startTime)),
  })).reduce((prevValue, currValue) => {
    // eslint-disable-next-line no-param-reassign
    (prevValue[currValue.day.short] = prevValue[currValue.day.short] || []).push(currValue);
    return prevValue;
  }, []);

  const slides = Object.values(obj)
    ?.filter((value) => value.length > 1)
    ?.map((value) => {
      const [startDay, endDay] = value;
      const iconName = getIconNameFromUrl(startDay.icon);

      return setWeatherSliderObj({
        name: startDay.name,
        order: startDay.number,
        temp: startDay.temperature,
        tempMin: startDay.temperature,
        tempMax: endDay.temperature,
        shortLabel: startDay.shortForecast,
        longLabel: startDay.detailedForecast,
        snow: null,
        snowMin: null,
        snowMax: null,
        icon: getIcon(iconName),
        date: startDay.startTime,
      });
    }).sort((a, b) => ((a.order > b.order) ? 1 : -1)) || [];

  return slides;
};

const getWidgetWeatherData = (weather) => {
  const { dor } = gatsbyConfig; // process.env.ap;

  switch (dor.weatherApi) {
    case DorWeatherApi.OPEN_SNOW: {
      return getOpenSnowObj(weather);
    }

    case DorWeatherApi.OPEN_MAP: {
      return getOpenMapObj(weather);
    }

    default: {
      return getDefaultObj(weather);
    }
  }
};

const getDorWeatherData = (weather) => {
  const { dor } = gatsbyConfig; // process.env.ap;

  switch (dor.weatherApi) {
    case DorWeatherApi.OPEN_SNOW: {
      return getOpenSnowSlides(weather);
    }

    case DorWeatherApi.OPEN_MAP: {
      return getOpenMapSlides(weather);
    }

    case DorWeatherApi.NOAA: {
      return getNoaaSlides(weather);
    }

    default: {
      return getDefaultSlides(weather);
    }
  }
};

const reducer = (weather) => {
  const property = process.env.GATSBY_PROPERTY_NAME;
  const widgetWeather = getWidgetWeatherData(weather) || {};
  const dorWeather = getDorWeatherData(weather) || [];

  return {
    ...weather,
    widgetWeather,
    dorWeather,
    modified: true,
    property,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WEATHER_PENDING:
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    case WEATHER_SUCCESS:
      return {
        ...state,
        data: reducer(action.payload),
        status: FetchStatus.SUCCESS,
      };
    case WEATHER_ERROR:
      return {
        status: FetchStatus.ERROR,
        error: action.payload,
      };
    default: return state;
  }
};
