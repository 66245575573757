/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, Components, NavigationStyles,
} from '@powdr/constants';
import { themeUtil, componentColor } from '@powdr/utils';

const CPNT = Components.MEGA_NAV;

export const StyledMegaContainer = styled.div`
  ${Breakpoints.desktopOnly(css`
    display: block;
    background-color: ${({ isMegaNav }) => (
    (isMegaNav)
      ? `${componentColor(Prop.BACKGROUND, CPNT, Prop.BACKGROUND)}`
      : `${themeUtil(Prop.BACKGROUND, CPNT)}`)};
    top: ${({ top }) => `${top}px`};
    box-shadow: 0 7px 10px -6px  rgba(0 0 0 / 50%);
    position: absolute;
    ${({ navigationStyle, isRightAlignedNav }) => ((navigationStyle !== NavigationStyles.ITEM_WIDTH) ? `
        left: 0;
        right: 0;
        padding: 3%;
        min-height: 300px;
        max-height: 50vh;
      ` : (isRightAlignedNav) ? `
        padding: 60px 0 40px 0;
      ` : `
        left: -30%;
        width: 160%;
        padding: 60px 0 40px 0;
      `)}
    height: auto;
    overflow-y: auto;
    z-index: 2;
  `)}


`;
