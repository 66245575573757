import styled from 'styled-components';

export const StyledCollapsePanelGroup = styled.div`
  > :first-child {
    padding: 0;
  }

  > :not(:first-child) {
    padding: ${({ $itemGap }) => $itemGap}px 0 0 0;
  }
`;
