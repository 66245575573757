import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ThemeProps as Prop, ColorProfiles } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const JumpToButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const JumpToButton = styled.a`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG30};
  text-transform: uppercase;
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, null, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, null, colorProfile)};
  padding: 16px 20px;
  transition: all .15s ease-in-out;
  width: fit-content;
  margin: 5px;
  cursor: pointer;

  &:hover {
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, null, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, null, colorProfile)};
  }
`;

export const JumpToButtons = ({ items, colorProfile, className }) => (
  <JumpToButtonGroup className={className}>
    {Object.values(items)
      .map((item) => (
        <JumpToButton
          key={item}
          colorProfile={colorProfile}
          href={`#${item}`}
        >
          {item}
        </JumpToButton>
      ))}
  </JumpToButtonGroup>
);

JumpToButtons.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  colorProfile: PropTypes.string,
};

JumpToButtons.defaultProps = {
  className: '',
  colorProfile: ColorProfiles.BASE,
};
