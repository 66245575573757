import { css } from 'styled-components';

/**
 * Breakpoint (min-width: 960px)
 * @param  {...any} args
 * @returns all styles inside given media query
 */
const desktopOnly = (...args) => css`
  @media only screen and (min-width: 960px) {
    ${args}
  }
`;

/**
 * Breakpoint (max-width {415 - 1}px)
 * @param  {...any} args
 * @returns all styles inside given media query
 */
const mobileSmall = (...args) => css`
  @media only screen and (max-width: ${415 - 1}px) {
    ${args}
  }
`;

/**
 * Breakpoint (max-width {960 - 1}px)
 * @param  {...any} args
 * @returns all styles inside given media query
 */
const mobile = (...args) => css`
  @media only screen and (max-width: ${960 - 1}px) {
    ${args}
  }
`;

/**
 * Breakpoint (min-width: 1600px)
 * @param  {...any} args
 * @returns all styles inside given media query
 */
const large = (...args) => css`
  @media only screen and (min-width: 1600px) {
    ${args}
  }
`;

/**
 * Breakpoint (min-width: 1920px)
 * @param  {...any} args
 * @returns all styles inside given media query
 */
const larger = (...args) => css`
  @media only screen and (min-width: 1920px) {
    ${args}
  }
`;

/**
 * Breakpoint (min-width: {1600 - 1}px)
 * @param  {...any} args
 * @returns all styles inside given media query
 */
const standard = (...args) => css`
  @media only screen and (max-width: ${1600 - 1}px) {
    ${args}
  }
`;

/**
 * Breakpoint (min-width: 768px)
 * @param  {...any} args
 * @returns all styles inside given media query
 */
const portrait = (...args) => css`
  @media only screen and (max-width: ${768 - 1}px) {
    ${args}
  }
`;

/**
 * Breakpoint (max-width: {480 - 1}px)
 * @param  {...any} args
 * @returns all styles inside given media query
 */
const portraitSmall = (...args) => css`
  @media only screen and (max-width: ${480 - 1}px) {
    ${args}
  }
`;

/**
 * Breakpoint (max-width: {480 - 1}px)
 * @param  {...any} args
 * @returns all styles inside given media query
 */
const smallest = (...args) => css`
  @media only screen and (max-width: ${960 - 1}px) {
    ${args}
  }
`;

/**
 * Breakpoint (max-width: {1024 - 1}px)
 * @param  {...any} args
 * @returns all styles inside given media query
 */
const smaller = (...args) => css`
  @media only screen and (max-width: ${1024 - 1}px) {
    ${args}
  }
`;

/**
 * Breakpoint (max-width: {1250 - 1}px)
 * @param  {...any} args
 * @returns all styles inside given media query
 */
const smallInbetween = (...args) => css`
  @media only screen and (max-width: ${1250 - 1}px) {
    ${args}
  }
`;

/**
 * Breakpoint (max-width: {1366 - 1}px)
 * @param  {...any} args
 * @returns all styles inside given media query
 */
const small = (...args) => css`
  @media only screen and (max-width: ${1366 - 1}px) {
    ${args}
  }
`;

const mobileLandscape = (...args) => css`
  @media only screen and (max-width:  ${960 - 1}px) {
    html.mobile.landscape & {
      ${args}
    }
  }
`;

const mobileTablet = (...args) => css`
  html.mobile &, html.tablet & {
    ${args}
  }
`;

const mobileTabletPortrait = (...args) => css`
  @media only screen and (max-width: ${960 - 1}px) {
    html.mobile &, html.tablet.portrait & {
      ${args}
    }
  }
`;

export const Breakpoints = {
  mobileTabletPortrait,
  mobileLandscape,
  mobile,
  mobileSmall,
  desktopOnly,
  mobileTablet,
  large,
  larger,
  standard,
  portrait,
  portraitSmall,
  smallest,
  smaller,
  smallInbetween,
  small,
};

export const BGImageBreakpoints = [960, 1024, 1366, 1920];
