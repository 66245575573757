import PropTypes from 'prop-types';
import React from 'react';

import { componentFactory } from '@powdr/utils';

import { StyledCollapsePanelGroup } from './styles';

export const CollapsePanelGroup = ({
  className,
  itemGap,
  parentColorProfile,
  relationships,
}) => (
  <StyledCollapsePanelGroup className={className} $itemGap={itemGap || 15}>
    {componentFactory(relationships?.components, parentColorProfile)}
  </StyledCollapsePanelGroup>
);

CollapsePanelGroup.propTypes = {
  className: PropTypes.string,
  itemGap: PropTypes.number,
  parentColorProfile: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  relationships: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

CollapsePanelGroup.defaultProps = {
  className: '',
  itemGap: 15,
  parentColorProfile: null,
};
