import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import {
  ThemeProps as Prop, Components, Breakpoints,
} from '@powdr/constants';
import { themeUtil, componentColor } from '@powdr/utils';

const CPNT = Components.CALENDAR;

export const CalendarWrapper = styled.div`
  border: 1px solid ${() => componentColor(Prop.BORDER, CPNT)};
  display: block;
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 90%;
  height: auto;

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .column {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  ${Breakpoints.mobile(css`
    width: 100%;
  `)}
`;

export const CalendarHeader = styled.div`
  border-bottom: 1px solid ${() => componentColor(Prop.BORDER, CPNT)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  padding: 15px 35px;

  .month-year {
    align-items: center;
    display: flex;
    margin-right: 25px;

    span {
      line-height: initial;
      margin-left: 15px;
    }
  }

  button {
    align-items: center;
    background-color: ${() => themeUtil(Prop.BTN_BG, CPNT)};
    border-radius: 17.5px;
    display: flex;
    justify-content: center;
    margin: 0;
    height: 35px;
    width: 35px;

    &.disabled {
      opacity: .50;
      cursor: not-allowed;
    }
  }

  ${Breakpoints.mobile(css`
    padding: 15px;

    button {
      height: 30px;
      width: 30px;
      border-radius: 15px;
      margin: 0;
    }
  `)}
`;

export const NavButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95px;

  ${Breakpoints.mobile(css`
    width: 70px;
  `)}
`;

export const CalendarSubHeader = styled.div`
  border-bottom: 1px solid ${() => componentColor(Prop.BORDER, CPNT)};
  color: ${() => themeUtil(Prop.CONTENT, CPNT)};
  font-size: 24px;
  font-weight: bold;

  .column {
    align-items: center;
    border-bottom: 1px solid ${() => componentColor(Prop.BORDER, CPNT)};
    display: flex;
    height: 35px;
    justify-content: center;

    &:last-of-type {
      border-right-color: transparent;
    }
  }

  ${Breakpoints.mobile(css`
    .column {
      height: 35px;
    }
  `)}
`;

export const ArrowIcon = styled(Icon)`
  position: relative;
  svg {
    fill: ${() => themeUtil(Prop.BTN_TXT, CPNT)};
    color: ${() => themeUtil(Prop.BTN_TXT, CPNT)};
  }
  &.prev {
    transform: rotate(90deg);
    right: 1.5px;
  }
  &.next {
    transform: rotate(-90deg);
    left: 1.5px;
  }
`;

export const CalendarIcon = styled(Icon)`
  svg {
    fill: ${() => themeUtil(Prop.ICON, CPNT)};
    color: ${() => themeUtil(Prop.ICON, CPNT)};
  }
`;

export const CalendarCells = styled.div`
  .cell {
    border-right: 1px solid ${() => componentColor(Prop.BORDER, CPNT)};
    cursor: pointer;
    height: 80px;
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    color: ${() => themeUtil(Prop.CONTENT, CPNT)};
    font-size: 24px;
    font-weight: bold;
    overflow: hidden;
    transition: 0.25s ease-out;
  }
  .cell:hover {
    transition: 0.5s ease-out;

  }
  .selected {
    background: ${() => themeUtil(Prop.BTN_BG, CPNT)};
    span {
      color: ${() => themeUtil(Prop.BTN_TXT, CPNT)};
    }
  }
  .row {
    border-bottom: 1px solid ${() => componentColor(Prop.BORDER, CPNT)};
  }
  .row:last-child {
    border-bottom: none;
  }
  .cell:last-child {
    border-right: none;
  }
  .diffMonth, .disabled {
    span {
      opacity: 0.50;
    }
  }
  .diffMonth:not(.selected) {
    cursor: default;

    span.number {
      color: ${() => themeUtil(Prop.LINK_TXT, CPNT)};
    }

    span.price {
      display: none;
    }
  }
  .disabled {
    cursor: default;
  }
  .column {
    flex-grow: 0;
    flex-basis: calc(100%/7);
    width: calc(100%/7);
  }

  ${Breakpoints.mobile(css`
    .cell {
      height: 65px;
    }
  `)}
`;
